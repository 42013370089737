import React, { memo, useState } from 'react'
import { RATING_SCALE_VALUE_MAP, useGetTender } from '@cotiss/tender'
import { useGetTenderResponse, useMutateTenderResponse } from '@cotiss/tender-response'
import { Button, Drawer, Icon, Input, Label, Text, sentryService, useToast } from '@cotiss/common'

type Props = {
  tenderResponseId: string
  isEditable?: boolean
  tenderId: string
}

export const TenderResponsePriceDrawer = memo(({ tenderId, tenderResponseId, isEditable }: Props) => {
  const { openToast } = useToast()
  const { tender } = useGetTender(tenderId)
  const [isSaving, setIsSaving] = useState(false)
  const { updateTenderResponse } = useMutateTenderResponse()
  const { tenderResponse } = useGetTenderResponse(tenderResponseId)
  const [priceScore, setPriceScore] = useState(tenderResponse?.priceScore?.toString() || '')
  // Once submitted, this form is only editable if:
  // - The tender is two envelope, and the procurement status is still on price
  // - The tender is one envelope, and the procurement status is still on evaluate.

  const canEdit = isEditable && ((tender?.isTwoEnvelope && tender?.status === 'price') || (!tender?.isTwoEnvelope && tender?.status === 'evaluate'))

  const handleEdit = async () => {
    try {
      setIsSaving(true)
      await updateTenderResponse(tenderResponseId, { priceScoreConsensusStatus: 'inProgress' })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleSubmit = async () => {
    try {
      setIsSaving(true)
      await updateTenderResponse(tenderResponseId, {
        priceScoreConsensusStatus: 'completed',
        priceScore: Number(Number(priceScore).toFixed(2)),
      })
      setIsSaving(false)
    } catch {
      setIsSaving(false)
    }
  }

  const renderHeader = () => (
    <Text className="font-semibold" size="h5" variant="heading">
      Score price criteria
    </Text>
  )

  const renderFooter = () => {
    if (tenderResponse?.priceScoreConsensusStatus !== 'completed') {
      return (
        <>
          <div />
          <Button type="submit" size="sm" isLoading={isSaving}>
            Mark as complete
          </Button>
        </>
      )
    }

    return (
      <>
        <div className="flex items-center">
          <Icon className="shrink-0 mr-2" icon="check-circle" variant="success" size={20} />
          <Text>Marked as complete.</Text>
        </div>
        {canEdit && (
          <Button className="ml-4" onClick={handleEdit} state="outline" variant="secondary" isLoading={isSaving}>
            Edit
          </Button>
        )}
      </>
    )
  }

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit} isNarrow>
      <div className="flex items-center justify-between">
        <Text className="uppercase" variant="light" size="sm">
          Price criteria
        </Text>
        <Text className="uppercase" variant="light" size="sm">
          Score
        </Text>
      </div>
      <div className="flex items-center justify-between bg-primary-50 border border-gray-200 rounded-lg p-4 mt-4">
        <Label>Price:</Label>
        <Input
          className="w-[80px]"
          value={priceScore}
          onChange={({ target }) => setPriceScore(target.value)}
          type="number"
          min={tender?.ratingScale ? RATING_SCALE_VALUE_MAP[tender.ratingScale].min : 0}
          max={tender?.ratingScale ? RATING_SCALE_VALUE_MAP[tender.ratingScale].max : 10}
          step={0.01}
          size="sm"
          isFull={false}
          isDisabled={!isEditable || tenderResponse?.priceScoreConsensusStatus === 'completed' || isSaving}
          isRequired
        />
      </div>
    </Drawer>
  )
})
