import React, { memo } from 'react'
import { useGetProject } from '@cotiss/project'
import { TenderTypeBadge, useGetTender } from '@cotiss/tender'
import { useGetProcurement } from '@cotiss/procurement'
import { Breadcrumb, BreadcrumbModel, Header, Skeleton, Text } from '@cotiss/common'

type Props = {
  tenderId?: string
  breadcrumbs?: BreadcrumbModel[]
}

export const EvaluationViewHeader = memo(({ tenderId, breadcrumbs }: Props) => {
  const { tender, isLoading: isLoadingTender } = useGetTender(tenderId)
  const { procurement, isLoading: isProcurementLoading } = useGetProcurement(tender?.procurement)
  const { project, isLoading: isProjectLoading } = useGetProject(procurement?.project)

  const isLoading = isProcurementLoading || isLoadingTender

  return (
    <Header>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
      {!isLoading && (
        <div className="flex items-center">
          {tender?.tenderType && <TenderTypeBadge className="mr-2" tenderType={tender.tenderType} />}
          <Text className="font-semibold" size="h5" variant="heading">
            {procurement?.title}
          </Text>
        </div>
      )}
      {isProjectLoading && <Skeleton className="h-3.5 w-24 mt-1" variant="gray" />}
      {!isProjectLoading && (
        <Text className="mt-0.5" size="sm" variant="light">
          {project?.title}
        </Text>
      )}
    </Header>
  )
})
