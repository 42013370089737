import React, { memo } from 'react'
import { Field, RadioCard, Text } from '@cotiss/common'
import { EVALUATION_EVENT_METHODOLOGY_DESCRIPTION_MAP, EVALUATION_EVENT_METHODOLOGY_NAME_MAP, useEvaluationEvent } from '@cotiss/evaluation-event'

export const EvaluationEventSummaryMethodology = memo(() => {
  const { evaluationEvent } = useEvaluationEvent()

  return (
    <Field
      label="Methodology type"
      supplementary="Select the Methodology that best fits with this specific procurement or your organisational policies."
    >
      <RadioCard className="min-h-[80px] border border-gray-100 w-1/2 p-3.5 pointer-events-none" isSelected>
        <div className="flex justify-between">
          <Text className="font-medium">{evaluationEvent?.methodology && EVALUATION_EVENT_METHODOLOGY_NAME_MAP[evaluationEvent.methodology]}</Text>
        </div>
        <Text className="mt-1" size="sm" variant="light">
          {evaluationEvent?.methodology && EVALUATION_EVENT_METHODOLOGY_DESCRIPTION_MAP[evaluationEvent.methodology]}
        </Text>
      </RadioCard>
    </Field>
  )
})
