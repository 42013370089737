import React, { memo } from 'react'
import { Button, Hr, Text, useCallout } from '@cotiss/common'
import { ConflictOfInterestCreateNewModal, ConflictOfInterestList } from '@cotiss/conflict-of-interest'

type Props = {
  procurementId: string
}

export const ProcurementOverviewConflictOfInterestTab = memo(({ procurementId }: Props) => {
  const { openModal } = useCallout()

  return (
    <>
      <div className="flex items-center justify-between">
        <Text className="font-semibold" size="h7" variant="heading">
          Conflict of Interest Declarations
        </Text>
        <div className="flex items-center ml-8">
          <Button size="sm" onClick={() => openModal(<ConflictOfInterestCreateNewModal procurementId={procurementId} />)}>
            Request conflict declaration
          </Button>
        </div>
      </div>
      <Hr className="my-4" />
      <ConflictOfInterestList procurementId={procurementId} />
    </>
  )
})
