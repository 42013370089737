import classNames from 'classnames'
import React, { ReactNode, memo } from 'react'
import { Button, Icon, IconType, Text } from '@cotiss/common'

export type StepButtonVariant = 'default' | 'pending' | 'locked' | 'complete' | 'rejected'
type Props = {
  className?: string
  children: ReactNode
  variant?: StepButtonVariant
  icon: IconType
  onClick: () => void
  isActive?: boolean
  isDisabled?: boolean
  isLast?: boolean
}

export const StepButton = memo(({ className, children, variant = 'default', icon, onClick, isActive, isDisabled, isLast }: Props) => {
  const classes = classNames(className, 'relative inline-flex items-center justify-center shrink-0 text-sm h-10', {
    'font-semibold text-secondary-600': isActive,
    'text-light pointer-events-none': variant === 'locked',
  })
  const textClasses = classNames({
    'text-secondary-600': isActive,
    'text-light': variant === 'locked',
  })

  const renderCheckIcon = () => (
    <div className="inline-flex items-center justify-center shrink-0 bg-green-200 rounded-full w-3 h-3 mr-2">
      <Icon icon="check" variant="success" size={10} />
    </div>
  )

  const renderClockIcon = () => (
    <div className="relative shrink-0 bg-gray-200 rounded-full w-3 h-3 mr-2">
      <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-[4px] bg-primary-400 rounded-full w-px h-[5px] " />
      <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-[2.5px] bg-primary-400 origin-bottom rotate-[115deg] rounded-full w-px h-[3px] " />
    </div>
  )

  const renderCrossIcon = () => (
    <div className="inline-flex items-center justify-center shrink-0 bg-pink-200 rounded-full w-3 h-3 mr-2">
      <Icon icon="x-close" variant="danger" size={10} />
    </div>
  )

  return (
    <Button className={classes} onClick={onClick} state="raw" isDisabled={isDisabled}>
      {(variant === 'default' || variant === 'locked') && <Icon className="mr-2" icon={icon} size={16} />}
      {variant === 'complete' && renderCheckIcon()}
      {variant === 'pending' && renderClockIcon()}
      {variant === 'rejected' && renderCrossIcon()}
      <Text className={textClasses} size="sm">
        {children}
      </Text>
      {!isLast && <Icon className="absolute right-0 top-1/2 -translate-y-1/2" icon="chevron-right" variant="light" />}
    </Button>
  )
})
