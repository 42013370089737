import React, { memo, useMemo, useState } from 'react'
import { ContractWizardPriceDurationTable, contractService, useGetContractShell } from '@cotiss/contract'
import {
  NoDataPlaceholder,
  OCDS_CURRENCY_OPTIONS,
  OcdsCurrencyCode,
  Select_DEPRECATED,
  Skeleton,
  TabModel,
  TableHeader,
  Tabs,
  Text,
} from '@cotiss/common'

type Props = {
  contractShellId: string
  contractId: string
  showComparison?: boolean
}

type SummaryPriceDurationTab = 'variated' | 'approved'
const CONTRACT_SUMMARY_PRICE_DURATION_TABS: TabModel<SummaryPriceDurationTab>[] = [
  { id: 'variated', label: 'Variated' },
  { id: 'approved', label: 'Approved' },
]

export const ContractSummaryPriceDurationTab = memo(({ contractId, contractShellId, showComparison = false }: Props) => {
  const [tab, setTab] = useState<SummaryPriceDurationTab>('variated')

  const { contractShell, isLoading } = useGetContractShell(contractShellId)

  const { contract, isVariation } = useMemo(() => {
    const currentContract = contractShell?.contracts.find((contract) => contract._id === contractId)
    const publishedContract = contractShell?.contracts.find(
      (contract) => contract._id === contractService.getContract(contractShell, ['PUBLISHED'])?._id
    )

    const contract = tab === 'approved' ? publishedContract : currentContract

    return { contract, isVariation: Boolean(currentContract?.variationTypes.length) }
  }, [contractShell, tab, contractId])

  return (
    <div className="py-6">
      {isVariation && showComparison && (
        <Tabs<SummaryPriceDurationTab>
          className="w-full mb-4"
          tab={tab}
          tabs={CONTRACT_SUMMARY_PRICE_DURATION_TABS}
          onChange={({ id }) => setTab(id)}
          variant="square"
        />
      )}
      <div className="mb-4 w-96">
        {isLoading && <Skeleton className="w-3/4 h-12" />}
        {!isLoading && !contract?.metadata.currency && <Text>No currency selected</Text>}
        {contract?.metadata.currency && (
          <Select_DEPRECATED<OcdsCurrencyCode>
            value={contract.metadata.currency}
            options={OCDS_CURRENCY_OPTIONS}
            onChange={() => 0}
            isDisabled
            placeholder
            className="w-2/3"
          />
        )}
      </div>
      {!contract?.priceDurations.length && <NoDataPlaceholder illustration="dot-list" label="No contract durations" />}
      {Boolean(contract?.priceDurations.length) && (
        <>
          <TableHeader>
            <Text className="font-semibold">Contract overview</Text>
          </TableHeader>

          <ContractWizardPriceDurationTable
            priceDurations={contract?.priceDurations || []}
            isVariation={isVariation}
            contractShellId={contractShellId}
            currency={contract?.metadata.currency}
            showErrors
          />
        </>
      )}
    </div>
  )
})
