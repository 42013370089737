import { find, includes, map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { UserAvatarGroup } from '@cotiss/user'
import { TenderTypeBadge } from '@cotiss/tender'
import { useListProject } from '@cotiss/project'
import { ProcurementListPopulatedModel, useListProcurement } from '@cotiss/procurement'
import {
  Badge,
  Breadcrumb,
  BreadcrumbModel,
  datetimeService,
  Icon,
  paginationService,
  Radio,
  ScrollableTable,
  ScrollableTableColumn,
  sortService,
  TableHeader,
  Text,
  Tooltip_DEPRECATED,
  useSortTable,
} from '@cotiss/common'

type SortKey = 'title' | 'date'

type Props = {
  title: string
  selectedProcurement?: ProcurementListPopulatedModel
  onProcurementChange: (procurement: ProcurementListPopulatedModel) => void
  onBack: () => void
  isDisabled?: boolean
}

export const ContractCreateProcurementStep = memo(({ title, selectedProcurement, onProcurementChange, onBack, isDisabled }: Props) => {
  const { projects, isLoading: isProjectsLoading } = useListProject()
  const [currentProcurementPage, setCurrentProcurementPage] = useState(1)
  const { sortKey, sortDirection, onSort } = useSortTable<SortKey>({ initialKey: 'date' })
  const { procurements, meta, isLoading: isProcurementsLoading } = useListProcurement({ pageSize: 2000 })
  const isLoading = isProjectsLoading || isProcurementsLoading

  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Create contract',
      onClick: onBack,
    },
    {
      label: title,
    },
  ]

  const { processedProcurements, pagination } = useMemo(() => {
    const result = procurements.sort((a, b) => {
      if (sortKey === 'title') {
        return sortService.sortString(a.title || '', b.title || '')
      } else if (sortKey === 'date') {
        return sortService.sortDate(a.createdAt, b.createdAt)
      }

      return 0
    })

    const sortedResult = sortDirection === 'asc' ? result : result.reverse()
    const { items: processedProcurements, pagination } = paginationService.paginate(sortedResult, {
      currentPage: currentProcurementPage,
      pageSize: 15,
    })

    return { processedProcurements, pagination }
  }, [procurements, sortKey, sortDirection, currentProcurementPage])

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: ' ',
        thClassName: 'w-12',
        rows: map(processedProcurements, (procurement) => ({
          content: () => (
            <Radio
              value={procurement._id}
              name="contract-create-procurement"
              onChange={() => onProcurementChange(procurement)}
              isChecked={procurement._id === selectedProcurement?._id}
              isDisabled={isDisabled}
              isRequired
            />
          ),
        })),
      },
      {
        heading: 'Procurement name',
        onSort: () => onSort('title'),
        rows: map(processedProcurements, (procurement) => ({
          content: () => (
            <Text className="truncate" title={procurement.title}>
              {procurement.title}
            </Text>
          ),
          cta: !includes(meta?.hasAccess, procurement._id) && (
            <Tooltip_DEPRECATED tooltipClassName="text-center" tooltip="You do not have access to this procurement.">
              <Icon icon="lock" variant="light" />
            </Tooltip_DEPRECATED>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Date created',
        onSort: () => onSort('date'),
        rows: map(processedProcurements, ({ createdAt }) => ({
          content: () => (
            <Text variant="light" size="sm">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Reference',
        rows: map(processedProcurements, ({ internalIdentifier }) => ({
          content: () => (
            <Text className="truncate" size="sm">
              {internalIdentifier}
            </Text>
          ),
        })),
      },
      {
        heading: 'Project',
        rows: map(processedProcurements, ({ project: projectId }) => {
          const project = find(projects, { _id: projectId })

          return {
            content: () => (
              <Text className="truncate max-w-[200px]" size="sm" title={project?.title || '--'}>
                {project?.title || '--'}
              </Text>
            ),
          }
        }),
      },
      {
        heading: 'Type',
        rows: map(processedProcurements, ({ tenders }) => ({
          content: () => (
            <>
              {map(tenders, (tender) => (
                <TenderTypeBadge key={tender._id} className="mr-1" tenderType={tender.tenderType} />
              ))}
            </>
          ),
        })),
      },
      {
        heading: 'Users',
        rows: map(processedProcurements, ({ accessControlUsers }) => ({
          content: () => <UserAvatarGroup users={accessControlUsers} />,
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [processedProcurements, isDisabled, selectedProcurement])

  return (
    <>
      <Breadcrumb className="mb-4" breadcrumbs={breadcrumbs} onBack={onBack} />
      <TableHeader className="flex items-center justify-between">
        <Text className="font-semibold">Select the procurement.</Text>
        <Badge variant="secondary" state="translucent">
          Step 2 of 2
        </Badge>
      </TableHeader>
      <ScrollableTable
        fixedColumns={fixedColumns}
        columns={columns}
        pagination={pagination}
        onPageChange={setCurrentProcurementPage}
        isLoading={isLoading}
      />
    </>
  )
})
