import { useRef, useEffect, useState } from 'react'

export const useAutoScrollContainer = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [canScroll, setCanScroll] = useState({ left: false, right: false })

  const handleScroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current
      const scrollWidth = container.offsetWidth
      const maxScroll = container.scrollWidth - container.offsetWidth
      const newScrollPosition =
        direction === 'right' ? Math.min(container.scrollLeft + scrollWidth, maxScroll) : Math.max(container.scrollLeft - scrollWidth, 0)

      container.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      })
    }
  }

  const handleScrollRight = () => handleScroll('right')
  const handleScrollLeft = () => handleScroll('left')

  useEffect(() => {
    const container = scrollContainerRef.current
    if (!container) return

    const updateScrollability = () => {
      setCanScroll({
        left: container.scrollLeft > 0,
        right: container.scrollLeft + container.offsetWidth < container.scrollWidth,
      })
    }

    const resizeObserver = new ResizeObserver(updateScrollability)
    resizeObserver.observe(container)

    container.addEventListener('scroll', updateScrollability)

    return () => {
      container.removeEventListener('scroll', updateScrollability)
      resizeObserver.disconnect()
    }
  }, [])

  return {
    scrollContainerRef,
    handleScrollRight,
    handleScrollLeft,
    canScrollLeft: canScroll.left,
    canScrollRight: canScroll.right,
  }
}
