import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import { Hr, Text } from '@cotiss/common'
import { EvaluationAllIndividualList } from '@cotiss/evaluation'

type Props = {
  onView: (userId: string) => void
}

export const EvaluationIndividualTab = memo(({ onView }: Props) => {
  const { tenderId } = useParams<{ tenderId: string }>()

  return (
    <>
      <Text className="font-semibold" size="h7">
        Evaluation panel
      </Text>
      <Text className="mt-1" variant="light" size="sm">
        This must be completed by the procurement chair.
      </Text>
      <Hr className="my-4" />
      <EvaluationAllIndividualList tenderId={tenderId} onView={onView} />
    </>
  )
})
