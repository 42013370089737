import { findIndex, some } from 'lodash'
import { AnimatePresence } from 'framer-motion'
import { useHistory, useParams } from 'react-router-dom'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { AppErrorPage } from '@cotiss/app'
import {
  Banner,
  Breadcrumb,
  BreadcrumbModel,
  Button,
  ConfirmModal,
  FourOhFourPage,
  Header,
  Page,
  PageContent,
  Skeleton,
  TabModel,
  Tabs,
  Text,
  Tooltip_DEPRECATED,
  TransitionContainer,
  routerService,
  sentryService,
  useAsyncEffect,
  useCallout,
  useToast,
  useTransition,
} from '@cotiss/common'
import {
  EvaluationEventStatusBadge,
  EvaluationEventViewDetailsTab,
  EvaluationEventViewResourcesTab,
  EvaluationEventViewResultTab,
  EvaluationEventViewSubmissionsTab,
  EvaluationEventViewTeamTab,
  EvaluationEventViewTrackingTab,
  EvaluationEventWizardFullModal,
  useEvaluationCriteria,
  useEvaluationEnvelope,
  useEvaluationEvent,
  useEvaluationEventAnalytics,
  useEvaluationSubmission,
  useEvaluationUser,
} from '@cotiss/evaluation-event'

export type EvaluationEventViewTab = 'result' | 'tracking' | 'details' | 'team' | 'submissions' | 'resources'

export const EvaluationEventViewPage = memo(() => {
  const { openToast } = useToast()
  const { push, replace } = useHistory()
  const [isError, setIsError] = useState(false)
  const { track } = useEvaluationEventAnalytics()
  const { openModal, openFullModal } = useCallout()
  const [isLoading, setIsLoading] = useState(true)
  const { queryEvaluationCriteriaList } = useEvaluationCriteria()
  const { evaluationSubmissions, queryEvaluationSubmissionList } = useEvaluationSubmission()
  const { queryEvaluationEnvelopeList, queryEvaluationEnvelopeOverviewList } = useEvaluationEnvelope()
  const { evaluationEventId, tab } = useParams<{ evaluationEventId: string; tab?: EvaluationEventViewTab }>()
  const { evaluationUserInSession, queryEvaluationUserList, queryEvaluationUserInSessionView } = useEvaluationUser()
  const { evaluationEvent, queryEvaluationEventView, mutateBeginEvaluationEvent, mutateUpdateEvaluationEvent } = useEvaluationEvent()

  const canStartEvaluation = useMemo(() => some(evaluationSubmissions, { isSetupComplete: true }), [evaluationSubmissions])

  const tabs: TabModel<EvaluationEventViewTab>[] = [
    {
      id: 'result',
      label: 'Result',
      isDisabled: evaluationEvent?.status !== 'complete' ? 'Result tab will be unlocked once tracking is complete.' : '',
    },
    { id: 'tracking', label: 'Tracking' },
    { id: 'details', label: 'Details' },
    { id: 'team', label: 'Team' },
    { id: 'submissions', label: 'Submissions', isHidden: evaluationUserInSession?.role !== 'owner' },
    { id: 'resources', label: 'Resources' },
  ]

  const { step, transition, onTransition } = useTransition({ initialStep: 2 })

  const backHref = routerService.getHref('/evaluation-event/list/:tab?')
  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Evaluate',
      href: backHref,
    },
    {
      label: evaluationEvent?.name || '',
      isLoading,
    },
  ]

  useAsyncEffect(async () => {
    try {
      const [evaluationEvent, evaluationUserInSession] = await Promise.all([
        queryEvaluationEventView({ evaluationEventId }),
        queryEvaluationUserInSessionView({ evaluationEventId }),
        queryEvaluationSubmissionList({ filter: { evaluationEventId } }),
        queryEvaluationEnvelopeList({ filter: { evaluationEventId } }),
        queryEvaluationEnvelopeOverviewList({ filter: { evaluationEventId } }),
        queryEvaluationCriteriaList({ filter: { evaluationEventId } }),
        queryEvaluationUserList({ filter: { evaluationEventId } }),
      ])

      track('evaluation_event_view')

      if (evaluationEvent.status === 'draft' && evaluationUserInSession.role !== 'owner') {
        openToast('You do not have permission to view this draft evaluation event.', 'danger')
        replace(backHref)
        return
      }

      if (!tab && evaluationEvent.status === 'complete') {
        push(routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', { evaluationEventId, tab: 'result' }))
      }
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!evaluationEvent) {
      return
    }

    if (evaluationEvent.isArchived) {
      openToast('You cannot access an archived evaluation event.', 'danger')
      return push(routerService.getHref('/evaluation-event/list/:tab?'))
    }

    setTimeout(() => {
      !evaluationEvent.isSetupComplete && openFullModal(<EvaluationEventWizardFullModal />)
    })
  }, [evaluationEvent])

  useEffect(() => {
    if (!tab) {
      replace(
        routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', {
          evaluationEventId,
          tab: 'tracking',
        })
      )
    }

    if (isLoading) {
      return
    }

    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    }
  }, [isLoading, tab])

  if (!isLoading && isError) {
    return <AppErrorPage />
  }

  if (!isLoading && !evaluationEvent) {
    return <FourOhFourPage />
  }

  const handleEdit = () => {
    openModal(
      <ConfirmModal
        heading="Edit evaluation"
        description="Are you sure you want to edit this evaluation event? It will take you back through the setup process."
        onSubmit={() => {
          track('evaluation_event_view_edit_submit')
          mutateUpdateEvaluationEvent({ evaluationEventId, isSetupComplete: false })
        }}
      />
    )
  }

  const handleStart = async () => {
    track('evaluation_event_view_start_submit')

    await mutateBeginEvaluationEvent({ evaluationEventId })
    await queryEvaluationEnvelopeList({ filter: { evaluationEventId } })
    push(routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', { evaluationEventId, tab: 'tracking' }))
  }

  return (
    <Page>
      <Header>
        <div className="flex items-center justify-between">
          <div className="flex items-start">
            <div>
              <Breadcrumb className="mb-1" backHref={backHref} breadcrumbs={breadcrumbs} isDisabled={isLoading} />
              {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
              {!isLoading && evaluationEvent && (
                <Text className="font-medium flex items-center" size="h7" variant="heading">
                  {evaluationEvent.name}
                  <EvaluationEventStatusBadge className="ml-2" status={evaluationEvent.status} state="translucent" size="sm" />
                </Text>
              )}
            </div>
          </div>
        </div>
      </Header>
      <div className="sticky top-[84px] bg-white border-t border-gray-100 shadow-sm px-10 z-30">
        {!isLoading && (
          <Tabs<EvaluationEventViewTab>
            className="w-full"
            tab={tab}
            tabs={tabs}
            onChange={({ id: tab }) =>
              push(routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', { evaluationEventId, tab }))
            }
            variant="underline"
          />
        )}
      </div>
      <PageContent>
        {!isLoading && (
          <>
            {evaluationUserInSession?.role === 'owner' && evaluationEvent?.status !== 'complete' && !evaluationEvent?.isArchived && (
              <Banner className="mb-4" variant="secondary" icon="alert-circle">
                <div>
                  <Text className="font-semibold" variant="heading">
                    Evaluation event
                  </Text>
                  <Text size="sm" variant="light">
                    Below are the envelopes of evaluation the evaluators will need to complete for each submission.
                  </Text>
                </div>
                {evaluationEvent?.status === 'draft' && (
                  <div className="whitespace-nowrap ml-4">
                    <Button onClick={handleEdit} size="sm" variant="secondary-dark" state="translucent">
                      Edit
                    </Button>
                    <Tooltip_DEPRECATED
                      tooltipClassName="text-center z-20"
                      tooltip="You cannot start an evaluation without at least 1 completed submissions."
                      isEnabled={!canStartEvaluation}
                      width={200}
                    >
                      <Button
                        className="ml-2"
                        onClick={() =>
                          openModal(
                            <ConfirmModal
                              heading="Start evaluation"
                              description="Are you sure you want to start this evaluation? This will notify all panel members that the evaluation is active."
                              onSubmit={handleStart}
                            />
                          )
                        }
                        size="sm"
                        isDisabled={!canStartEvaluation}
                      >
                        Start evaluation
                      </Button>
                    </Tooltip_DEPRECATED>
                  </div>
                )}
              </Banner>
            )}
            <AnimatePresence initial={false} mode="wait">
              <TransitionContainer key={step} transition={transition}>
                {step === 1 && <EvaluationEventViewResultTab />}
                {step === 2 && <EvaluationEventViewTrackingTab />}
                {step === 3 && <EvaluationEventViewDetailsTab />}
                {step === 4 && <EvaluationEventViewTeamTab />}
                {step === 5 && <EvaluationEventViewSubmissionsTab />}
                {step === 6 && <EvaluationEventViewResourcesTab />}
              </TransitionContainer>
            </AnimatePresence>
          </>
        )}
      </PageContent>
    </Page>
  )
})
