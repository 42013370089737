import React, { memo } from 'react'
import { motion } from 'framer-motion'
import { includes, map } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { TenderStatus, TenderTypeBadge, useGetTender } from '@cotiss/tender'
import { useGetProcurement } from '@cotiss/procurement'
import { Breadcrumb, BreadcrumbModel, Header, routerService, Skeleton, StepButtonVariant, Text, TRANSITION_DURATION } from '@cotiss/common'
import { EvaluationChairStepButton, EvaluationChairStep, EVALUATION_CHAIR_STEPS, evaluationService } from '@cotiss/evaluation'

type Props = {
  breadcrumbs?: BreadcrumbModel[]
}

export const EvaluationChairHeader = memo(({ breadcrumbs }: Props) => {
  const { replace } = useHistory()
  const { tenderId } = useParams<{ tenderId: string }>()
  const { tender, isLoading: isTenderLoading } = useGetTender(tenderId)
  const { procurement, isLoading: isProcurementLoading } = useGetProcurement(tender?.procurement)
  const stepUnlockedIndex = tender ? evaluationService.getUnlockedChairStepIndex(tender) : 0

  const isLoading = isProcurementLoading || isTenderLoading

  const handleStepChange = (step: EvaluationChairStep) => {
    replace(routerService.getHref('/evaluation/chair/:tenderId/:step?', { tenderId, step }))
  }

  const getIsDisabled = (status: TenderStatus) => !includes(tender?.validation.finishedStages, status)
  const getIsComplete = (status: TenderStatus) => includes(tender?.validation.finishedStages, status)

  const getVariant = (step: EvaluationChairStep): StepButtonVariant => {
    if (step === 'individual-evaluation') {
      return getIsComplete('evaluate') ? 'complete' : 'default'
    }

    if (step === 'consensus-scoring') {
      return getIsDisabled('evaluate') ? 'locked' : getIsComplete('consensus') ? 'complete' : 'default'
    }

    if (step === 'price-scoring') {
      if (tender?.isTwoEnvelope) {
        return getIsDisabled('consensus') ? 'locked' : getIsComplete('price') ? 'complete' : 'default'
      } else {
        return getIsComplete('price') ? 'complete' : 'default'
      }
    }

    return 'locked'
  }

  return (
    <>
      <Header>
        <Breadcrumb breadcrumbs={breadcrumbs} />
        {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
        {!isLoading && (
          <div className="flex items-center">
            {tender?.tenderType && <TenderTypeBadge className="mr-2" tenderType={tender.tenderType} />}
            <Text className="font-medium" size="h7" variant="heading">
              {tender?.title}
            </Text>
          </div>
        )}
        {isProcurementLoading && <Skeleton className="h-3.5 w-24 mt-1" variant="gray" />}
        {!isProcurementLoading && (
          <Text className="mt-0.5" size="sm" variant="light">
            {procurement?.title}
          </Text>
        )}
      </Header>
      <div className="sticky top-0 flex items-center bg-white border-t border-gray-100 shadow-sm z-10">
        {map(EVALUATION_CHAIR_STEPS, (step) => (
          <EvaluationChairStepButton key={step} className="flex-1" step={step} variant={getVariant(step)} onStepChange={handleStepChange} />
        ))}
        <motion.div
          className="absolute left-0 bottom-0 bg-secondary-500 h-1"
          animate={{ width: `${(stepUnlockedIndex / EVALUATION_CHAIR_STEPS.length) * 100}%` }}
          transition={{ duration: TRANSITION_DURATION.xl }}
          initial={false}
        />
      </div>
    </>
  )
})
