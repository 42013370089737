import React, { useCallback, useState } from 'react'
import { useContractSearchFiltersContext } from '@cotiss/contract/components/contract-list-search-filters-context.component'
import { useDebounceCallback } from 'usehooks-ts'
import { SearchInput } from '@cotiss/common/components/search-input.component'

type Props = {
  isLoading?: boolean
  isDisabled?: boolean
  className?: string
}

export const ContractListSearchInput = ({ isDisabled, className }: Props) => {
  const { queryState, queryStateDispatch } = useContractSearchFiltersContext()

  const [tempValue, setTempValue] = useState(queryState.searchQuery)

  const updateSearchQuery = useCallback((value: string) => queryStateDispatch({ type: 'UPDATE_SEARCH_QUERY', payload: value }), [queryStateDispatch])

  const debouncedHandler = useDebounceCallback(updateSearchQuery, 500)

  function handleChange(value: string) {
    setTempValue(value)
    debouncedHandler(value)
  }

  function handleClear() {
    setTempValue('')
    queryStateDispatch({ type: 'UPDATE_SEARCH_QUERY', payload: '' })
  }

  return (
    <SearchInput
      onChange={({ target }) => handleChange(target.value)}
      onClear={handleClear}
      isDisabled={isDisabled}
      value={tempValue}
      placeholder="Search title, counterparty or internal reference"
      className={className}
    />
  )
}
