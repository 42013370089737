import { groupBy, map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { useGetLoggedInUser } from '@cotiss/user'
import { TaskListEvaluationCta } from '@cotiss/task'
import { EvaluationStatusBadge, evaluationService, useListEvaluation } from '@cotiss/evaluation'
import { datetimeService, NoDataPlaceholder, paginationService, ScrollableTable, ScrollableTableColumn, Text } from '@cotiss/common'

export const EvaluationTaskTab = memo(() => {
  const { user, isLoading: isLoggedInUserLoading, isError: isLoggedInUserError } = useGetLoggedInUser()
  const { evaluations, isLoading: isEvaluationsLoading, isError: isEvaluationsError } = useListEvaluation({ userId: user?._id, type: 'panel' })
  const [currentPage, setCurrentPage] = useState(1)

  const { processedEvaluations, pagination } = useMemo(() => {
    const groupedEvaluations = groupBy(evaluations, 'tender._id')
    const _evaluations = map(
      groupedEvaluations,
      (evaluations) => ({ type: 'evaluation', item: evaluations[0], status: evaluationService.getGroupedEvaluationStatus(evaluations) } as const)
    )

    const { items: processedEvaluations, pagination } = paginationService.paginate(_evaluations, { currentPage })

    return { processedEvaluations, pagination }
  }, [evaluations, currentPage])

  if (isLoggedInUserError || isEvaluationsError) {
    return (
      <div className="h-64 flex justify-center items-center">
        <Text>Whoops, something went wrong. Please try again.</Text>
      </div>
    )
  }

  if (!(isLoggedInUserLoading || isEvaluationsLoading) && !processedEvaluations.length) {
    return <NoDataPlaceholder label="No evaluations" />
  }

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Tender',
      rows: map(processedEvaluations, ({ item }) => ({
        content: () => (
          <Text className="truncate" title={item.tender.title}>
            {item.tender.title}
          </Text>
        ),
        cta: <TaskListEvaluationCta tenderId={item.tender._id} />,
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Status',
      rows: map(processedEvaluations, ({ status }) => ({
        content: () => <EvaluationStatusBadge status={status} />,
      })),
    },
    {
      heading: 'Procurement',
      rows: map(processedEvaluations, ({ item }) => ({
        content: () => <Text size="sm">{item.tender.procurement.title}</Text>,
      })),
    },
    {
      heading: 'Date Created',
      rows: map(processedEvaluations, ({ item }) => ({
        content: () => (
          <Text variant="light" size="sm">
            {datetimeService.format(item.createdAt, 'd MMMM yyyy h:mm aaa')}
          </Text>
        ),
      })),
    },
  ]

  return (
    <ScrollableTable
      fixedColumns={fixedColumns}
      columns={columns}
      pagination={pagination}
      onPageChange={setCurrentPage}
      isLoading={isLoggedInUserLoading || isEvaluationsLoading}
    />
  )
})
