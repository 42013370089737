import { map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { PreferredSupplierAddFormData } from '@cotiss/preferred-supplier'
import { OrganisationSearchResponse, organisationService } from '@cotiss/organisation'
import {
  Badge,
  Banner,
  Breadcrumb,
  BreadcrumbModel,
  Button,
  Icon,
  ScrollableTable,
  ScrollableTableColumn,
  TableHeader,
  Text,
  paginationService,
} from '@cotiss/common'

type Props = {
  breadcrumbs: BreadcrumbModel[]
  formData: PreferredSupplierAddFormData
  isDisabled: boolean
  isLoading: boolean
  onBack: () => void
  onCreateNewContact: () => void
  onSelectOrganisation: (organisation: OrganisationSearchResponse) => void
  organisations: OrganisationSearchResponse[]
}

export const PreferredSupplierListOrganisationsStep = memo((props: Props) => {
  const { breadcrumbs, formData, isDisabled, isLoading, onBack, onCreateNewContact, onSelectOrganisation, organisations } = props
  const [currentPage, setCurrentPage] = useState(1)
  const { processedOrganisations, pagination } = useMemo(() => {
    const { items: processedOrganisations, pagination } = paginationService.paginate(organisations, { currentPage })

    return { processedOrganisations, pagination }
  }, [organisations, currentPage])
  const processedCountryCode = formData.countryCode === 'CA' || formData.countryCode === 'US' ? formData.countryCodeSubdivision : formData.countryCode

  const fixedColumns: ScrollableTableColumn[] = useMemo(
    () => [
      {
        heading: 'Name',
        rows: map(processedOrganisations, (organisation) => ({
          content: () => (
            <Text className="truncate uppercase" title={organisation.name || '--'}>
              {organisation.name || '--'}
            </Text>
          ),
          cta: (
            <Button state="outline" variant="secondary" size="xs" onClick={() => onSelectOrganisation(organisation)} isDisabled={isDisabled}>
              View <Icon className="ml-1" icon="arrow-right" />
            </Button>
          ),
        })),
      },
    ],
    [processedOrganisations, onSelectOrganisation, isDisabled]
  )

  const columns: ScrollableTableColumn[] = useMemo(
    () => [
      {
        heading: organisationService.getRegistryNumberType(processedCountryCode),
        rows: map(processedOrganisations, (organisation) => ({
          content: () => <Text size="sm">{organisation.businessNumber || '--'}</Text>,
        })),
      },
      {
        heading: 'Address',
        rows: map(processedOrganisations, (organisation) => ({
          content: () => <Text size="sm">{organisation.address || '--'}</Text>,
        })),
      },
      {
        heading: 'Status',
        rows: map(processedOrganisations, (organisation) => ({
          content: () =>
            organisation?.status ? (
              <Badge
                state="outline"
                variant={organisation.status === 'CLAIMED' ? 'secondary' : 'neutral'}
                title={
                  organisation.status === 'CLAIMED'
                    ? 'One of the contacts associated with this account has created a Cotiss account'
                    : 'None of the contacts associated with this account have created a Cotiss account'
                }
              >
                {organisation.status === 'CLAIMED' && <Icon className="mr-1" icon="check-verified-02" size={10} />}
                {organisationService.getClaimedStatusText(organisation.status)}
              </Badge>
            ) : (
              <Text size="sm" variant="light">
                --
              </Text>
            ),
        })),
      },
    ],
    [processedOrganisations]
  )

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} onBack={onBack} />
      <TableHeader className="mt-6">
        <Text className="font-semibold">
          Results <span className="text-light">({pagination.totalCount})</span>
        </Text>
        <Text variant="light" size="sm" className="mt-1">
          Select the correct company from the list below
        </Text>
      </TableHeader>
      <ScrollableTable isLoading={isLoading} fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} />
      <Banner className="mt-6" variant="light" icon="info-circle" iconVariant="secondary">
        <div className="flex justify-between items-center w-full">
          <div>
            <Text className="font-medium">Can&apos;t find what you&apos;re looking for?</Text>
            <Text className="mt-1" size="sm">
              If you can&apos;t find the correct contact, you can create one here
            </Text>
          </div>
          <Button variant="secondary" onClick={onCreateNewContact}>
            + Create
          </Button>
        </div>
      </Banner>
    </>
  )
})
