import { map } from 'lodash'
import React, { memo } from 'react'
import { Card, CardHeader, Text } from '@cotiss/common'
import { TenderQuestionReportModel } from '@cotiss/tender-question'
import { TenderResponseReportModel } from '@cotiss/tender-response'
import { TenderCriteriaReportAccordion, TenderCriterionReportModel } from '@cotiss/tender-criteria'

type Props = {
  tenderResponseReport: TenderResponseReportModel
  onView?: (tenderCriterionReport: TenderCriterionReportModel, tenderQuestionReport: TenderQuestionReportModel) => void
}

export const TenderResponseReportView = memo(({ tenderResponseReport, onView }: Props) => (
  <Card>
    <CardHeader>
      <Text className="font-semibold" size="h7">
        {tenderResponseReport.procurementResponse.supplier.name}
      </Text>
    </CardHeader>
    <Text className="font-semibold" size="h7">
      Consensus score the criteria below
    </Text>
    <Text className="mt-1" variant="light" size="sm">
      Progress through each of the non-price criteria for this supplier
    </Text>
    {map(tenderResponseReport.tenderCriteria, (tenderCriterionReport) => (
      <TenderCriteriaReportAccordion key={tenderCriterionReport._id} className="mt-4" tenderCriterionReport={tenderCriterionReport} onView={onView} />
    ))}
  </Card>
))
