import { filter, find, flatMap, forEach, includes, map, orderBy } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { useGetLoggedInUser } from '@cotiss/user'
import { TaskListContractCta } from '@cotiss/task'
import { ContractApproverStatusBadge, ContractModel, useListContractShell, ContractApproverModel } from '@cotiss/contract'
import { datetimeService, NoDataPlaceholder, paginationService, ScrollableTable, ScrollableTableColumn, Text } from '@cotiss/common'
import { useHistory } from 'react-router-dom'

type ApprovalListItem = {
  title: string
  contract: ContractModel
  approver: ContractApproverModel
  updatedAt: string
}

export const ContractTaskTab = memo(() => {
  const [currentPage, setCurrentPage] = useState(1)
  const { user, isLoading: isLoadingUser } = useGetLoggedInUser()
  const { contractShells, isLoading: isLoadingContractShell, isError } = useListContractShell({ filter: 'approvals' })
  const isLoading = isLoadingContractShell || isLoadingUser
  const history = useHistory()

  const { approvalItems, pagination } = useMemo(() => {
    const approvalItems: ApprovalListItem[] = []

    forEach(contractShells, (contractShell) => {
      forEach(contractShell.contracts, (contract) => {
        if (contract.status === 'DRAFTING') {
          return
        }

        const approver = find(
          flatMap(
            filter(contract.approvals, ({ status }) => includes(['PENDING_APPROVAL', 'APPROVED', 'REJECTED'], status)),
            ({ approvers }) => approvers
          ),
          (approver) => approver.assigned._id === user?._id && approver.status !== 'NOT_SUBMITTED'
        )

        if (!approver) {
          return
        }

        if (approver.state === 'PENDING') {
          return
        }

        approvalItems.push({
          title: contractShell.title,
          contract,
          approver,
          updatedAt: approver.updatedAt,
        })
      })
    })

    const { items, pagination } = paginationService.paginate(orderBy(approvalItems, 'updatedAt', 'desc'), { currentPage })

    return { approvalItems: items, pagination }
  }, [contractShells, currentPage, history])

  if (isError) {
    return (
      <div className="h-64 flex justify-center items-center">
        <Text>Whoops, something went wrong. Please try again.</Text>
      </div>
    )
  }

  if (!isLoading && !approvalItems.length) {
    return <NoDataPlaceholder label="No contracts" />
  }

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Contract Title',
      rows: map(approvalItems, ({ contract, title }) => ({
        content: () => (
          <Text className="truncate" title={title}>
            {title}
          </Text>
        ),
        cta: <TaskListContractCta contract={contract} />,
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Status',
      rows: map(approvalItems, ({ approver }) => ({
        content: () => <ContractApproverStatusBadge status={approver.status} />,
      })),
    },
    {
      heading: 'Task Type',
      rows: map(approvalItems, ({ contract }) => ({
        content: () => (
          <Text size="sm" variant="light">
            {contract.cessationDate ? 'Cessation request' : 'Approval'}
          </Text>
        ),
      })),
    },
    {
      heading: 'Date',
      rows: map(approvalItems, ({ updatedAt }) => ({
        content: () => (
          <Text variant="light" size="sm">
            {datetimeService.format(updatedAt, 'd MMMM yyyy h:mm aaa')}
          </Text>
        ),
      })),
    },
  ]

  return <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
})
