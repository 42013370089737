import { ApprovalStatusBadge, ApprovalViewHeader, approvalService, useGetApproval } from '@cotiss/approval'
import { BreadcrumbModel, Button, Card, CardHeader, Field, Page, PageContent, Skeleton, Text, datetimeService, routerService } from '@cotiss/common'
import { useGetProcurement } from '@cotiss/procurement'
import { userService } from '@cotiss/user'
import React, { memo, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetDocument } from '@cotiss/document'

export const ApprovalViewPage = memo(() => {
  const { id } = useParams<{ id: string }>()
  const { approval, isLoading: isApprovalLoading } = useGetApproval(id)
  const { procurement, isLoading: isProcurementLoading } = useGetProcurement(approval?.procurement._id)
  const { document: recommendationReport } = useGetDocument(approval?.tender?.recommendationReport)
  const isLoading = isApprovalLoading || isProcurementLoading
  const breadcrumbs: BreadcrumbModel[] = useMemo(() => {
    return [
      {
        label: 'Tasks',
        href: routerService.getHref('/task/list/:tab?', { tab: 'approval' }),
      },
      {
        label: 'Approval request',
      },
    ]
  }, [])

  const renderReviewDetailsAction = (isLoading: boolean) => {
    if (approval?.approvalType === 'go-to-market') {
      return (
        <Field className="mt-8" label="Go to market request">
          {isLoading && <Skeleton className="h-3 w-1/3" />}
          {!isLoading && (
            <Button href={routerService.getHref(`/approval/view/:id/request/:tab?`, { id })} state="text" variant="link" isLink>
              View request details
            </Button>
          )}
        </Field>
      )
    }

    if (approval?.approvalType === 'recommendation' && recommendationReport) {
      return (
        <Field className="mt-8" label="Recommendation report">
          {isLoading && <Skeleton className="h-3 w-1/3" />}
          {!isLoading && (
            <Button
              state="text"
              variant="link"
              isLink
              href={routerService.getHref('/document/view/:id', { id: recommendationReport._id })}
              isOpenNewTab
            >
              Download report
            </Button>
          )}
        </Field>
      )
    }
  }

  const renderContent = () => (
    <>
      <Field label="Approval type">
        {isLoading && <Skeleton className="h-3 w-1/3" />}
        {!isLoading && <Text>{approval ? approvalService.getDisplayApprovalType(approval) : '--'}</Text>}
      </Field>
      <Field className="mt-8" label="Date requested">
        {isLoading && <Skeleton className="h-3 w-1/3" />}
        {!isLoading && <Text>{approval ? datetimeService.format(approval.createdAt, 'd MMMM yyyy h:mm aaa') : '--'}</Text>}
      </Field>
      <Field className="mt-8" label="Status">
        {isLoading && <Skeleton className="h-5 w-20" />}
        {!isLoading && <Text>{approval ? <ApprovalStatusBadge status={approval.status} /> : '--'}</Text>}
      </Field>
      <Field className="mt-8" label="Requested by">
        {isLoading && <Skeleton className="h-3 w-1/3" />}
        {!isLoading && <Text>{approval ? userService.getFullName(approval.assigned) : '--'}</Text>}
      </Field>
      {renderReviewDetailsAction(isLoading)}
      {approval?.feedback && (
        <Field className="mt-8" label="Feedback">
          <Text>{approval?.feedback}</Text>
        </Field>
      )}
    </>
  )

  return (
    <Page>
      <ApprovalViewHeader breadcrumbs={breadcrumbs} />
      <PageContent>
        <Card>
          <CardHeader>
            {isLoading && <Skeleton className="h-3 w-1/3 mt-1" />}
            {!isLoading && (
              <Text className="font-semibold" size="h5">
                {procurement?.title}
              </Text>
            )}
          </CardHeader>
          {renderContent()}
        </Card>
      </PageContent>
    </Page>
  )
})
