import { filter, flatMap, forEach, some } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { Button, Card, CardHeader, TableHeader, Text, sentryService, useAsyncEffect, useCallout, useToast } from '@cotiss/common'
import {
  EvaluationEventPanelMemberAddModal,
  EvaluationEventPanelList,
  useEvaluationUser,
  useEvaluationEvent,
  useEvaluationEnvelope,
  EvaluationEventUserList,
  EvaluationEventUserAddModal,
  useEvaluationEventAnalytics,
} from '@cotiss/evaluation-event'

type Props = {
  onNext: () => void
  onBack: () => void
}

export const EvaluationEventWizardPanelStep = memo(({ onNext, onBack }: Props) => {
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const [errorText, setErrorText] = useState('')
  const { track } = useEvaluationEventAnalytics()
  const { evaluationEvent } = useEvaluationEvent()
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const { evaluationUsers, queryEvaluationUserList } = useEvaluationUser()

  useEffect(() => {
    track('evaluation_event_wizard_panel_view')
  }, [])

  useAsyncEffect(async () => {
    if (!evaluationEvent) {
      return
    }

    try {
      await queryEvaluationUserList({ filter: { evaluationEventId: evaluationEvent.id }, pagination: { page: 1, pageSize: 100 } })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }, [])

  useEffect(() => {
    setErrorText('')
  }, [evaluationUsers])

  const handleClickContinue = () => {
    const evaluationUsersWithAccessControl = filter(evaluationUsers, ({ accessControls }) => Boolean(accessControls.length))

    const envelopesRequiringEvaluator = []
    const envelopesRequiringModerator = []
    const errors = []

    forEach(evaluationEnvelopes, (envelope) => {
      const userAccessControls = flatMap(evaluationUsersWithAccessControl, ({ accessControls }) =>
        filter(accessControls, { resourceType: 'envelope', resourceId: envelope.id })
      )

      // Every envelope must have at least 1 evaluator to continue.
      if (!some(userAccessControls, { access: 'evaluate' })) {
        envelopesRequiringEvaluator.push(envelope)
      }

      // Per-envelope: If there are multiple evaluators there must be a moderator
      if (filter(userAccessControls, { access: 'evaluate' }).length > 1 && !some(userAccessControls, { access: 'moderate' })) {
        envelopesRequiringModerator.push(envelope)
      }
    })

    if (envelopesRequiringEvaluator.length) {
      errors.push('Every envelope must have at least one evaluator.')
    }

    if (envelopesRequiringModerator.length) {
      errors.push('An envelope with multiple evaluators requires a moderator.')
    }

    if (!errors.length) {
      onNext()
      return
    }

    setErrorText(errors.join('. '))
  }

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <Text className="font-semibold" variant="heading" size="h5">
          Evaluation panel
        </Text>
        <div className="flex flex-col items-end ml-4">
          <div>
            <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm">
              Back
            </Button>

            <Button onClick={handleClickContinue} variant="secondary" size="sm">
              Continue
            </Button>
          </div>
          {errorText && (
            <Text className="mt-1" size="sm" variant="danger">
              {errorText}
            </Text>
          )}
        </div>
      </CardHeader>
      <TableHeader className="flex justify-between items-center">
        <div>
          <Text className="font-semibold">Evaluation panel</Text>
          <Text variant="light" size="sm">
            Select panel members to evaluate and moderate the supplier submissions
          </Text>
        </div>
        <Button state="translucent" size="xs" variant="secondary-dark" onClick={() => openModal(<EvaluationEventPanelMemberAddModal />)}>
          + Add panel member
        </Button>
      </TableHeader>
      <EvaluationEventPanelList isEditable />

      <TableHeader className="flex justify-between items-center mt-8">
        <div>
          <Text className="font-semibold">Evaluation owners</Text>
          <Text variant="light" size="sm">
            Assign users to give them access to view and edit the evaluation event details
          </Text>
        </div>
        <Button state="translucent" size="xs" variant="secondary-dark" onClick={() => openModal(<EvaluationEventUserAddModal />)}>
          + Add owner
        </Button>
      </TableHeader>
      <EvaluationEventUserList isEditable />
    </Card>
  )
})
