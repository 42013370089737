import { find, forEach, map, pickBy, some } from 'lodash'
import { useHistory } from 'react-router-dom'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useListMetafield } from '@cotiss/metafield'
import { UserAvatarGroup, useGetLoggedInUser } from '@cotiss/user'
import { metafieldValueService, useListMetafieldValue } from '@cotiss/metafield-value'
import {
  ContractCreateDrawer,
  ContractListItemCta,
  ContractShellFilterPopulatedModel,
  ContractStatusBadge,
  ContractVariationStatusBadge,
  FilterContractShellSortKey,
  useMutateContractShell,
  ContractNextExpirationDate,
} from '@cotiss/contract'
import {
  Button,
  Icon,
  NoDataPlaceholder,
  PaginationModel,
  ScrollableTable,
  ScrollableTableColumn,
  Skeleton,
  TableHeader,
  Text,
  Tooltip_DEPRECATED,
  datetimeService,
  routerService,
  sentryService,
  useCallout,
  useDeepMemo,
  useFeature,
  useSortTable,
  useToast,
  utilService,
} from '@cotiss/common'
import { useContractSearchFiltersContext } from '@cotiss/contract/components/contract-list-search-filters-context.component'
import HighlightedText from '@cotiss/common/components/highlighted-text'
import { useAdvancedFiltersContext } from '@cotiss/common/modals/advanced-filters/advanced-filters.context'
import { Filter } from '@cotiss/common/models/filter.model'

// EXCLUDES: archived, status, expired, isMine
const FILTER_FIELDS_WHITELIST = [
  'contractOwners',
  'title',
  'procurement',
  'type',
  'approver',
  'expiryDate',
  'totalExercised',
  'totalValue',
  'supplier',
  'variationStatus',
  'createdBy',
  'variatedBy',
  'internalReference',
  'contractId',
  'contractingEntity',
  'status',
]

export const ContractList = memo(() => {
  const { replace } = useHistory()
  const { openToast } = useToast()
  const { user } = useGetLoggedInUser()
  const { openDrawer } = useCallout()
  const [isDownloading, setIsDownloading] = useState(false)
  const [pagination, setPagination] = useState<PaginationModel>()
  const { filterContractShell, exportCsv } = useMutateContractShell()
  const [isLoadingContractShells, setIsLoadingContractShells] = useState(false)
  const [contractShells, setContractShell] = useState<ContractShellFilterPopulatedModel[]>([])
  const isContractManagementListViewMetafieldsEnabled = useFeature('contract-management-list-view-metafields')

  const { sortKey, sortDirection, onSort } = useSortTable<FilterContractShellSortKey>({ initialKey: 'createdAt', initialSortDirection: 'desc' })
  const { metafields, isFetching: isLoadingMetafields } = useListMetafield({
    entityType: 'CONTRACT',
    isEnabled: isContractManagementListViewMetafieldsEnabled,
  })
  const { metafieldValues, isFetching: isLoadingMetafieldValues } = useListMetafieldValue({
    resourceIds: map(contractShells, (contractShell) => contractShell.contracts._id),
    isEnabled: isContractManagementListViewMetafieldsEnabled,
  })

  const isLoading = isLoadingContractShells || isLoadingMetafields || isLoadingMetafieldValues

  const { queryState, queryStateDispatch, processedFilters, isQuerying } = useContractSearchFiltersContext()
  const { searchQuery, currentPage } = queryState

  const { validAdvancedFilters, advancedFiltersDispatch } = useAdvancedFiltersContext()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    queryStateDispatch({ type: 'UPDATE_ADVANCED_FILTERS', payload: validAdvancedFilters.map(({ id, ...rest }) => ({ ...rest } as Filter)) })
  }, [validAdvancedFilters])

  useEffect(() => {
    const queryParams = utilService.generateUrlSearchParams({
      statusCard: queryState.statusCard ?? undefined,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filters: JSON.stringify(validAdvancedFilters.map(({ id, ...rest }) => ({ ...rest }))),
      q: searchQuery,
    })
    replace({
      pathname: routerService.getHref('/contract/list/:tab?', { tab: queryState.primaryTab }),
      search: isQuerying ? queryParams : '',
    })
  }, [validAdvancedFilters, searchQuery, queryState.primaryTab, queryState.statusCard, isQuerying])

  const refreshContractShells = async () => {
    try {
      setIsLoadingContractShells(true)
      const { contractShells, pagination, meta } = await filterContractShell({
        filters: processedFilters,
        searchQuery,
        currentPage: currentPage,
        pageSize: 20,
        sort: sortKey,
        order: sortDirection,
      })

      setContractShell(contractShells)
      setPagination(pagination)

      // Whitelist only filter fields that appear in the whitelist OR custom metafields
      const filterFields = pickBy(meta, (_, key) => FILTER_FIELDS_WHITELIST.includes(key) || key.startsWith('metafield'))
      advancedFiltersDispatch({ type: 'SET_FILTER_FIELDS', payload: filterFields })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
    setIsLoadingContractShells(false)
  }

  const contractShellQuery = useDeepMemo(
    () => ({
      queryState,
      sortKey,
      sortDirection,
    }),
    [queryState, sortKey, sortDirection]
  )

  useEffect(() => {
    refreshContractShells()
  }, [contractShellQuery])

  const handleDownloadCsv = async () => {
    try {
      setIsDownloading(true)
      const csvData = await exportCsv({ filters: processedFilters, searchQuery, timeZone: datetimeService.getLocalTimeZone() })

      utilService.downloadCsv({
        csv: csvData.csv,
        filename: `contract_export_${datetimeService.format(new Date(), 'd MMMM yyyy h:mm aaa')}.csv`,
      })

      setIsDownloading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsDownloading(false)
    }
  }

  const handlePageChange = (page: number) => {
    queryStateDispatch({ type: 'UPDATE_CURRENT_PAGE', payload: page })
  }

  const contractListTableHeader = (
    <TableHeader className="flex justify-between items-center" variant="white">
      <Text size="md" className="flex items-center gap-1">
        Results{' '}
        {isLoading ? (
          <Skeleton className="h-3 w-6 inline-block" />
        ) : (
          <Text isInline variant="light">
            ({pagination?.totalCount})
          </Text>
        )}
      </Text>
      <Button
        size="xs"
        variant="secondary"
        state="ghost"
        isDisabled={isLoading}
        isLoading={isDownloading}
        onClick={handleDownloadCsv}
        className="gap-1"
      >
        <Icon icon="download-01" /> <span>Download CSV</span>
      </Button>
    </TableHeader>
  )

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Title',
        onSort: () => onSort('title'),
        rows: map(contractShells, (contractShell) => ({
          content: () => (
            <Text className="truncate" title={contractShell.title}>
              <HighlightedText text={contractShell.title} highlightText={searchQuery} />
            </Text>
          ),
          cta: (
            <>
              {/* For initial contracts that are in drafting, only the person who created the contract can view */}
              {!contractShell.contracts.variationTypes.length &&
              contractShell.contracts.status == 'DRAFTING' &&
              !some(contractShell.contracts.metadata.owners, (owner) => owner._id === user?._id) ? (
                <Tooltip_DEPRECATED className="hover:cursor-default" tooltip="This contract is locked as it is still in draft">
                  <Icon icon="lock" variant="light" />
                </Tooltip_DEPRECATED>
              ) : (
                <ContractListItemCta contractShell={contractShell} userId={user?._id} onSuccess={refreshContractShells} />
              )}
            </>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Contract status',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => <ContractStatusBadge status={contracts?.status} />,
        })),
      },
      {
        heading: 'Counterparties',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              <HighlightedText
                text={contracts.metadata.suppliers.map((counterparty) => counterparty.name).join(', ') || '--'}
                highlightText={searchQuery}
              />
            </Text>
          ),
        })),
      },
      {
        heading: 'Next expiry',
        rows: map(contractShells, ({ contracts: { priceDurations } }) => ({
          content: () => <ContractNextExpirationDate priceDurations={priceDurations}></ContractNextExpirationDate>,
          tdClassName: 'max-w-[350px]',
        })),
      },
      {
        heading: 'Owners',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => <UserAvatarGroup users={contracts.metadata.owners} />,
        })),
      },
      {
        heading: 'Variation status',
        rows: map(contractShells, ({ variationStatus }) => ({
          content: () => {
            return <ContractVariationStatusBadge status={variationStatus} />
          },
        })),
      },
      {
        heading: 'Internal reference',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              <HighlightedText text={contracts.metadata.externalReference || '--'} highlightText={searchQuery} />
            </Text>
          ),
        })),
      },
      {
        heading: 'Total Exercised',
        onSort: () => onSort('totalExercised'),
        rows: map(
          contractShells,
          ({
            totalExercised,
            contracts: {
              metadata: { currency },
            },
          }) => ({
            content: () => (
              <Text variant="light" className="truncate" size="sm">
                {currency && `${utilService.formatAsCurrency(totalExercised, currency)}`}
              </Text>
            ),
            tdClassName: 'max-w-[350px]',
          })
        ),
      },
      {
        heading: 'Total value',
        onSort: () => onSort('totalValue'),
        rows: map(
          contractShells,
          ({
            totalValue,
            contracts: {
              metadata: { currency },
            },
          }) => ({
            content: () => (
              <Text variant="light" className="truncate" size="sm">
                {currency && `${utilService.formatAsCurrency(totalValue, currency)}`}
              </Text>
            ),
            tdClassName: 'max-w-[350px]',
          })
        ),
      },
      {
        heading: 'Procurement',
        onSort: () => onSort('procurementTitle'),
        rows: map(contractShells, ({ procurement }) => ({
          content: () => (
            <Text variant="light" className="truncate" size="sm">
              {procurement?.title || '--'}
            </Text>
          ),
          tdClassName: 'max-w-[350px]',
        })),
      },
      {
        heading: 'Date created',
        onSort: () => onSort('createdAt'),
        rows: map(contractShells, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Contract id',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.internalReference || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Contracting entity',
        rows: map(contractShells, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.contractingEntity?.name || '--'}
            </Text>
          ),
        })),
      },
    ]

    // Add metafields columns
    if (isContractManagementListViewMetafieldsEnabled) {
      forEach(metafields, (metafield) => {
        columns.push({
          heading: metafield.fieldLabel,
          rows: map(contractShells, ({ contracts: { _id: contractId } }) => ({
            tdClassName: 'max-w-[350px]',
            content: () => {
              const metafieldValue = find(metafieldValues, { metafield: metafield._id, resourceId: contractId })
              const processedMetafieldValue = metafieldValueService.renderFieldValue({ metafield, metafieldValue })

              if (metafield.fieldType === 'HYPERLINK' && metafieldValue) {
                return (
                  <Button isExternalLink isTruncated href={processedMetafieldValue} variant="secondary" state="text">
                    {processedMetafieldValue}
                  </Button>
                )
              }

              return (
                <Text className="whitespace-pre-wrap line-clamp-3" size="sm" variant="light" title={processedMetafieldValue}>
                  {processedMetafieldValue}
                </Text>
              )
            },
          })),
        })
      })
    }

    return { fixedColumns, columns }
  }, [contractShells, user, metafields, metafieldValues, isContractManagementListViewMetafieldsEnabled])

  if (!isLoading && !contractShells.length) {
    return (
      <div>
        {contractListTableHeader}
        <div className="flex items-center justify-center h-60 bg-white rounded border">
          {isQuerying ? (
            <Text size="md" variant="light">
              No results
            </Text>
          ) : (
            <NoDataPlaceholder
              illustration="dot-list"
              variant="transparent"
              label="You haven't created any contracts yet. Once created, you can track your contracts here"
              ctaSize="xs"
              ctaLabel="+ Create contract"
              onCtaClick={() => openDrawer(<ContractCreateDrawer />)}
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <div>
      {contractListTableHeader}
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={handlePageChange} isLoading={isLoading} />
    </div>
  )
})
