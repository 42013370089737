import { clamp, map, sortBy } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { AsyncInput, Icon, Table, TableColumn, Text, Tooltip_DEPRECATED, utilService } from '@cotiss/common'
import { evaluationEventService, useEvaluationCriteria, useEvaluationEventAnalytics } from '@cotiss/evaluation-event'

type Props = {
  className?: string
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
  evaluationSubCriteria: GqlEvaluationCriteriaFieldsFragment[]
  criteriaWeightById: Record<string, number>
  weightedPercentageById: Record<string, number>
  overallWeightedPercentageById: Record<string, number>
  setCriteriaWeightById: (criteriaWeightById: Record<string, number>) => void
  isEditable?: boolean
}

export const EvaluationEventSubCriteriaWeightList = memo((props: Props) => {
  const {
    className,
    evaluationEnvelope,
    parentEvaluationCriteriaItem,
    evaluationSubCriteria,
    weightedPercentageById,
    overallWeightedPercentageById,
    isEditable,
  } = props
  const { track } = useEvaluationEventAnalytics()
  const { mutateUpdateEvaluationCriteria } = useEvaluationCriteria()
  const [subCriteriaWeightById, setSubCriteriaWeightById] = useState<Record<string, number>>(
    evaluationEventService.getWeightById({ items: evaluationSubCriteria })
  )

  const subCriteriaWeightPercentageById = useMemo(() => {
    const totalSubCriteriaWeight = evaluationEventService.getTotalWeight({ weightById: subCriteriaWeightById })

    return evaluationEventService.getWeightedPercentageById({ weightById: subCriteriaWeightById, totalWeight: totalSubCriteriaWeight })
  }, [subCriteriaWeightById])

  const handleUpdateWeight = async (evaluationCriteriaId: string) => {
    track('evaluation_event_wizard_sub_weight_criteria_update_submit')

    const clampedWeight = clamp(Number(subCriteriaWeightById[evaluationCriteriaId]) || 0, 1, 100)
    setSubCriteriaWeightById({ ...subCriteriaWeightById, [evaluationCriteriaId]: clampedWeight })

    await mutateUpdateEvaluationCriteria({ evaluationCriteriaId, weight: clampedWeight })
  }

  const { columns } = useMemo(() => {
    const sortedSubCriteria = sortBy(evaluationSubCriteria, 'index')

    const columns: TableColumn[] = [
      {
        heading: 'Sub-criteria',
        rows: [
          ...map(sortedSubCriteria, ({ content, index }) => ({
            content: () => (
              <div className="flex items-start">
                <Text className="mr-2">
                  {parentEvaluationCriteriaItem.index}.{index}.
                </Text>
                <Text>{content}</Text>
              </div>
            ),
          })),
          {
            content: () => <Text className="font-medium">Totals</Text>,
          },
        ],
      },
      {
        heading: 'Value (1 - 100)',
        thClassName: 'w-28',
        rows: [
          ...map(sortedSubCriteria, ({ id, weight }) => ({
            content: () =>
              isEditable ? (
                <div className="relative w-20">
                  <AsyncInput
                    value={subCriteriaWeightById[id]}
                    onChange={({ target }) => setSubCriteriaWeightById({ ...subCriteriaWeightById, [id]: Number(target.value) || 0 })}
                    onSubmit={() => handleUpdateWeight(id)}
                    onFocus={({ target }) => target.select()}
                    min={0}
                    max={100}
                    placeholder="--"
                    isDisabled={sortedSubCriteria.length < 2}
                  />
                  {sortedSubCriteria.length < 2 && (
                    <Tooltip_DEPRECATED
                      className="top-2 right-2"
                      tooltipClassName="text-center"
                      tooltip="You cannot edit the weight when you only have 1 criteria"
                      isAbsolute
                    >
                      <Icon icon="lock" variant="light" />
                    </Tooltip_DEPRECATED>
                  )}
                </div>
              ) : (
                <Text className="text-right">{weight}</Text>
              ),
          })),
          {
            content: () => <></>,
          },
        ],
      },
      {
        heading: 'Weight',
        thClassName: 'text-right w-32',
        rows: [
          ...map(sortedSubCriteria, ({ id }) => ({
            tdClassName: 'text-right',
            content: () => (
              <>
                <Text>{utilService.formatAsPercentage(Number(subCriteriaWeightPercentageById[id]) * 100) || '--'}</Text>
                <Text className="mt-1" variant="light" size="sm">
                  ({subCriteriaWeightById[id]}/{evaluationEventService.getTotalWeight({ weightById: subCriteriaWeightById })})
                </Text>
              </>
            ),
          })),
          {
            tdClassName: 'text-right',
            content: () => (
              <Text className="font-medium" variant="secondary">
                100.00%
              </Text>
            ),
          },
        ],
      },
      {
        heading: 'Envelope weight',
        thClassName: 'text-right w-32',
        rows: [
          ...map(sortedSubCriteria, ({ id, parentEvaluationCriteriaId }) => ({
            tdClassName: 'text-right',
            content: () => (
              <>
                <Text>
                  {utilService.formatAsPercentage(
                    Number(subCriteriaWeightPercentageById[id]) * Number(weightedPercentageById[parentEvaluationCriteriaId as string]) * 100
                  ) || '--'}
                </Text>
                <Text className="mt-1" variant="light" size="sm">
                  ({utilService.formatAsPercentage(Number(subCriteriaWeightPercentageById[id]) * 100, 0)} x{' '}
                  {utilService.formatAsPercentage(Number(weightedPercentageById[parentEvaluationCriteriaId as string]) * 100, 0)})
                </Text>
              </>
            ),
          })),
          {
            tdClassName: 'text-right',
            content: () => (
              <Text className="font-medium" variant="secondary">
                {utilService.formatAsPercentage(Number(weightedPercentageById[parentEvaluationCriteriaItem.id]) * 100)}
              </Text>
            ),
          },
        ],
      },
      {
        heading: 'Overall weight',
        thClassName: 'text-right w-40',
        rows: [
          ...map(sortedSubCriteria, ({ id, parentEvaluationCriteriaId }) => ({
            tdClassName: 'text-right',
            content: () => (
              <>
                <Text>
                  {utilService.formatAsPercentage(
                    Number(subCriteriaWeightPercentageById[id]) *
                      Number(weightedPercentageById[parentEvaluationCriteriaId as string]) *
                      Number(weightedPercentageById[evaluationEnvelope.id]) *
                      100
                  ) || '--'}
                </Text>
                <Text className="mt-1" variant="light" size="sm">
                  ({utilService.formatAsPercentage(Number(subCriteriaWeightPercentageById[id]) * 100, 0)} x{' '}
                  {utilService.formatAsPercentage(Number(weightedPercentageById[parentEvaluationCriteriaId as string]) * 100, 0)} x{' '}
                  {utilService.formatAsPercentage(Number(weightedPercentageById[evaluationEnvelope.id]) * 100, 0)})
                </Text>
              </>
            ),
          })),
          {
            tdClassName: 'text-right',
            content: () => (
              <Text className="font-medium" variant="secondary">
                {utilService.formatAsPercentage(Number(overallWeightedPercentageById[parentEvaluationCriteriaItem.id]) * 100)}
              </Text>
            ),
          },
        ],
      },
    ]

    return { columns }
  }, [evaluationSubCriteria, subCriteriaWeightPercentageById, subCriteriaWeightById, weightedPercentageById, isEditable])

  return <Table className={className} columns={columns} />
})
