import { Card, CardHeader, NoDataPlaceholder, Skeleton, Text } from '@cotiss/common'
import React, { useMemo } from 'react'
import { ContractStatusBadge, ContractAssociations, contractService, useGetContractShell } from '@cotiss/contract'
import { useParams } from 'react-router-dom'

export const ContractViewAssociatedTab = () => {
  const { contractShellId } = useParams<{ contractShellId: string }>()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const contract = useMemo(() => (contractShell ? contractService.getContract(contractShell) : null), [])

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <div>
            <Skeleton className="h-2 w-1/3 mb-1" />
            <Text className="font-semibold" size="h5">
              Associated contracts
            </Text>
          </div>
        </CardHeader>
        <div className="py-6 flex flex-wrap gap-6">
          <Skeleton className="w-48 h-48" />
          <Skeleton className="w-48 h-48" />
          <Skeleton className="w-48 h-48" />
        </div>
      </Card>
    )
  }

  if (!contract) {
    return (
      <Card>
        <NoDataPlaceholder label="No active contract to view" />
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader>
        <div>
          <Text className="mb-1" size="sm" variant="light">
            {contractShell?.title || ''}
          </Text>
          <Text className="font-semibold" size="h5">
            Associations
          </Text>
        </div>
        <ContractStatusBadge status={contract.status} />
      </CardHeader>
      <ContractAssociations contractShellId={contractShellId} isEditable />
    </Card>
  )
}
