import {
  Button,
  ConfirmModal,
  FourOhThreePage,
  Header,
  Icon,
  NoDataPlaceholder,
  Page,
  PageContent,
  ScrollableTable,
  ScrollableTableColumn,
  TabModel,
  TableRowCta,
  Tabs,
  Text,
  TransitionContainer,
  datetimeService,
  routerService,
  sentryService,
  useFeature,
  useCallout,
  useSortTable,
  useToast,
  useTransition,
  utilService,
} from '@cotiss/common'
import { useListMetafield } from '@cotiss/metafield'
import { metafieldValueService, useListMetafieldValue } from '@cotiss/metafield-value'
import { ListProcurementSortKey, ProcurementCreateUpdateModal, useListProcurement, useMutateProcurement } from '@cotiss/procurement'
import { useListProject } from '@cotiss/project'
import { TenderTypeBadge } from '@cotiss/tender'
import { UserAvatarGroup, useUserAccess } from '@cotiss/user'
import { AnimatePresence } from 'framer-motion'
import { find, findIndex, forEach, includes, map } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

export type ProcurementListTab = 'archived' | 'active'

const PROCUREMENT_LIST_TABS: TabModel<ProcurementListTab>[] = [
  { id: 'active', label: 'Active' },
  { id: 'archived', label: 'Archived' },
]

export const ProjectListPage = memo(() => {
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const { permissions } = useUserAccess()
  const { tab } = useParams<{ tab?: ProcurementListTab }>()
  const { step, transition, onTransition } = useTransition({ initialStep: tab ? findIndex(PROCUREMENT_LIST_TABS, ({ id }) => id === tab) + 1 : 0 })
  const { replace, push } = useHistory()
  const { projects, isLoading: isProjectsLoading } = useListProject()
  const isProcurementListViewMetafieldsEnabled = useFeature('procurement-list-view-metafields')
  const { metafields, isFetching: isLoadingMetafields } = useListMetafield({
    entityType: 'PROCUREMENT_PLAN',
    isEnabled: isProcurementListViewMetafieldsEnabled,
  })
  const { updateProcurement, exportCsv } = useMutateProcurement()
  const [isDownloading, setIsDownloading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const { sortKey, sortDirection, onSort } = useSortTable<ListProcurementSortKey>({ initialKey: 'createdAt' })
  const {
    procurements,
    pagination,
    meta,
    isLoading: isProcurementsLoading,
  } = useListProcurement({
    currentPage,
    sort: sortKey,
    order: sortDirection,
    pageSize: 20,
    isArchived: tab === 'archived',
  })
  const { metafieldValues, isFetching: isLoadingMetafieldValues } = useListMetafieldValue({
    resourceIds: map(procurements, (procurement) => procurement._id),
    isEnabled: isProcurementListViewMetafieldsEnabled,
  })
  const isLoading = isProjectsLoading || isProcurementsLoading || isLoadingMetafields || isLoadingMetafieldValues

  useEffect(() => {
    if (!tab) {
      replace(routerService.getHref('/project/list/:tab?', { tab: 'active' }))
    }
  }, [])

  const handleTabChange = (_tab: ProcurementListTab) => {
    const newStep = findIndex(PROCUREMENT_LIST_TABS, ({ id }) => id === _tab) + 1
    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    push(routerService.getHref('/project/list/:tab?', { tab: _tab }))
  }

  const handleDownloadCsv = async () => {
    try {
      setIsDownloading(true)
      const csvData = await exportCsv({
        isArchived: tab === 'archived',
        timeZone: datetimeService.getLocalTimeZone(),
      })

      utilService.downloadCsv({
        csv: csvData.csv,
        filename: `procurement_export_${datetimeService.format(new Date(), 'd MMMM yyyy h:mm aaa')}.csv`,
      })

      setIsDownloading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsDownloading(false)
    }
  }

  if (!permissions.hasProcurementAccess || !permissions.isBuyer) {
    return <FourOhThreePage />
  }

  const renderContent = () => {
    if (!isLoading && !procurements.length) {
      return (
        <div className="flex items-center justify-center h-60 bg-gray-200 rounded">
          <NoDataPlaceholder
            illustration="dot-list"
            variant="transparent"
            label={tab === 'active' ? 'You have not created any procurements yet.' : 'No procurements have been found.'}
            ctaSize="xs"
            ctaLabel={tab === 'active' ? 'Create procurement' : undefined}
            onCtaClick={tab === 'active' ? () => openModal(<ProcurementCreateUpdateModal />) : undefined}
          />
        </div>
      )
    }

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Procurement name',
        onSort: () => onSort('title'),
        rows: map(procurements, (procurement) => {
          const hasProcurementAccess = includes(meta?.hasAccess, procurement._id)

          return {
            content: () => <Text className="truncate">{procurement.title}</Text>,
            cta: hasProcurementAccess ? (
              <TableRowCta
                cta={
                  procurement.isArchived
                    ? undefined
                    : {
                        label: (
                          <>
                            View <Icon className="ml-1" icon="arrow-right" />
                          </>
                        ),
                        href: routerService.getHref('/procurement/overview/:procurementId/:tab?/:nestedTab?', { procurementId: procurement._id }),
                      }
                }
                actions={[
                  ...(procurement.isArchived
                    ? []
                    : [
                        {
                          label: 'Edit',
                          onClick: () => openModal(<ProcurementCreateUpdateModal procurement={procurement} />),
                        },
                      ]),
                  {
                    label: procurement.isArchived ? 'Unarchive' : 'Archive',
                    onClick: () =>
                      openModal(
                        <ConfirmModal
                          heading={procurement.isArchived ? 'Unarchive procurement' : 'Archive procurement'}
                          description={
                            procurement.isArchived
                              ? `Are you sure you want unarchive this procurement?`
                              : `Are you sure you want to archive this procurement?`
                          }
                          onSubmit={async () => {
                            await updateProcurement(procurement._id, { isArchived: !procurement.isArchived })
                          }}
                        />
                      ),
                  },
                ]}
              />
            ) : (
              <Icon icon="lock" variant="light" size={20} />
            ),
          }
        }),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Date created',
        onSort: () => onSort('createdAt'),
        rows: map(procurements, ({ createdAt }) => ({
          content: () => (
            <Text variant="light" size="sm">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Reference',
        rows: map(procurements, ({ internalIdentifier }) => ({
          content: () => (
            <Text className="truncate" size="sm">
              {internalIdentifier}
            </Text>
          ),
        })),
      },
      {
        heading: 'Project',
        rows: map(procurements, ({ project }) => ({
          content: () => (
            <Text className="truncate max-w-xs" size="sm">
              {find(projects, { _id: project })?.title || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Users',
        rows: map(procurements, ({ accessControlUsers }) => ({
          content: () => <UserAvatarGroup users={accessControlUsers} />,
        })),
      },
      {
        heading: 'Type',
        rows: map(procurements, ({ tenders }) => ({
          content: () => (
            <>
              {map(tenders, (tender) => (
                <TenderTypeBadge key={tender._id} className="mr-1" tenderType={tender.tenderType} />
              ))}
            </>
          ),
        })),
      },
      {
        heading: 'Opex budget',
        onSort: () => onSort('opexBudget'),
        rows: map(procurements, ({ opexBudget }) => ({
          content: () => (
            <Text variant="light" size="sm">
              {opexBudget ? utilService.formatAsCurrency(opexBudget.amount, opexBudget.currency) : '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Capex budget',
        onSort: () => onSort('capexBudget'),
        rows: map(procurements, ({ capexBudget }) => ({
          content: () => (
            <Text variant="light" size="sm">
              {capexBudget ? utilService.formatAsCurrency(capexBudget.amount, capexBudget.currency) : '--'}
            </Text>
          ),
        })),
      },
    ]

    // Add metafields columns
    if (isProcurementListViewMetafieldsEnabled) {
      forEach(metafields, (metafield) => {
        columns.push({
          heading: metafield.fieldLabel,
          rows: map(procurements, (procurement) => ({
            tdClassName: 'max-w-[350px]',
            content: () => {
              const metafieldValue = find(metafieldValues, { metafield: metafield._id, resourceId: procurement._id })
              const processedMetafieldValue = metafieldValueService.renderFieldValue({ metafield, metafieldValue })

              if (metafield.fieldType === 'HYPERLINK' && metafieldValue) {
                return (
                  <Button isExternalLink isTruncated href={processedMetafieldValue} variant="secondary" state="text">
                    {processedMetafieldValue}
                  </Button>
                )
              }

              return (
                <Text className="whitespace-pre-wrap line-clamp-3" size="sm" variant="light" title={processedMetafieldValue}>
                  {processedMetafieldValue}
                </Text>
              )
            },
          })),
        })
      })
    }

    return (
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    )
  }

  return (
    <Page>
      <Header className="flex items-center justify-between">
        <Text className="font-semibold" size="h5" variant="heading">
          Procurements
        </Text>
        <Button onClick={() => openModal(<ProcurementCreateUpdateModal />)} size="sm">
          + New procurement
        </Button>
      </Header>
      <PageContent>
        <Tabs<ProcurementListTab>
          className="border-b border-gray-300 w-full mb-8"
          tab={tab}
          tabs={PROCUREMENT_LIST_TABS}
          onChange={({ id }) => handleTabChange(id)}
          variant="underline"
        />
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            <div className="flex justify-end">
              <Button size="xs" variant="secondary" className="mb-4" isDisabled={isLoading} isLoading={isDownloading} onClick={handleDownloadCsv}>
                <Icon icon="download-01" className="mr-2" /> Download CSV
              </Button>
            </div>
            {renderContent()}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
