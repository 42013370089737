import { includes, map } from 'lodash'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { useGetDocument } from '@cotiss/document'
import { useGetLoggedInUser } from '@cotiss/user'
import {
  PerformanceScorecardMetricCycleDocumentUploadModal,
  performanceService,
  usePerformanceScorecard,
  usePerformanceScorecardMetric,
  usePerformanceScorecardMetricCycle,
  usePerformanceScorecardMetricCycleDocument,
  usePerformanceScorecardUser,
} from '@cotiss/performance'
import {
  Button,
  ConfirmModal,
  datetimeService,
  Drawer,
  Hr,
  Icon,
  Input,
  Label,
  sentryService,
  Table,
  TableColumn,
  TableHeader,
  TableRowCta,
  Text,
  TextArea,
  useAsyncEffect,
  useCallout,
  useToast,
} from '@cotiss/common'

type PerformanceScorecardMetricCycleOwnerScoreFormData = {
  score: string
  comment: string
}

export const PerformanceScorecardMetricCycleOwnerScoreDrawer = memo(() => {
  const { openToast } = useToast()
  const { user } = useGetLoggedInUser()
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const { openModal, closeNarrowDrawer } = useCallout()
  const { performanceScorecard } = usePerformanceScorecard()
  const [pagination, setPagination] = useState<GqlPagination>()
  const [documentIdToDownload, setDocumentIdToDownload] = useState('')
  const { performanceScorecardMetric } = usePerformanceScorecardMetric()
  const { performanceScorecardUserInSession } = usePerformanceScorecardUser()
  const { document: documentToDownload } = useGetDocument(documentIdToDownload)
  const { performanceScorecardMetricCycle, mutateUpdatePerformanceScorecardMetricCycle } = usePerformanceScorecardMetricCycle()
  const {
    performanceScorecardMetricCycleDocuments,
    queryPerformanceScorecardMetricCycleDocumentList,
    mutateDeletePerformanceScorecardMetricCycleDocument,
  } = usePerformanceScorecardMetricCycleDocument()
  const [formData, setFormData] = useState<PerformanceScorecardMetricCycleOwnerScoreFormData>({
    score: performanceScorecardMetricCycle?.score?.toString() || '',
    comment: performanceScorecardMetricCycle?.comment || '',
  })

  useAsyncEffect(async () => {
    if (!performanceScorecard || !performanceScorecardMetricCycle) {
      return
    }

    try {
      const { pagination } = await queryPerformanceScorecardMetricCycleDocumentList({
        filter: { performanceScorecardId: performanceScorecard.id, performanceScorecardMetricCycleId: performanceScorecardMetricCycle.id },
        pagination: { page: currentPage, pageSize: 20 },
      })

      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
    }

    setIsLoading(false)
  }, [currentPage])

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentIdToDownload('')
    }
  }, [documentToDownload])

  const { columns, isComplete, canCreateDocuments } = useMemo(() => {
    const canCreateDocuments = !performanceScorecard?.isArchived && includes(performanceScorecardUserInSession?.roles, 'owner')

    const columns: TableColumn[] = [
      {
        heading: 'Resource name',
        rows: map(performanceScorecardMetricCycleDocuments, ({ id: performanceScorecardMetricCycleDocumentId, fileName, documentId }) => ({
          content: () => (
            <Button
              className="text-sm underline cursor-pointer truncate inline-block align-middle"
              onClick={() => setDocumentIdToDownload(documentId)}
              state="raw"
            >
              <Text>{fileName}</Text>
            </Button>
          ),
          cta: canCreateDocuments && (
            <TableRowCta
              actions={[
                {
                  label: 'Delete',
                  onClick: () =>
                    openModal(
                      <ConfirmModal
                        heading="Delete document"
                        description="Are you sure you want to delete this document?"
                        onSubmit={() => mutateDeletePerformanceScorecardMetricCycleDocument({ performanceScorecardMetricCycleDocumentId })}
                      />
                    ),
                },
              ]}
            />
          ),
        })),
      },
      {
        heading: 'Date created',
        rows: map(performanceScorecardMetricCycleDocuments, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
    ]

    return { columns, isComplete: performanceScorecardMetricCycle?.status === 'complete', canCreateDocuments }
  }, [performanceScorecardMetricCycle, performanceScorecardMetricCycleDocuments, performanceScorecardUserInSession])

  const handleSubmit = async () => {
    if (!performanceScorecardMetricCycle || !user) {
      return
    }

    setIsSaving(true)

    try {
      await mutateUpdatePerformanceScorecardMetricCycle({
        ...formData,
        performanceScorecardMetricCycleId: performanceScorecardMetricCycle.id,
        score: Number(formData.score),
        submittedAt: new Date(),
        submittedByUserId: user._id,
        status: 'complete',
      })
      closeNarrowDrawer()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      Update score
    </Text>
  )

  const renderFooter = () => {
    if (!performanceScorecardMetricCycle) {
      return
    }

    if (isComplete) {
      return (
        <div className="flex items-center">
          <Button
            className="mr-2"
            onClick={() =>
              openModal(
                <ConfirmModal
                  heading="Edit cycle"
                  description="Are you sure you want to edit this cycle?"
                  onSubmit={() =>
                    mutateUpdatePerformanceScorecardMetricCycle({
                      performanceScorecardMetricCycleId: performanceScorecardMetricCycle.id,
                      status: 'active',
                    })
                  }
                />
              )
            }
            state="translucent"
            variant="secondary"
          >
            <Icon className="mr-1" icon="edit-05" />
            Edit score
          </Button>

          <Icon className="mr-1" icon="check-circle" variant="success" />
          <Text className="font-semibold mr-1">Marked as complete</Text>
        </div>
      )
    }

    return (
      <Button type="submit" variant="secondary" isLoading={isSaving}>
        Mark as complete
      </Button>
    )
  }

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit} isNarrow>
      <Label>Final value</Label>
      {!isComplete && (
        <>
          <div className="relative w-full">
            <Input
              className="pr-12"
              value={formData.score}
              onChange={({ target }) => setFormData({ ...formData, score: target.value })}
              placeholder="Enter score..."
              type="number"
              isDisabled={isSaving}
              isRequired
            />
            {performanceScorecardMetric?.performanceMetric.metricUnit === 'percentage' && (
              <Text className="absolute right-4 top-1/2 -translate-y-1/2">%</Text>
            )}
            {performanceScorecardMetric?.performanceMetric.metricUnit === 'currency' && (
              <Text className="absolute right-4 top-1/2 -translate-y-1/2">{performanceScorecardMetric?.performanceMetric.metricUnitType}</Text>
            )}
          </div>
          <div className="mt-1">
            <Text className="mr-1" size="sm" variant="light" isInline>
              Target:
            </Text>
            {performanceScorecardMetric && (
              <Text size="sm" isInline>
                {performanceService.formatPerformanceValue({
                  value: performanceScorecardMetric.target,
                  performanceMetric: performanceScorecardMetric.performanceMetric,
                })}
              </Text>
            )}
          </div>
        </>
      )}
      {isComplete && performanceScorecardMetric && (
        <Text>
          {performanceScorecardMetricCycle?.score
            ? performanceService.formatPerformanceValue({
                value: performanceScorecardMetricCycle.score,
                performanceMetric: performanceScorecardMetric.performanceMetric,
              })
            : '--'}
        </Text>
      )}
      <Hr className="my-4" />
      <Label>Final comment</Label>
      {!isComplete && (
        <TextArea
          value={formData.comment}
          onChange={({ target }) => setFormData({ ...formData, comment: target.value })}
          placeholder="Enter any notes outlining the justification for the metric value..."
          rows={5}
          isDisabled={isSaving}
          isRequired
        />
      )}
      {isComplete && <Text className="whitespace-pre-wrap">{performanceScorecardMetricCycle?.comment || '--'}</Text>}
      {(isLoading || (isComplete && Boolean(performanceScorecardMetricCycleDocuments.length)) || !isComplete) && (
        <>
          <TableHeader className="flex justify-between items-center mt-8">
            <Text className="font-medium" variant="heading" size="lg">
              Resources{canCreateDocuments && !isComplete && ' (optional)'}
            </Text>
            {canCreateDocuments && !isComplete && (
              <Button
                onClick={() =>
                  performanceScorecardMetricCycle &&
                  openModal(
                    <PerformanceScorecardMetricCycleDocumentUploadModal performanceScorecardMetricCycleId={performanceScorecardMetricCycle.id} />
                  )
                }
                state="translucent"
                variant="secondary-dark"
                size="xs"
              >
                + Add resource
              </Button>
            )}
          </TableHeader>
          <Table
            columns={columns}
            pagination={pagination}
            onPageChange={setCurrentPage}
            emptyCta={
              canCreateDocuments &&
              !isComplete && (
                <Button
                  size="sm"
                  state="text"
                  variant="secondary"
                  onClick={() =>
                    performanceScorecardMetricCycle &&
                    openModal(
                      <PerformanceScorecardMetricCycleDocumentUploadModal performanceScorecardMetricCycleId={performanceScorecardMetricCycle.id} />
                    )
                  }
                  isDisabled={isLoading}
                >
                  + Add resource
                </Button>
              )
            }
            isLoading={isLoading}
          />
        </>
      )}
    </Drawer>
  )
})
