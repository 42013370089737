import { useMemo, useRef } from 'react'
import { isEqual } from 'lodash'

export function useDeepMemo<T>(value: () => T, deps: React.DependencyList): T {
  const ref = useRef<T | null>(null)

  return useMemo(() => {
    const newValue = value()

    if (isEqual(ref.current, newValue)) {
      return ref.current as T
    }

    ref.current = newValue
    return newValue
  }, deps)
}
