import React, { forwardRef } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { Input, InputProps } from './input.component'

type Props = Omit<InputProps, 'onChange' | 'value' | 'type' | 'defaultValue'> &
  Omit<NumericFormatProps, 'disabled'> & {
    isDisabled?: boolean
  }

export const NumberInput = forwardRef<HTMLInputElement, Props>(({ isDisabled, ...rest }, ref) => {
  return <NumericFormat {...rest} disabled={isDisabled} customInput={Input} getInputRef={ref} />
})
