import React, { memo } from 'react'
import { Icon, IconType, Text, Tooltip_DEPRECATED, datetimeService } from '@cotiss/common'
import { ContractPriceDurationModel, contractService, EXPIRY_WARNING_PERIOD_MONTHS } from '@cotiss/contract'
import { isBefore, addMonths } from 'date-fns'
import classNames from 'classnames'

type Props = {
  priceDurations: ContractPriceDurationModel[]
  className?: string
}

export const ContractNextExpirationDate = memo(({ className, priceDurations }: Props) => {
  const nextExpiry: string = contractService.getNextExpirationDate(priceDurations)
  const nextExpiryDate = new Date(nextExpiry)
  const warningLimitDate = addMonths(new Date(), EXPIRY_WARNING_PERIOD_MONTHS)
  const showWarning = datetimeService.isDateBetween(nextExpiryDate, { dateFrom: new Date(), dateTo: warningLimitDate })
  const expired = isBefore(nextExpiryDate, new Date())

  let indicator: {
    icon: IconType
    tooltip: string
    width: number
  } = {
    icon: 'flag',
    tooltip: 'Next expiry is within 3 months',
    width: 190,
  }

  if (expired) {
    indicator = {
      icon: 'clock',
      tooltip: 'Next expiry in the past. This contract has expired',
      width: 160,
    }
  }

  const classes = classNames(className, 'flex justify-between items-end gap-3')

  return (
    <Text size="sm" variant={showWarning ? 'danger' : 'light'} className={classes}>
      {nextExpiry ? datetimeService.format(nextExpiry, 'do MMM yyyy') : '--'}
      {(showWarning || expired) && (
        <Tooltip_DEPRECATED tooltip={indicator.tooltip} width={indicator.width} className="z-10">
          <Icon icon={indicator.icon}></Icon>
        </Tooltip_DEPRECATED>
      )}
    </Text>
  )
})
