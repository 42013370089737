import { findIndex } from 'lodash'
import { AnimatePresence } from 'framer-motion'
import React, { memo, useEffect, useState } from 'react'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import {
  EvaluationEventPanelUpdatePanelStep,
  EvaluationEventPanelUpdateScoringStep,
  EvaluationEventPanelUpdateSummaryStep,
  useEvaluationEventAnalytics,
} from '@cotiss/evaluation-event'
import {
  Breadcrumb,
  Button,
  Header,
  PageContent,
  Section,
  StepModel,
  Steps,
  Text,
  TransitionContainer,
  TransitionType,
  useCallout,
} from '@cotiss/common'

type Step = 'panel' | 'scoring' | 'summary'
const STEPS: StepModel<Step>[] = [
  {
    id: 'panel',
    label: 'Panel',
  },
  {
    id: 'scoring',
    label: 'Scoring access',
  },
  {
    id: 'summary',
    label: 'Summary',
  },
]

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
}

export const EvaluationEventPanelUpdateFullModal = memo(({ evaluationEnvelope }: Props) => {
  const { closeFullModal } = useCallout()
  const [stepIndex, setStepIndex] = useState(0)
  const { track } = useEvaluationEventAnalytics()
  const [transition, setTransition] = useState<TransitionType>('right')

  useEffect(() => {
    track('evaluation_event_panel_update_view')
  }, [])

  const handleStepChange = (newStep: StepModel<Step>) => {
    const oldStepIndex = findIndex(STEPS, ({ id }) => id === STEPS[stepIndex].id)
    const newStepIndex = findIndex(STEPS, ({ id }) => id === newStep.id)

    setTransition(newStepIndex > oldStepIndex ? 'right' : 'left')
    setTimeout(() => setStepIndex(newStepIndex), 0)
  }

  const handleNext = () => {
    const newStepIndex = findIndex(STEPS, ({ id }) => id === STEPS[stepIndex].id) + 1

    STEPS[newStepIndex] && handleStepChange(STEPS[newStepIndex])
  }

  const handleBack = () => {
    const newStepIndex = findIndex(STEPS, ({ id }) => id === STEPS[stepIndex].id) - 1

    newStepIndex >= 0 && handleStepChange(STEPS[newStepIndex])
  }

  const props = {
    evaluationEnvelope,
    onBack: handleBack,
    onNext: handleNext,
  }

  // This will only ever happen momentarily, usually just before the modal closes
  if (!STEPS[stepIndex]?.id) {
    return null
  }

  return (
    <div className="bg-primary-50 w-full h-full min-h-screen overflow-y-scroll">
      <Header>
        <Section isCentered>
          <div className="flex items-center justify-between">
            <div className="w-full">
              <Breadcrumb breadcrumbs={[{ label: 'Evaluate', onClick: closeFullModal }, { label: 'Update panel' }]} />
              <div className="flex items-center">
                <Text className="font-semibold mr-2" size="h4" variant="heading">
                  Update panel
                </Text>
              </div>
            </div>
            <Button className="ml-2" onClick={closeFullModal} state="ghost" variant="link" size="sm">
              Save and exit
            </Button>
          </div>
        </Section>
      </Header>
      <PageContent>
        <Section isCentered>
          <Steps<Step> className="mb-8" steps={STEPS} step={STEPS[stepIndex]} onChange={handleStepChange} />
          <AnimatePresence initial={false} mode="wait">
            <TransitionContainer key={STEPS[stepIndex].id} transition={transition}>
              {STEPS[stepIndex].id === 'panel' && <EvaluationEventPanelUpdatePanelStep {...props} />}
              {STEPS[stepIndex].id === 'scoring' && <EvaluationEventPanelUpdateScoringStep {...props} />}
              {STEPS[stepIndex].id === 'summary' && <EvaluationEventPanelUpdateSummaryStep {...props} />}
            </TransitionContainer>
          </AnimatePresence>
        </Section>
      </PageContent>
    </div>
  )
})
