import React, { memo } from 'react'
import { Drawer, Text } from '@cotiss/common'
import { DocumentModel, DocumentViewer } from '@cotiss/document'

type Props = {
  title: string
  document: DocumentModel
}

export const ContractSupportingDocumentDrawer = memo(({ title, document }: Props) => {
  const renderHeader = () => (
    <Text className="font-semibold" size="h5" variant="heading">
      {title}
    </Text>
  )

  return (
    <Drawer header={renderHeader()}>
      <DocumentViewer className="w-full aspect-[1/1.414]" document={document} isDownloadable />
    </Drawer>
  )
})
