import React, { memo } from 'react'
import { PreferredSupplierOrganisationAccountUser } from '@cotiss/preferred-supplier'
import { Td_DEPRECATED, Tr_DEPRECATED, Text, Checkbox } from '@cotiss/common'

type Props = {
  accountUser: PreferredSupplierOrganisationAccountUser
  onSelect?: (accountUserId: string) => void
  isSelected?: boolean
  isDisabled?: boolean
}

export const PreferredSupplierAccountUserListItem = memo(({ accountUser, onSelect, isSelected, isDisabled }: Props) => (
  <Tr_DEPRECATED variant="white">
    <Td_DEPRECATED>
      <label className="flex items-center">
        {onSelect && <Checkbox className="shrink-0 mr-1" onChange={() => onSelect(accountUser._id)} isChecked={isSelected} isDisabled={isDisabled} />}
        <Text>{accountUser.email}</Text>
      </label>
    </Td_DEPRECATED>
    <Td_DEPRECATED />
    <Td_DEPRECATED />
    <Td_DEPRECATED className="text-right">
      <Text className="text-right" size="sm" variant="light">
        Already invited
      </Text>
    </Td_DEPRECATED>
  </Tr_DEPRECATED>
))
