import classNames from 'classnames'
import { flatMap, map } from 'lodash'
import React, { memo, useMemo } from 'react'
import {
  TableColumn,
  Text,
  ScrollableTable,
  datetimeService,
  Icon,
  TableRowCta,
  routerService,
  Button,
  TableHeader,
  ConfirmModal,
  useCallout,
} from '@cotiss/common'
import {
  ContractDocumentShellAttachmentModel,
  ContractDocumentShellModel,
  ContractSupportingDocumentDrawer,
  ContractWizardStepSupportDocumentsUploadModal,
  useMutateContractShell,
} from '@cotiss/contract'

type Props = {
  contractShellId: string
  contractId?: string
  documentShells: ContractDocumentShellModel[]
  isEditable?: boolean
  className?: string
  previewAction?: 'drawer' | 'download'
}

type HandleDeleteParam = {
  documentShellId: string
  attachmentId: string
}

export const ContractSupportingDocumentsTable = memo((props: Props) => {
  const { contractShellId, contractId, documentShells, isEditable, className = '', previewAction = 'download' } = props
  const classes = classNames(className)
  const { openModal, openDrawer } = useCallout()
  const { removeContractDocumentShellAttachment, removeContractDocumentShell } = useMutateContractShell()

  const supportingDocumentData: { documentShellName: string; attachment: ContractDocumentShellAttachmentModel }[] = useMemo(() => {
    return flatMap(documentShells, ({ name, attachments }) =>
      map(attachments, (attachment) => {
        return {
          documentShellName: name,
          attachment,
        }
      })
    )
  }, [documentShells])

  const handleDelete = async ({ documentShellId, attachmentId }: HandleDeleteParam) => {
    if (!contractId) {
      return
    }

    // TODO: remove deleting attachment separately in future when BE supports this
    // For now the shell must be empty to delete the shell, so need to delete the attachment first
    await removeContractDocumentShellAttachment({ contractShellId, contractId, documentShellId, attachmentId })
    await removeContractDocumentShell(contractShellId, contractId, documentShellId)
  }

  const fixedColumns: TableColumn[] = [
    {
      heading: 'Name',
      rows: map(supportingDocumentData, ({ documentShellName, attachment }) => ({
        content: () => (
          <Text variant="light" size="sm" className="truncate">
            {documentShellName || '--'}
          </Text>
        ),
        cta:
          isEditable && contractId ? (
            <TableRowCta
              actions={[
                {
                  label: 'View',
                  onClick: () =>
                    previewAction === 'download'
                      ? window.open(routerService.getHref('/document/view/:id', { id: attachment.document._id }), '_blank')
                      : openDrawer(<ContractSupportingDocumentDrawer title={documentShellName} document={attachment.document} />),
                },
                {
                  label: 'Delete',
                  onClick: () =>
                    openModal(
                      <ConfirmModal
                        onSubmit={() => handleDelete({ documentShellId: attachment.documentShell, attachmentId: attachment._id })}
                        heading="Confirm delete"
                        description="Confirm you'd like to delete this supporting document?"
                      />
                    ),
                },
              ]}
            />
          ) : (
            <Button
              onClick={() =>
                previewAction === 'download'
                  ? window.open(routerService.getHref('/document/view/:id', { id: attachment.document._id }), '_blank')
                  : openDrawer(<ContractSupportingDocumentDrawer title={documentShellName} document={attachment.document} />)
              }
              state="outline"
              variant="secondary"
              size="xs"
            >
              View <Icon className="ml-1" icon="arrow-right" />
            </Button>
          ),
      })),
    },
  ]

  const columns: TableColumn[] = [
    {
      heading: 'File name',
      rows: map(supportingDocumentData, ({ attachment }) => ({
        content: () => (
          <div className="flex items-center truncate">
            {/* TODO: make this the correct icon */}
            <div className="shrink-0 mr-2 bg-secondary-100 rounded h-6 w-6 flex items-center justify-center">
              <Icon icon="document-check" className="text-secondary-500 " />
            </div>
            <Text variant="light" size="sm" className="truncate">
              {attachment.document.fileName || '--'}
            </Text>
          </div>
        ),
      })),
    },
    {
      heading: 'Date',
      rows: map(supportingDocumentData, ({ attachment }) => ({
        content: () => <Text>{datetimeService.format(attachment.createdAt, 'do MMM yyyy')}</Text>,
      })),
    },
  ]

  return (
    <div className={classes}>
      <TableHeader>
        <div className="flex justify-between">
          <Text className="font-semibold">Supporting documents</Text>
          {isEditable && (
            <Button
              size="xs"
              variant="secondary"
              onClick={() => openModal(<ContractWizardStepSupportDocumentsUploadModal contractShellId={contractShellId} />)}
            >
              <Icon icon="plus-01" />
              Add document
            </Button>
          )}
        </div>
      </TableHeader>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} />
    </div>
  )
})
