import React, { memo, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { compact, find, forEach, map, sortBy, uniq } from 'lodash'
import { GqlEvaluationSubmissionFieldsFragment, GqlPagination } from '@gql'
import {
  EvaluationEventEvaluationStatusBadge,
  evaluationEventService,
  useEvaluation,
  useEvaluationCriteria,
  useEvaluationEnvelope,
  useEvaluationEvent,
  useEvaluationSubmission,
} from '@cotiss/evaluation-event'
import {
  Skeleton,
  routerService,
  sentryService,
  Text,
  useAsyncEffect,
  TableHeader,
  ScrollableTableColumn,
  ScrollableTable,
  TableRowCta,
  Icon,
  useToast,
  utilService,
  ErrorPanel,
} from '@cotiss/common'

type Props = {
  isInitialised?: boolean
}

export const EvaluationEventEvaluateEnvelopeUserEvaluationTab = memo(({ isInitialised }: Props) => {
  const { push } = useHistory()
  const { openToast } = useToast()
  const [isError, setIsError] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { evaluationEvent } = useEvaluationEvent()
  const [currentPage, setCurrentPage] = useState(1)
  const { evaluationCriteria } = useEvaluationCriteria()
  const { evaluationEnvelope } = useEvaluationEnvelope()
  const [pagination, setPagination] = useState<GqlPagination>()
  const { evaluationSubmissions, queryEvaluationSubmissionList } = useEvaluationSubmission()
  const { evaluations, evaluationOverviews, queryEvaluationList, queryEvaluationOverviewList, mutateCreateEvaluation } = useEvaluation()
  const { evaluationEventId, evaluationEnvelopeId, evaluationUserId } = useParams<{
    evaluationEventId: string
    evaluationEnvelopeId: string
    evaluationUserId: string
  }>()

  useAsyncEffect(async () => {
    if (!isInitialised) {
      return
    }

    try {
      setIsLoading(true)
      const { evaluationSubmissions, pagination } = await queryEvaluationSubmissionList({
        filter: { evaluationEventId },
        pagination: { page: currentPage, pageSize: 20 },
      })

      const evaluationSubmissionIds = compact(uniq(map(evaluationSubmissions, 'id')))
      await Promise.all([
        queryEvaluationList({ filter: { evaluationEventId, evaluationEnvelopeId, evaluationUserId, evaluationSubmissionIds } }),
        queryEvaluationOverviewList({ filter: { evaluationEventId, evaluationEnvelopeId, evaluationUserId, evaluationSubmissionIds } }),
      ])
      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [isInitialised, currentPage])

  const handleCreateEvaluation = async (evaluationSubmission: GqlEvaluationSubmissionFieldsFragment) => {
    try {
      setIsSaving(true)
      const evaluation = await mutateCreateEvaluation({
        evaluationEventId,
        evaluationUserId,
        evaluationEnvelopeId,
        evaluationSubmissionId: evaluationSubmission.id,
      })

      push(
        routerService.getHref('/evaluation-event/view/:evaluationEventId/evaluate/view/:evaluationId', {
          evaluationEventId,
          evaluationId: evaluation.id,
        })
      )
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const { fixedColumns, columns } = useMemo(() => {
    const weightById = evaluationEventService.getWeightById({ items: evaluationCriteria })
    const totalWeight = evaluationEventService.getTotalWeight({ weightById })
    const weightPercentageById = evaluationEventService.getWeightedPercentageById({ weightById, totalWeight })

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Criteria',
        colSpan: 3,
        rows: [
          {
            colSpan: 3,
            hasHover: false,
            content: () => (
              <Text className="uppercase" size="xs" variant="light">
                Weight
              </Text>
            ),
          },
          {
            variant: 'primary',
            hasHover: false,
            content: () => (
              <Text className="uppercase" size="xs" variant="light">
                Submission
              </Text>
            ),
          },
          ...map(evaluationSubmissions, (evaluationSubmission) => {
            const evaluation = find(evaluations, { evaluationSubmissionId: evaluationSubmission.id })

            return {
              content: () => (
                <Text className="font-medium truncate" title={evaluationSubmission.organisation?.name || evaluationSubmission.name}>
                  {evaluationSubmission.name || evaluationSubmission.organisation?.name}
                </Text>
              ),
              cta: (
                <TableRowCta
                  cta={{
                    isDisabled: isSaving,
                    label: (
                      <>
                        View <Icon className="ml-2" icon="arrow-right" />
                      </>
                    ),
                    // If an evaluation exists, we can navigate to the evaluation view page. Otherwise, we need to create a new evaluation.
                    href: evaluation
                      ? routerService.getHref('/evaluation-event/view/:evaluationEventId/evaluate/view/:evaluationId', {
                          evaluationEventId,
                          evaluationId: evaluation.id,
                        })
                      : undefined,
                    onClick: !evaluation ? async () => handleCreateEvaluation(evaluationSubmission) : undefined,
                  }}
                />
              ),
            }
          }),
        ],
      },
      {
        rows: [
          // Empty cells to get colSpan of 3 above working as expected.
          {},
          {
            variant: 'primary',
            hasHover: false,
            content: () => (
              <Text className="uppercase" size="xs" variant="light">
                Status
              </Text>
            ),
          },
          ...map(evaluationSubmissions, ({ id }) => ({
            content: () => <EvaluationEventEvaluationStatusBadge status={find(evaluations, { evaluationSubmissionId: id })?.status} />,
          })),
        ],
      },
      {
        rows: [
          // Empty cells to get colSpan of 3 above working as expected.
          {},
          {
            variant: 'primary',
            hasHover: false,
            content: () => (
              <Text className="uppercase" size="xs" variant="light">
                Total
              </Text>
            ),
          },
          ...map(evaluationSubmissions, ({ id: evaluationSubmissionId }) => {
            const evaluation = find(evaluations, { evaluationSubmissionId })
            const evaluationOverview = evaluation && find(evaluationOverviews, { evaluationId: evaluation.id })

            return {
              content: () => (
                <Text>
                  {evaluationOverview?.totalEnvelopeWeightedPercentageScore
                    ? utilService.formatAsPercentage(Number(evaluationOverview?.totalEnvelopeWeightedPercentageScore) * 100)
                    : '--'}
                </Text>
              ),
            }
          }),
        ],
      },
    ]

    const columns: ScrollableTableColumn[] = []

    forEach(sortBy(evaluationCriteria, 'index'), ({ id: evaluationCriteriaId, content, index }, i) => {
      columns.push({
        heading: (
          <>
            {isLoading && <Skeleton className="h-2 w-full" />}
            {!isLoading && (
              <Text className="normal-case">
                {index}. {content}
              </Text>
            )}
          </>
        ),
        rows: [
          {
            hasHover: false,
            content: () => (
              <Text size="sm" variant="light">
                {utilService.formatAsPercentage(Number(weightPercentageById[evaluationCriteriaId]) * 100)}
              </Text>
            ),
          },
          !i
            ? {
                variant: 'primary',
                colSpan: evaluationCriteria.length,
                hasHover: false,
                content: () => (
                  <Text className="uppercase" size="xs" variant="light">
                    Weighted score
                  </Text>
                ),
              }
            : {},
          ...map(evaluationSubmissions, ({ id: evaluationSubmissionId }) => {
            const evaluation = find(evaluations, { evaluationSubmissionId })
            const evaluationOverview = evaluation && find(evaluationOverviews, { evaluationId: evaluation.id })
            const criteriaBreakdown = evaluationOverview && find(evaluationOverview.criteriaBreakdown, { evaluationCriteriaId })

            return {
              content: () => (
                <Text>
                  {criteriaBreakdown?.isScored || criteriaBreakdown?.completedScoredSubCriteriaCount
                    ? utilService.formatAsPercentage(criteriaBreakdown.rawScoreSummary.weightedPercentageScore * 100)
                    : '--'}
                </Text>
              ),
            }
          }),
        ],
      })
    })

    return { fixedColumns, columns }
  }, [evaluationEvent, evaluationEnvelope, evaluationCriteria, evaluations, evaluationOverviews, isLoading])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  if (!isLoading && !evaluationEvent) {
    return <ErrorPanel />
  }

  return (
    <>
      <TableHeader variant="white">
        {isLoading && <Skeleton className="h-4 w-32" variant="gray" />}
        {!isLoading && evaluationEnvelope && (
          <>
            <Text variant="light" size="sm">
              Envelope {evaluationEnvelope.order}.
            </Text>
            <Text className="font-medium" variant="heading" size="h7">
              {evaluationEnvelope.name}
            </Text>
          </>
        )}
      </TableHeader>
      <ScrollableTable
        fixedColumns={fixedColumns}
        fixedColumnsWidth={600}
        columns={columns}
        pagination={pagination}
        onPageChange={setCurrentPage}
        isLoading={isLoading}
      />
    </>
  )
})
