import { AnimatePresence } from 'framer-motion'
import React, { FormEvent, useState, memo, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useMutateUser } from '@cotiss/user'
import CotissLogoLightSvg from '@assets/svg/cotiss-logo-light.svg'
import { SignUpUserStepAccount, SignUpUserStepConfirmation, authResource, authService, useAuth } from '@cotiss/auth'
import {
  Button,
  Form,
  routerService,
  Page,
  Text,
  useTransition,
  sentryService,
  localStorageService,
  utilService,
  useAsyncEffect,
} from '@cotiss/common'

export type SignUpUserFormData = {
  account: string
  firstname: string
  surname: string
  email: string
  password: string
  passwordConfirm: string
  phone: string
  regions: string[]
  termsAgreement: boolean
}

export const SignUpUserPage = memo(() => {
  const { push } = useHistory()
  const { search } = useLocation()
  const { login, logout } = useAuth()
  const { createUser } = useMutateUser()
  const [formError, setFormError] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isValidatingCode, setIsValidatingCode] = useState(true)
  const { invitationCode } = useParams<{ invitationCode: string }>()
  const { step, transition, isTransitioning, onTransition } = useTransition()
  const searchParams = utilService.getUrlSearchParams({ search, params: ['invitationCode'] })
  const [formData, setFormData] = useState<SignUpUserFormData>({
    account: '',
    firstname: '',
    surname: '',
    email: '',
    password: '',
    passwordConfirm: '',
    phone: '',
    regions: [],
    termsAgreement: false,
  })

  useEffect(() => {
    logout()
  }, [])

  useAsyncEffect(async () => {
    try {
      const codeToVerify = invitationCode || searchParams.invitationCode

      const data = codeToVerify && (await authResource.verifyUserInvitation(invitationCode))

      if (!data) {
        setFormError('')
        return
      }

      const { email, account } = data
      setFormData((prev) => ({ ...prev, email, account }))
    } catch (error: any) {
      if (error instanceof Error) {
        switch (error.message) {
          case 'Pending user invitation code expired':
            setFormError('Your invitation has expired, please contact your account administrator to re-send the link.')
            break
          case 'Pending user invitation code invalid':
            setFormError('Invalid invitation code. Please contact your account administrator to re-send the link.')
            break
          case 'Pending user invitation code has already been used':
            setFormError('An account has already been created using this invitation. Please navigate to the login page to sign in.')
            break
          case 'Email has already been used':
            setFormError('This email has already been used. Please navigate to the login page to sign in.')
            break
          default:
            setFormError('Invalid invitation code. Please contact your account administrator to re-send the link.')
        }
      }
    }

    setIsValidatingCode(false)
  }, [invitationCode])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (step < 2) {
      onTransition({ step: step + 1 })
      return
    }

    setFormError('')

    if (formData.password !== formData.passwordConfirm) {
      setFormError('Passwords do not match')
      return
    }

    if (!authService.isPasswordValid(formData.password)) {
      setFormError('Password must be at least 8 characters long and contain at least one letter and one number')
      return
    }

    try {
      setIsSaving(true)
      await createUser({
        accountId: formData.account,
        firstname: formData.firstname,
        surname: formData.surname,
        email: formData.email,
        password: formData.password,
        phone: formData.phone,
        regions: formData.regions,
      })

      await login(formData.email, formData.password)
      push(localStorageService.getItem('auth-redirect-route') || routerService.getHref('/'))
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setFormError(error.message)
      setIsSaving(false)
    }
  }

  const stepProps = {
    formData,
    transition,
    setFormData,
    isDisabled: isTransitioning || isSaving || isValidatingCode,
    isError: Boolean(formError),
  }

  return (
    <Page>
      <div className="flex items-start justify-between h-full">
        <div className="relative bg-white w-5/12 h-full p-4 pr-10">
          <div className="bg-secondary-500 rounded-lg h-full w-full p-10">
            <CotissLogoLightSvg className="h-8" />
            <h1 className="text-white text-5xl leading-tight mt-48">
              Better decisions.
              <br />
              Simplified process.
            </h1>
          </div>
        </div>
        <div className="bg-white w-7/12 h-full p-12">
          <Button
            className={step === 1 ? 'invisible' : ''}
            onClick={() => onTransition({ step: step - 1, transition: 'left' })}
            state="ghost"
            variant="link"
          >
            Back
          </Button>

          <div className="flex items-center justify-center w-full h-full">
            <div className="w-full max-w-[350px]">
              <Text className="font-semibold mt-2" size="h3">
                Sign up
              </Text>
              <Text className="mt-2" variant="light">
                Sign up to join account on Cotiss.
              </Text>
              {Boolean(formError) && (
                <Text className="text-sm my-4" variant="danger">
                  {formError}
                </Text>
              )}
              <Form className="mt-8" onSubmit={handleSubmit} isHubSpot>
                <AnimatePresence mode="wait" initial={false}>
                  {step === 1 && <SignUpUserStepAccount {...stepProps} />}
                  {step === 2 && <SignUpUserStepConfirmation {...stepProps} />}
                </AnimatePresence>
                <div className="bg-secondary-100 text-center rounded py-2 px-4 mt-4">
                  <Text className="mr-1" isInline>
                    Already have an account?
                  </Text>
                  <Button
                    className="inline-block text-sm"
                    state="text"
                    variant="link"
                    href={routerService.getHref('/login')}
                    isDisabled={isSaving}
                    isLink
                  >
                    Log in
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
})
