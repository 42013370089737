import { findIndex, includes } from 'lodash'
import { AnimatePresence } from 'framer-motion'
import React, { memo, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useGetLoggedInUser } from '@cotiss/user'
import { EvaluationEventCreateModal, EvaluationEventList } from '@cotiss/evaluation-event'
import {
  Button,
  Header,
  Page,
  PageContent,
  TabModel,
  Tabs,
  Text,
  TransitionContainer,
  routerService,
  useAnalytics,
  useCallout,
  useTransition,
} from '@cotiss/common'

export type EvaluationEventListTab = 'archived' | 'active'

const EVALUATION_EVENT_LIST_TABS: TabModel<EvaluationEventListTab>[] = [
  { id: 'active', label: 'Active' },
  { id: 'archived', label: 'Archived' },
]

export const EvaluationEventListPage = memo(() => {
  const { openModal } = useCallout()
  const { track } = useAnalytics()
  const { user } = useGetLoggedInUser()
  const { tab } = useParams<{ tab?: EvaluationEventListTab }>()
  const { step, transition, onTransition } = useTransition({
    initialStep: tab ? findIndex(EVALUATION_EVENT_LIST_TABS, ({ id }) => id === tab) + 1 : 0,
  })
  const { replace, push } = useHistory()

  useEffect(() => {
    track('evaluation_event_list_view')

    if (!tab) {
      replace(routerService.getHref('/evaluation-event/list/:tab?', { tab: 'active' }))
    }
  }, [])

  const canCreateEvaluationEvent = useMemo(() => includes(user?.permissions, 'PROCUREMENT_MANAGER'), [user])

  const handleTabChange = (newTab: EvaluationEventListTab) => {
    const newStep = findIndex(EVALUATION_EVENT_LIST_TABS, ({ id }) => id === newTab) + 1
    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    push(routerService.getHref('/evaluation-event/list/:tab?', { tab: newTab }))
  }

  return (
    <Page>
      <Header className="flex items-center justify-between">
        <Text className="font-semibold" size="h5" variant="heading">
          Evaluate
        </Text>
        {canCreateEvaluationEvent && (
          <Button onClick={() => openModal(<EvaluationEventCreateModal />)} size="sm">
            + New evaluation
          </Button>
        )}
      </Header>
      <PageContent>
        <Tabs<EvaluationEventListTab>
          className="border-b border-gray-300 w-full mb-8"
          tab={tab}
          tabs={EVALUATION_EVENT_LIST_TABS}
          onChange={({ id }) => handleTabChange(id)}
          variant="underline"
        />
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {step === 1 && <EvaluationEventList />}
            {step === 2 && <EvaluationEventList isArchived />}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
