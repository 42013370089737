import React, { memo, useState } from 'react'
import { DocumentList } from '@cotiss/document'
import { filter, includes, map } from 'lodash'
import { Button, Hr, Icon, Text, sentryService, useCallout, useToast } from '@cotiss/common'
import { ShortListDueDiligenceUploadModal, ShortListPopulatedModel, ShortListStatus, useMutateShortList } from '@cotiss/short-list'

type Props = {
  shortList: ShortListPopulatedModel
  onBack: () => void
  isEditable?: boolean
}

export const ShortListDueDiligenceView = memo(({ shortList, onBack, isEditable }: Props) => {
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { updateShortList } = useMutateShortList()

  const handleUpdate = async (status: ShortListStatus) => {
    try {
      setIsSaving(true)
      await updateShortList(shortList._id, { status, rank: status !== 'passed' ? 0 : undefined })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleBulkRemoveDueDiligence = async (documentIdsToRemove: string[]) => {
    try {
      setIsSaving(true)
      const dueDilligence = map(
        filter(shortList.dueDilligence, ({ _id }) => !includes(documentIdsToRemove, _id)),
        ({ _id }) => _id
      )
      await updateShortList(shortList._id, { dueDilligence })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <div className="bg-white rounded-lg shadow-lg w-full px-8 py-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center mr-4">
          <Button onClick={onBack} state="translucent" variant="secondary" size="sm">
            <Icon icon="arrow-left" size={20} />
            Back
          </Button>
          <Text className="font-semibold ml-2">{shortList.tenderResponse.procurementResponse.supplier.name}</Text>
        </div>
        {isEditable && (
          <div className="flex items-center">
            {shortList.status === 'pending' && (
              <>
                <Button onClick={() => handleUpdate('failed')} state="translucent" variant="secondary" size="sm" isDisabled={isSaving}>
                  <Icon className="mr-1" icon="x-close" size={20} />
                  Fail
                </Button>
                <Button className="ml-2" onClick={() => handleUpdate('passed')} variant="secondary" size="sm" isDisabled={isSaving}>
                  <Icon className="mr-1" icon="check" />
                  Pass
                </Button>
              </>
            )}
            {shortList.status !== 'pending' && (
              <div className="flex items-center justify-between bg-gray-100 rounded-lg w-full p-2 ml-2">
                <div className="flex items-center">
                  <Icon className="mr-2" icon="check-circle" variant="success" size={20} />
                  <Text>Due diligence marked as {shortList.status}.</Text>
                </div>
                <Button className="ml-4" onClick={() => handleUpdate('pending')} state="text" variant="link" isDisabled={isSaving}>
                  Edit
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <Hr className="my-4" />
      <DocumentList
        title="Due diligence documents"
        documents={shortList.dueDilligence}
        onUpload={isEditable ? () => openModal(<ShortListDueDiligenceUploadModal shortList={shortList} />) : undefined}
        onBulkRemove={isEditable ? handleBulkRemoveDueDiligence : undefined}
        isDisabled={isSaving}
      />
    </div>
  )
})
