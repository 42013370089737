import React, { memo, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useGetProcurementResponse } from '@cotiss/procurement-response'
import { Button, COLOUR, Icon, Page, Section, Spinner, Text, routerService } from '@cotiss/common'

/**
 * Note: this page has been consolidated into the ProcurementViewPage.
 * This page just exists as a redirect in case of bookmarks.
 */
export const ProcurementResponseViewPage = memo(() => {
  const { push } = useHistory()
  const { procurementResponseId, tab, nestedTab } = useParams<{
    procurementResponseId: string
    tab?: string
    nestedTab?: string
  }>()

  const { procurementResponse, isError } = useGetProcurementResponse(procurementResponseId)

  useEffect(() => {
    if (procurementResponse) {
      push(
        routerService.getHref('/procurement/view/:procurementId/:tab?/:nestedTab?', {
          procurementId: procurementResponse.procurement._id,
          tab,
          nestedTab,
        })
      )
    }
  }, [procurementResponse])

  const renderContent = () => {
    if (isError) {
      return (
        <Section className="text-center" isCentered>
          <Icon className="mx-auto mb-2" icon="x-close" variant="danger" />
          <Text className="text-center font-semibold" size="h5">
            Failed to load procurement response
          </Text>

          <Text className="text-center mt-2" variant="light">
            Please visit
            <Button className="mx-1" state="text" variant="link" size="sm" onClick={() => push(routerService.getHref('/procurement-response/list'))}>
              Responses
            </Button>
            to view your procurement responses
          </Text>
        </Section>
      )
    }

    return (
      <Section isCentered>
        <Spinner className="mx-auto mb-2" colour={COLOUR.primary['500']} />
        <Text className="text-center font-semibold" size="h5">
          Loading procurement response
        </Text>

        <Text className="text-center mt-2" variant="light">
          Please do not refresh the page
        </Text>
      </Section>
    )
  }

  return <Page className="flex items-center">{renderContent()}</Page>
})
