import { FilterAllOperations_DEPRECATED } from '@cotiss/common'

export const FILTER_OPERATION_NAMES_DEPRECATED: Record<FilterAllOperations_DEPRECATED, string> = {
  IS_ANY_OF: 'is any of',
  IS_NONE_OF: 'is none of',
  CONTAINS_EXACTLY: 'contains exactly',
  DOES_NOT_CONTAIN_EXACTLY: 'does not contain exactly',
  IS_TRUE: 'is true',
  IS_FALSE: 'is false',
  IS_BEFORE: 'is before',
  IS_AFTER: 'is after',
  IS_GREATER_THAN: 'is greater than',
  IS_LESS_THAN: 'is less than',
  IS_EQUAL_TO: 'is equal to',
}
