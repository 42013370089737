import React, { memo, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useGetProject } from '@cotiss/project'
import { TenderCreateModal } from '@cotiss/tender'
import { useGetProcurement } from '@cotiss/procurement'
import { Header, Text, Button, routerService, TabModel, Tabs, useCallout, Skeleton, Breadcrumb, BreadcrumbModel, useAnalytics } from '@cotiss/common'
import { UserAvatarGroup } from '@cotiss/user'
import { filter, map } from 'lodash'
import { accessControlService, useListAccessControl } from '@cotiss/access-control'

export type ProcurementOverviewTab = 'background' | 'all-requests' | 'all-activity' | 'conflict-of-interest' | 'settings'
export const PROJECT_OVERVIEW_TABS: TabModel<ProcurementOverviewTab>[] = [
  { id: 'background', label: 'Background' },
  { id: 'all-requests', label: 'Requests' },
  { id: 'all-activity', label: 'Activity' },
  { id: 'conflict-of-interest', label: 'Conflict of interest' },
  { id: 'settings', label: 'Settings', isHidden: true },
]

export const ProcurementOverviewHeader = memo(() => {
  const { push } = useHistory()
  const { openModal } = useCallout()
  const { procurementId, tab } = useParams<{ procurementId: string; tab?: ProcurementOverviewTab }>()
  const { accessControls, isLoading: isAccessControlsLoading } = useListAccessControl({ resourceId: procurementId })
  const { procurement, isLoading: isProcurementLoading } = useGetProcurement(procurementId)
  const { project, isLoading: isProjectLoading } = useGetProject(procurement?.project)
  const { track } = useAnalytics()
  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Procurements',
      href: routerService.getHref('/project/list/:tab?'),
    },
    {
      label: 'Overview',
    },
  ]

  const { users } = useMemo(() => {
    const owners = filter(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'procurement:owner'))
    const collaborators = filter(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'procurement:collaborator'))

    return { users: [...owners, ...collaborators] }
  }, [accessControls, isAccessControlsLoading])

  const handleTabChange = ({ id: tab }: TabModel<ProcurementOverviewTab>) => {
    if (tab === 'all-activity') {
      track('procurement_event_dashboard_activity_tab_view')
    }
    if (tab === 'all-requests') {
      track('procurement_event_dashboard_requests_tab_view')
    }
    if (tab === 'background') {
      track('procurement_event_dashboard_background_tab_view')
    }
    if (tab === 'conflict-of-interest') {
      track('procurement_event_dashboard_conflict_of_interest_tab_view')
    }
    if (tab === 'settings') {
      track('procurement_event_dashboard_settings_view')
    }
    push(routerService.getHref('/procurement/overview/:procurementId/:tab?/:nestedTab?', { procurementId, tab }))
  }

  return (
    <>
      <Header className="z-30">
        <div className="flex items-center justify-between">
          <div className="flex items-start">
            <div>
              <Breadcrumb breadcrumbs={breadcrumbs} />
              {isProcurementLoading && <Skeleton className="h-4 w-32" variant="gray" />}
              {!isProcurementLoading && (
                <div className="flex items-center">
                  <Text className="font-medium" size="h7" variant="heading">
                    {procurement?.title}
                  </Text>
                </div>
              )}
              {isProjectLoading && <Skeleton className="h-3.5 w-24 mt-1" variant="gray" />}
              {!isProjectLoading && (
                <Text className="mt-0.5" size="sm" variant="light">
                  {project?.title}
                </Text>
              )}
            </div>
          </div>
          <Button onClick={() => openModal(<TenderCreateModal procurementId={procurementId} />)} size="sm">
            + Create request
          </Button>
        </div>
      </Header>
      <Header className="border-t border-gray-100 flex justify-between items-center">
        <Tabs<ProcurementOverviewTab> tab={tab} tabs={PROJECT_OVERVIEW_TABS} onChange={handleTabChange} variant="square" />
        {/* TODO: Can we move this to a "Team" tab? */}
        <UserAvatarGroup
          users={map(users, ({ user }) => user)}
          onClick={() => push(routerService.getHref('/procurement/overview/:procurementId/:tab?/:nestedTab?', { procurementId, tab: 'settings' }))}
        />
      </Header>
    </>
  )
})
