import { find, map } from 'lodash'
import { AnimatePresence } from 'framer-motion'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { userService } from '@cotiss/user'
import { GqlEvaluationFieldsFragment } from '@gql'
import { useEvaluation, useEvaluationUser } from '@cotiss/evaluation-event'
import { Button, Drawer, Icon, Table, TableColumn, TableHeader, TableRowCta, Text, TransitionContainer, useTransition } from '@cotiss/common'

export const EvaluationEventModerationEvaluatorCommentsDrawer = memo(() => {
  const { evaluations } = useEvaluation()
  const { evaluationUsers } = useEvaluationUser()
  const { step, transition, onTransition } = useTransition()
  const [activeEvaluation, setActiveEvaluation] = useState<GqlEvaluationFieldsFragment | null>(null)

  useEffect(() => {
    activeEvaluation ? onTransition({ step: 2, transition: 'right' }) : onTransition({ step: 1, transition: 'left' })
  }, [activeEvaluation])

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: 'Criteria',
        rows: map(evaluations, (evaluation) => {
          const evaluationUser = find(evaluationUsers, { id: evaluation.evaluationUserId })

          return {
            content: () => <Text>{evaluationUser ? userService.getFullName(evaluationUser.user) : '--'}</Text>,
            cta: (
              <TableRowCta
                cta={{
                  label: 'View',
                  onClick: () => setActiveEvaluation(evaluation),
                }}
              />
            ),
          }
        }),
      },
    ]

    return { columns }
  }, [evaluations, evaluationUsers])

  const activeEvaluationUser = useMemo(() => {
    return activeEvaluation && find(evaluationUsers, { id: activeEvaluation.evaluationUserId })
  }, [activeEvaluation])

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      View evaluator notes
    </Text>
  )

  return (
    <Drawer header={renderHeader()} isNarrow>
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && (
            <>
              <TableHeader>
                <Text>Select evaluator</Text>
              </TableHeader>
              <Table columns={columns} />
            </>
          )}
          {step === 2 && activeEvaluation && activeEvaluationUser && (
            <>
              <Button className="mb-4" onClick={() => setActiveEvaluation(null)} state="translucent" variant="secondary" size="xs" isLink>
                <Icon className="mr-1" icon="arrow-left" />
                Back
              </Button>
              <Text className="font-medium" size="h4">
                {userService.getFullName(activeEvaluationUser.user)}
              </Text>
              <Text className="whitespace-pre-wrap mt-4">{activeEvaluation.comment || '--'}</Text>
            </>
          )}
        </TransitionContainer>
      </AnimatePresence>
    </Drawer>
  )
})
