import folderDocumentsPng from '@assets/images/folder-documents.png'
import { ApprovalApprover, useListApproval } from '@cotiss/approval'
import { Button, FourOhThreePage, Text, sentryService, useToast } from '@cotiss/common'
import { useGetTender, useMutateTender } from '@cotiss/tender'
import { tenderFlowService } from '@cotiss/tender-flow'
import { every, filter, map } from 'lodash'
import React, { memo, useState } from 'react'

type Props = {
  tenderId: string
}

export const TenderFlowSelectionApprovalGate = memo(({ tenderId }: Props) => {
  const { openToast } = useToast()
  const { progressTender } = useMutateTender()
  const [isSaving, setIsSaving] = useState(false)
  const { approvals } = useListApproval({ tenderId })
  const filteredApprovals = filter(approvals, { approvalType: 'recommendation' })
  const { tender } = useGetTender(tenderId)
  const isOldEvaluationsEnabled = tenderFlowService.isOldEvaluationsEnabled(tender)

  const handleMoveToSelection = async () => {
    try {
      setIsSaving(true)
      await progressTender(tenderId)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  if (!isOldEvaluationsEnabled) {
    return <FourOhThreePage />
  }

  return (
    <div className="flex items-start justify-center max-w-[600px] mx-auto mt-36">
      <img className="mr-8" src={folderDocumentsPng} alt="Folder documents" />
      <div>
        <Text className="font-semibold" size="h5">
          Great! Your{' '}
          <Text className="font-semibold" variant="link" size="h5" isInline>
            Recommendation Report
          </Text>{' '}
          has been sent for approval
        </Text>
        <Text className="mt-4" variant="light">
          Report stage will be unlocked when your live tender is approved
        </Text>
        {map(filteredApprovals, (approval) => (
          <ApprovalApprover key={approval._id} className="mt-6" approval={approval} />
        ))}

        <Button className="mt-8" onClick={handleMoveToSelection} isDisabled={!every(filteredApprovals, { status: 'approved' })} isLoading={isSaving}>
          Go to report
        </Button>
      </div>
    </div>
  )
})
