import React from 'react'
import { DatetimeInput, datetimeService, Input, MultiSelect, NumberInput } from '@cotiss/common'
import { FilterValueType } from '@cotiss/common/models/filter.model'

interface Props {
  valueType?: FilterValueType
  valueOptions?: { value: string; label: string }[]
  setValue: (value?: string | string[]) => void
  value: any
  isDisabled?: boolean
  placeholder?: string
}

export const AdvancedFiltersValueField = ({ valueType, valueOptions, setValue, value, isDisabled, placeholder }: Props) => {
  if (valueType === 'array') {
    return (
      <MultiSelect
        renderInPortal
        values={value ?? []}
        options={valueOptions ?? []}
        onChange={(newValue) => setValue(newValue)}
        isDisabled={isDisabled}
        placeholder={placeholder}
      />
    )
  }

  if (valueType === 'string') {
    return <Input value={value ?? ''} onChange={({ target }) => setValue(target.value)} placeholder={placeholder} isDisabled={isDisabled} />
  }

  if (valueType === 'date') {
    return (
      <DatetimeInput
        isTimeVisible={false}
        value={value ? datetimeService.parse(value) : null}
        placeholder={placeholder}
        onChange={(newValue) => setValue(newValue?.toISOString())}
        isDisabled={isDisabled}
      />
    )
  }

  if (valueType === 'number') {
    return <NumberInput value={value ?? ''} onChange={({ target }) => setValue(target.value)} placeholder={placeholder} isDisabled={isDisabled} />
  }

  return <Input value="" placeholder={placeholder} isDisabled />
}
