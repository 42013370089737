import { Button, Form, Hr, sentryService, useToast, Text } from '@cotiss/common'
import React, { FormEvent, memo, useState } from 'react'
import { useMutateConflictOfInterest, ConflictOfInterestModel, ConflictOfInterestTerms } from '@cotiss/conflict-of-interest'

type Props = {
  conflictOfInterest: ConflictOfInterestModel
  onSuccess: () => void
}

export const ConflictOfInterestViewAgreementTab = memo(({ conflictOfInterest, onSuccess }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const { openToast } = useToast()
  const { updateConflictOfInterest } = useMutateConflictOfInterest()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateConflictOfInterest(conflictOfInterest._id, { step: 'declaration-form' })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsSaving(false)
    onSuccess()
  }

  return (
    <Form className="bg-white rounded-lg shadow-lg w-full px-8 py-6 max-w-5xl" onSubmit={handleSubmit}>
      <Text variant="heading" size="h4">
        {conflictOfInterest.procurement.title}
      </Text>
      <Hr className="my-4" />
      <ConflictOfInterestTerms conflictOfInterestId={conflictOfInterest._id} isReviewing />
      <Hr className="my-4" />
      <div className="flex items-center justify-end">
        <Button type="submit" variant="secondary" isLoading={isSaving}>
          Continue
        </Button>
      </div>
    </Form>
  )
})
