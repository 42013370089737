import classNames from 'classnames'

import { TextVariant } from '@cotiss/common'
import { MouseEvent as ReactMouseEvent, SVGProps, createElement, memo } from 'react'

import AlertCircleDeprecated from '@assets/svg/icon-alert-circle-deprecated.svg'
import AlertCircle from '@assets/svg/icon-alert-circle.svg'
import AnnotationAlert from '@assets/svg/icon-annotation-alert.svg'
import ArrowDown from '@assets/svg/icon-arrow-down.svg'
import ArrowLeft from '@assets/svg/icon-arrow-left.svg'
import ArrowRight from '@assets/svg/icon-arrow-right.svg'
import ArrowUp from '@assets/svg/icon-arrow-up.svg'
import Attachment from '@assets/svg/icon-attachment.svg'
import BarChart10 from '@assets/svg/icon-bar-chart-10.svg'
import Bell01 from '@assets/svg/icon-bell-01.svg'
import BulletList from '@assets/svg/icon-bullet-list.svg'
import Calendar from '@assets/svg/icon-calendar.svg'
import CheckCircle from '@assets/svg/icon-check-circle.svg'
import CheckSquareBroken from '@assets/svg/icon-check-square-broken.svg'
import CheckVerified01 from '@assets/svg/icon-check-verified-01.svg'
import CheckVerified02 from '@assets/svg/icon-check-verified-02.svg'
import Check from '@assets/svg/icon-check.svg'
import ChevronDown from '@assets/svg/icon-chevron-down.svg'
import ChevronLeft from '@assets/svg/icon-chevron-left.svg'
import ChevronRight from '@assets/svg/icon-chevron-right.svg'
import ChevronSelectorVertical from '@assets/svg/icon-chevron-selector-vertical.svg'
import ChevronUp from '@assets/svg/icon-chevron-up.svg'
import CircleAdd from '@assets/svg/icon-circle-add.svg'
import ClipboardCheck from '@assets/svg/icon-clipboard-check.svg'
import Clock from '@assets/svg/icon-clock.svg'
import Comment from '@assets/svg/icon-comment.svg'
import Compass03 from '@assets/svg/icon-compass-03.svg'
import Copy07 from '@assets/svg/icon-copy-07.svg'
import CreditCard02 from '@assets/svg/icon-credit-card-02.svg'
import CurrencyDollarCircle from '@assets/svg/icon-currency-dollar-circle.svg'
import Data from '@assets/svg/icon-data.svg'
import DocumentCheck from '@assets/svg/icon-document-check.svg'
import Dollar from '@assets/svg/icon-dollar.svg'
import Dots from '@assets/svg/icon-dots.svg'
import Download01 from '@assets/svg/icon-download-01.svg'
import Download from '@assets/svg/icon-download.svg'
import Edit05 from '@assets/svg/icon-edit-05.svg'
import Edit from '@assets/svg/icon-edit.svg'
import ExternalLink from '@assets/svg/icon-external-link.svg'
import File04 from '@assets/svg/icon-file-04.svg'
import File06 from '@assets/svg/icon-file-06.svg'
import FileAttachment05 from '@assets/svg/icon-file-attachment-05.svg'
import FileCheck02 from '@assets/svg/icon-file-check-02.svg'
import FilePlus02 from '@assets/svg/icon-file-plus-02.svg'
import FileSearch02 from '@assets/svg/icon-file-search-02.svg'
import FileText from '@assets/svg/icon-file-text.svg'
import FilterFunnel01 from '@assets/svg/icon-filter-funnel-01.svg'
import Flag from '@assets/svg/icon-flag.svg'
import Folder from '@assets/svg/icon-folder.svg'
import Globe01 from '@assets/svg/icon-globe-01.svg'
import Globe from '@assets/svg/icon-globe.svg'
import Hidden from '@assets/svg/icon-hidden.svg'
import HomeLine from '@assets/svg/icon-home-line.svg'
import InfoCircle from '@assets/svg/icon-info-circle.svg'
import Invisible from '@assets/svg/icon-invisible.svg'
import Key01 from '@assets/svg/icon-key-01.svg'
import Lightbulb05 from '@assets/svg/icon-lightbulb-05.svg'
import Lightning01 from '@assets/svg/icon-lightning-01.svg'
import Like from '@assets/svg/icon-like.svg'
import LineChartUp01 from '@assets/svg/icon-line-chart-up-01.svg'
import Link03 from '@assets/svg/icon-link-03.svg'
import LinkExternal01 from '@assets/svg/icon-link-external-01.svg'
import Linkedin from '@assets/svg/icon-linkedin.svg'
import List from '@assets/svg/icon-list.svg'
import Lock01 from '@assets/svg/icon-lock-01.svg'
import Lock from '@assets/svg/icon-lock.svg'
import LogIn03 from '@assets/svg/icon-log-in-03.svg'
import Logout from '@assets/svg/icon-logout.svg'
import Mail02 from '@assets/svg/icon-mail-02.svg'
import Mail from '@assets/svg/icon-mail.svg'
import Menu01 from '@assets/svg/icon-menu-01.svg'
import MessageQuestionCircle from '@assets/svg/icon-message-question-circle.svg'
import Messages from '@assets/svg/icon-messages.svg'
import Pencil02 from '@assets/svg/icon-pencil-02.svg'
import PersonVideo from '@assets/svg/icon-person-video.svg'
import Phone from '@assets/svg/icon-phone.svg'
import PushPin from '@assets/svg/icon-push-pin.svg'
import Refresh02 from '@assets/svg/icon-refresh-ccw-02.svg'
import RefreshCw03 from '@assets/svg/icon-refresh-cw-03.svg'
import Reply from '@assets/svg/icon-reply.svg'
import Search from '@assets/svg/icon-search.svg'
import SearchSm from '@assets/svg/icon-search-sm.svg'
import Send01 from '@assets/svg/icon-send-01.svg'
import Settings01 from '@assets/svg/icon-settings-01.svg'
import Settings from '@assets/svg/icon-settings.svg'
import ShieldTick from '@assets/svg/icon-shield-tick.svg'
import ShoppingCart02 from '@assets/svg/icon-shopping-cart-02.svg'
import Star01 from '@assets/svg/icon-star-01.svg'
import Star06 from '@assets/svg/icon-star-06.svg'
import Stars02 from '@assets/svg/icon-stars-02.svg'
import Table from '@assets/svg/icon-table.svg'
import Trash from '@assets/svg/icon-trash.svg'
import Trophy01 from '@assets/svg/icon-trophy-01.svg'
import Upload01 from '@assets/svg/icon-upload-01.svg'
import Upload from '@assets/svg/icon-upload.svg'
import User01 from '@assets/svg/icon-user-01.svg'
import UserCircle from '@assets/svg/icon-user-circle.svg'
import UserPlus01 from '@assets/svg/icon-user-plus-01.svg'
import Users01 from '@assets/svg/icon-users-01.svg'
import UsersEdit from '@assets/svg/icon-users-edit.svg'
import Visible from '@assets/svg/icon-visible.svg'
import XSquare from '@assets/svg/icon-x-square.svg'
import XClose from '@assets/svg/icon-x-close.svg'
import UserCheck01 from '@assets/svg/icon-user-check-01.svg'
import Contract from '@assets/svg/icon-contract.svg'
import CheckCircle01 from '@assets/svg/icon-check-circle-01.svg'
import Clock01 from '@assets/svg/icon-clock-01.svg'
import ClockCheck from '@assets/svg/icon-clock-check.svg'
import Plus01 from '@assets/svg/icon-plus-01.svg'
import Trash01 from '@assets/svg/icon-trash-01.svg'

export type IconType = keyof typeof ICON_CONFIG_MAP

// In order to ensure our icons position and size correctly, make sure that you export the icon container from Figma. These should always be 12x12px.
// There are some cases where the icon container is larger. In that case, exporting the icon directly should give you the correct 12x12px dimensions.
// If you still can't export the correct dimensions from Figma, talk to the design team to give you a 12x12px version.
const DEFAULT_ICON_CONFIG = { defaultSize: 12, type: 'stroke' }
const ICON_CONFIG_MAP = {
  // Current icons (sorted alphabetically)
  ['alert-circle']: { component: AlertCircle },
  ['annotation-alert']: { component: AnnotationAlert },
  ['bar-chart-10']: { defaultSize: 14, component: BarChart10 },
  ['bell-01']: { component: Bell01 },
  ['calendar']: { component: Calendar },
  ['check-circle-01']: { component: CheckCircle01 },
  ['check-square-broken']: { component: CheckSquareBroken },
  ['check-verified-01']: { component: CheckVerified01 },
  ['check-verified-02']: { component: CheckVerified02 },
  ['chevron-down']: { component: ChevronDown },
  ['chevron-left']: { component: ChevronLeft },
  ['chevron-right']: { component: ChevronRight },
  ['chevron-selector-vertical']: { component: ChevronSelectorVertical },
  ['chevron-up']: { component: ChevronUp },
  ['clipboard-check']: { component: ClipboardCheck },
  ['clock-01']: { component: Clock01 },
  ['clock-check']: { component: ClockCheck },
  ['comment']: { component: Comment },
  ['compass-03']: { component: Compass03 },
  ['contract']: { component: Contract },
  ['copy-07']: { component: Copy07 },
  ['credit-card-02']: { component: CreditCard02 },
  ['currency-dollar-circle']: { component: CurrencyDollarCircle },
  ['data']: { component: Data },
  ['download-01']: { component: Download01 },
  ['edit-05']: { component: Edit05 },
  ['file-04']: { component: File04 },
  ['file-06']: { component: File06 },
  ['file-attachment-05']: { component: FileAttachment05 },
  ['file-check-02']: { component: FileCheck02 },
  ['file-plus-02']: { component: FilePlus02 },
  ['file-search-02']: { component: FileSearch02 },
  ['filter-funnel-01']: { component: FilterFunnel01 },
  ['flag']: { component: Flag },
  ['folder']: { component: Folder },
  ['globe-01']: { component: Globe01 },
  ['home-line']: { component: HomeLine },
  ['info-circle']: { component: InfoCircle },
  ['invisible']: { component: Invisible },
  ['key-01']: { component: Key01 },
  ['lightbulb-05']: { component: Lightbulb05 },
  ['lightning-01']: { component: Lightning01 },
  ['like']: { component: Like },
  ['line-chart-up-01']: { component: LineChartUp01 },
  ['link-03']: { component: Link03 },
  ['link-external-01']: { component: LinkExternal01 },
  ['list']: { component: List },
  ['lock-01']: { component: Lock01 },
  ['log-in-03']: { component: LogIn03 },
  ['mail-02']: { component: Mail02 },
  ['menu-01']: { component: Menu01 },
  ['message-question-circle']: { component: MessageQuestionCircle },
  ['pencil-02']: { component: Pencil02 },
  ['plus-01']: { component: Plus01 },
  ['refresh-02']: { component: Refresh02 },
  ['refresh-cw-03']: { component: RefreshCw03 },
  ['reply']: { component: Reply },
  ['search-sm']: { component: SearchSm },
  ['send-01']: { component: Send01 },
  ['settings-01']: { component: Settings01 },
  ['shield-tick']: { component: ShieldTick },
  ['shopping-cart-02']: { component: ShoppingCart02 },
  ['star-01']: { component: Star01 },
  ['star-06']: { component: Star06 },
  ['stars-02']: { component: Stars02 },
  ['table']: { type: 'fill', component: Table },
  ['trash-01']: { component: Trash01 },
  ['trophy-01']: { component: Trophy01 },
  ['upload-01']: { component: Upload01 },
  ['user-01']: { component: User01 },
  ['user-check-01']: { component: UserCheck01 },
  ['user-circle']: { component: UserCircle },
  ['user-plus-01']: { component: UserPlus01 },
  ['users-01']: { component: Users01 },
  ['users-edit']: { component: UsersEdit },
  ['x-close']: { component: XClose },
  ['x-square']: { component: XSquare },

  // Deprecated icons (sorted alphabetically)
  ['alert-circle-deprecated']: { defaultSize: 24, component: AlertCircleDeprecated },
  ['arrow-down']: { defaultSize: 24, component: ArrowDown },
  ['arrow-left']: { defaultSize: 24, component: ArrowLeft },
  ['arrow-right']: { defaultSize: 24, component: ArrowRight },
  ['arrow-up']: { defaultSize: 24, component: ArrowUp },
  ['attachment']: { defaultSize: 24, component: Attachment },
  ['bullet-list']: { defaultSize: 24, component: BulletList },
  ['check']: { component: Check },
  ['check-circle']: { defaultSize: 20, component: CheckCircle },
  ['circle-add']: { defaultSize: 20, component: CircleAdd },
  ['clock']: { defaultSize: 16, component: Clock },
  ['document-check']: { defaultSize: 16, component: DocumentCheck },
  ['dollar']: { defaultSize: 24, component: Dollar },
  ['dots']: { defaultSize: 24, component: Dots },
  ['download']: { defaultSize: 24, component: Download },
  ['edit']: { defaultSize: 24, component: Edit },
  ['external-link']: { defaultSize: 24, component: ExternalLink },
  ['file-text']: { defaultSize: 24, component: FileText },
  ['globe']: { defaultSize: 24, component: Globe },
  ['hidden']: { defaultSize: 24, component: Hidden },
  ['linkedin']: { defaultSize: 24, component: Linkedin },
  ['lock']: { defaultSize: 24, component: Lock },
  ['logout']: { defaultSize: 16, component: Logout },
  ['mail']: { defaultSize: 24, component: Mail },
  ['messages']: { defaultSize: 16, component: Messages },
  ['person-video']: { defaultSize: 16, component: PersonVideo },
  ['phone']: { defaultSize: 24, component: Phone },
  ['push-pin']: { defaultSize: 24, component: PushPin },
  ['search']: { defaultSize: 24, component: Search },
  ['settings']: { defaultSize: 24, component: Settings },
  ['trash']: { defaultSize: 24, component: Trash },
  ['upload']: { component: Upload },
  ['visible']: { defaultSize: 24, component: Visible },
} as const

type Props = {
  icon: IconType
  className?: string
  variant?: TextVariant
  size?: number
  onClick?: (event: ReactMouseEvent<SVGSVGElement, MouseEvent>) => void
}

export const Icon = memo(({ className, icon, variant = 'none', size = 16, onClick }: Props) => {
  const iconConfig = ICON_CONFIG_MAP[icon]

  if (!iconConfig?.component) {
    return null
  }

  const { type, defaultSize, component } = { ...DEFAULT_ICON_CONFIG, ...iconConfig }
  const classes = classNames(className, 'inline-block shrink-0', {
    'text-gray-700': variant === 'dark',
    'text-light': variant === 'light',
    'text-primary-500': variant === 'heading' || variant === 'primary',
    'text-secondary-500': variant === 'link' || variant === 'secondary',
    'text-blue-500': variant === 'info',
    'text-alert-error-400': variant === 'danger',
    'text-orange-700': variant === 'warning',
    'text-green-500': variant === 'success',
    'text-white': variant === 'white',
    'stroke-current': type === 'stroke',
    'fill-current': type === 'fill',
  })
  const attrs: SVGProps<SVGSVGElement> = { className: classes, width: size, height: size, onClick }

  if (attrs.width !== defaultSize || attrs.height !== defaultSize) {
    attrs.viewBox = `0 0 ${defaultSize} ${defaultSize}`
  }

  return createElement(component, attrs) || null
})
