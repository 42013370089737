// NOTE: This is part of a DEPRECATED and UNUSED flow.

import React, { memo } from 'react'
import { Button, Card, CardHeader, Icon, Text } from '@cotiss/common'
import { TenderResponseReportModel } from '@cotiss/tender-response'
import { TenderCriterionReportModel } from '@cotiss/tender-criteria'
import {
  TenderQuestionReportEvaluationComments,
  TenderQuestionReportModel,
  TenderQuestionReportQuestion,
  TenderQuestionReportScore,
} from '@cotiss/tender-question'
import { TenderPopulatedModel } from '@cotiss/tender/tender.models'

type Props = {
  tender: TenderPopulatedModel
  tenderResponseReport: TenderResponseReportModel
  tenderCriterionReport: TenderCriterionReportModel
  tenderQuestionReport: TenderQuestionReportModel
  onPreviousQuestion?: () => void
  onNextQuestion?: () => void
  isEditable?: boolean
}

export const TenderQuestionReport = memo((props: Props) => {
  const { tender, tenderResponseReport, tenderCriterionReport, tenderQuestionReport, onPreviousQuestion, onNextQuestion, isEditable } = props

  return (
    <Card>
      <CardHeader>
        <Text className="font-semibold" size="h7">
          {tenderResponseReport.procurementResponse.supplier.name}
        </Text>

        <div className="flex items-center shrink-0 ml-4">
          {onPreviousQuestion && (
            <Button className="mr-2" onClick={onPreviousQuestion} state="ghost" variant="secondary">
              <Icon icon="arrow-left" size={20} />
              Previous question
            </Button>
          )}
          {onNextQuestion && (
            <Button onClick={onNextQuestion} state="ghost" variant="secondary" size="sm">
              Next question
              <Icon icon="arrow-right" size={20} />
            </Button>
          )}
        </div>
      </CardHeader>
      <div className="flex items-start">
        <div className="w-1/2 mr-8">
          <TenderQuestionReportQuestion
            tenderQuestionId={tenderQuestionReport._id}
            tenderResponseId={tenderResponseReport._id}
            tenderCriterionReport={tenderCriterionReport}
            tenderQuestionReport={tenderQuestionReport}
          />
          <TenderQuestionReportEvaluationComments tenderQuestionId={tenderQuestionReport._id} tenderResponseId={tenderResponseReport._id} />
        </div>
        <div className="w-1/2">
          <TenderQuestionReportScore
            tender={tender}
            tenderResponseId={tenderResponseReport._id}
            tenderQuestionId={tenderQuestionReport._id}
            isEditable={isEditable}
          />
        </div>
      </div>
    </Card>
  )
})
