import classNames from 'classnames'
import React, { memo } from 'react'
import { Button, Hr, Text } from '@cotiss/common'

type Props = {
  className?: string
  heading: string
  onToggleEdit?: (isEditing: boolean) => void
  hasSeparator?: boolean
  isEditable?: boolean
  isEditing?: boolean
  isSaving?: boolean
  isLoading?: boolean
}

export const CardFormHeader = memo(({ className, heading, onToggleEdit, hasSeparator = true, isEditable, isEditing, isSaving, isLoading }: Props) => {
  const classes = classNames(className, 'flex items-center justify-between')

  const handleToggleEdit = (isEditing: boolean) => {
    onToggleEdit && onToggleEdit(isEditing)
  }

  return (
    <>
      <div className={classes}>
        <Text className="font-semibold" variant="heading" size="h4">
          {heading}
        </Text>
        {isEditable && !isEditing && (
          <Button onClick={() => handleToggleEdit(true)} state="translucent" variant="secondary" size="sm" isDisabled={isLoading}>
            Edit
          </Button>
        )}
        {isEditing && (
          <div>
            <Button className="mr-2" onClick={() => handleToggleEdit(false)} state="ghost" variant="link" size="sm" isDisabled={isSaving}>
              Cancel
            </Button>
            <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
              Save
            </Button>
          </div>
        )}
      </div>
      {hasSeparator && <Hr className="my-4" />}
    </>
  )
})
