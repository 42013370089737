import {
  Filter_DEPRECATED,
  FilterFieldOptions_DEPRECATED,
  MetricUnit,
  OcdsCurrencyCode,
  PaginatedModel,
  paginationService,
  useMutate,
} from '@cotiss/common'
import { Filter, FilterFieldOptions } from '@cotiss/common/models/filter.model'
import {
  ContractDocumentShellSignatureType,
  ContractShellFilterPopulatedModel,
  ContractShellModel,
  ContractVariation,
  ContractReminderModel,
  AttachmentSignatureType,
  ContractShellType,
  ContractDocumentShellType,
} from '@cotiss/contract'
import { isArray, map } from 'lodash'

type CreateContractShellBody = {
  procurementId?: string
  title: string
  description?: string
}

type UpdateContractShellBody = {
  title?: string
  description?: string
  type?: ContractShellType
}

type AssociateContractShellBody = {
  parentContractShells?: string[]
  childrenContractShells?: string[]
  siblingsContractShells?: string[]
}

type UpdateContractMetadataBody = {
  owners?: string[]
  regions?: string[]
  categories?: string[]
  externalReference?: string
  currency?: OcdsCurrencyCode
  suppliers?: string[]
  contractingEntity?: string
}

type CreateContractDocumentShellBody = {
  name: string
  description?: string
  signatureType?: ContractDocumentShellSignatureType
  type: ContractDocumentShellType
}

type UpdateContractDocumentShellBody = {
  name?: string
  description?: string
  signatureType?: ContractDocumentShellSignatureType
}

export type UpdateContractPriceDurationBulkBody = {
  items: {
    index: number
    startDate?: Date
    endDate?: Date
    value: number
    variation: number
    exercised: number
    description?: string
    length?: number
  }[]
}

export type UpdateContractMilestoneBulkBody = {
  items: {
    index: number
    value: number
    variation: number
    exercised: number
    description?: string
    length?: number
  }[]
}

export type UpdateScheduledRatesBulkBody = {
  items: {
    title: string
    index: number
    role?: string
    tag: string
    amount: number
    unit: MetricUnit
    description: string
  }[]
}

export type UpdateContractApproverSequenceBody = {
  approvalTemplateGroupId: string
  approvalTemplateGroupName: string
  approvers: {
    userId: string
    role: string
    order: number | null
  }[]
  comment?: string
}

type ActionContractApproverBody = {
  comment?: string
}

type ActionContractApproverParam = {
  contractShellId: string
  contractId: string
  approvalId: string
  approverId: string
  action: ContractApproverAction
  body: ActionContractApproverBody
}

type CeaseContractBody = {
  cessationReason: string
}

type CeaseContractParam = {
  contractShellId: string
  contractId: string
  body: CeaseContractBody
}

export type ContractApproverAction = 'approved' | 'request-changes'

type ActionContractApprovalParam = {
  contractShellId: string
  contractId: string
  approvalId: string
  action: ContractApprovalAction
}

export type ContractApprovalAction = 'submit' | 'revoke'

type UploadContractSignedDocumentParam = {
  contractShellId: string
  contractId: string
  documentShellId: string
  attachmentId: string
  documentId: string
}

type CreateContractVariationBody = {
  types: ContractVariation[]
}

type RemoveDocumentShellAttachmentParam = {
  contractShellId: string
  contractId: string
  documentShellId: string
  attachmentId: string
}

export type CreateContractReminderBody = Pick<ContractReminderModel, 'title' | 'date' | 'description'> & { users: string[] }

export type UpdateContractReminderBody = Partial<Pick<ContractReminderModel, 'date' | 'title' | 'description' | 'complete'> & { users: string[] }>

export type UpdateAttachmentSignaturesBulkBody = {
  attachment: string
  name: string
  email: string
  signatureType: AttachmentSignatureType
}

type UpdateAttachmentSignaturesBulkParam = {
  contractShellId: string
  contractId: string
  documentShellId: string
  attachmentId: string
  body: { items: UpdateAttachmentSignaturesBulkBody[] }
}

type UpdateContractApprovalSequenceParam = {
  contractShellId: string
  contractId: string
  approvalId: string
  body: UpdateContractApproverSequenceBody
}

type ActionContractShellParam = {
  contractShellId: string
  action: 'archive' | 'unarchive'
}

export type FilterContractShellSortKey = 'title' | 'procurementTitle' | 'createdAt' | 'totalValue' | 'totalExercised'

type FilterContractShellParam_DEPRECATED = {
  currentPage: number
  pageSize: number
  sort?: FilterContractShellSortKey
  order: 'asc' | 'desc'
  filters?: Filter_DEPRECATED[]
  searchQuery?: string
}

type FilterContractShellParam = {
  currentPage: number
  pageSize: number
  sort?: FilterContractShellSortKey
  order: 'asc' | 'desc'
  filters?: Filter[]
  searchQuery?: string
}

type ContractShellFilterTotalsParam = {
  baseFilters: { filters: Filter[]; searchQuery?: string }
  totalFilters: Record<string, { filters: Filter[] }>
}

export type ContractShellFilterTotals = { filteredSubtotal: number; total: number }

type ContractShellFilterTotalsResponse = {
  totals: Record<string, ContractShellFilterTotals>
}

type ExportContractShellCsvBody_DEPRECATED = {
  procurementId?: string
  filters?: Filter_DEPRECATED[]
  timeZone?: string
}

type ExportContractShellCsvBody = {
  procurementId?: string
  filters?: Filter[]
  searchQuery?: string
  timeZone?: string
}

export const useMutateContractShell = () => {
  const { mutate } = useMutate()

  return {
    createContractShell: async (body: CreateContractShellBody) => {
      return await mutate<ContractShellModel>({ route: '/v2/contract-shells', body, invalidate: ['/v2/contract-shells'] })
    },
    createContractDocumentShell: async (contractShellId: string, contractId: string, body?: CreateContractDocumentShellBody) => {
      return await mutate<ContractShellModel>({
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/document-shells`,
        body,
        invalidate: ['/v2/contract-shells'],
      })
    },
    createContractVariation: async (contractShellId: string, contractId: string, body?: CreateContractVariationBody) => {
      return await mutate<ContractShellModel>({
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/variation`,
        body,
        invalidate: ['/v2/contract-shells'],
      })
    },
    updateContractShell: async (id: string, body: UpdateContractShellBody) => {
      return await mutate<ContractShellModel>({ method: 'PUT', route: `/v2/contract-shells/${id}`, body, invalidate: ['/v2/contract-shells'] })
    },
    deleteContractShell: async (id: string) => {
      return await mutate<ContractShellModel>({ method: 'DELETE', route: `/v2/contract-shells/${id}`, invalidate: ['/v2/contract-shells'] })
    },
    associateContractShell: async (id: string, body: AssociateContractShellBody) => {
      return await mutate<ContractShellModel>({
        method: 'PUT',
        route: `/v2/contract-shells/${id}/associate`,
        body,
        invalidate: ['/v2/contract-shells'],
      })
    },
    updateContractDocumentShell: async (
      contractShellId: string,
      contractId: string,
      documentShellId: string,
      body: UpdateContractDocumentShellBody
    ) => {
      return await mutate<ContractShellModel>({
        method: 'PUT',
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/document-shells/${documentShellId}`,
        body,
        invalidate: ['/v2/contract-shells'],
      })
    },
    updateContractMetadata: async (contractShellId: string, contractId: string, metadataId: string, body: UpdateContractMetadataBody) => {
      return await mutate<ContractShellModel>({
        method: 'PUT',
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/metadata/${metadataId}`,
        body,
        invalidate: ['/v2/contract-shells'],
      })
    },
    updateContractPriceDurationBulk: async (contractShellId: string, contractId: string, body: UpdateContractPriceDurationBulkBody) => {
      return await mutate<ContractShellModel>({
        method: 'PUT',
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/price-durations/bulk`,
        body,
        invalidate: ['/v2/contract-shells'],
      })
    },
    updateContractMilestoneBulk: async (contractShellId: string, contractId: string, body: UpdateContractMilestoneBulkBody) => {
      return await mutate<ContractShellModel>({
        method: 'PUT',
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/milestones/bulk`,
        body,
        invalidate: ['/v2/contract-shells'],
      })
    },
    updateScheduledRatesBulk: async (contractShellId: string, contractId: string, body: UpdateScheduledRatesBulkBody) => {
      return await mutate<ContractShellModel>({
        method: 'PUT',
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/scheduled-rates/bulk`,
        body,
        invalidate: ['/v2/contract-shells'],
      })
    },
    updateAttachmentSignaturesBulk: async ({
      contractShellId,
      contractId,
      documentShellId,
      attachmentId,
      body,
    }: UpdateAttachmentSignaturesBulkParam) => {
      return await mutate<ContractShellModel>({
        method: 'PUT',
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/document-shells/${documentShellId}/attachments/${attachmentId}/signatures/bulk`,
        body,
        invalidate: ['/v2/contract-shells'],
      })
    },
    updateContractApproverSequence: async ({ contractShellId, contractId, approvalId, body }: UpdateContractApprovalSequenceParam) => {
      return await mutate<ContractShellModel>({
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/approvals/${approvalId}/sequence`,
        body,
        invalidate: ['/v2/contract-shells'],
      })
    },
    uploadContractDocumentShellAttachment: async (contractShellId: string, contractId: string, documentShellId: string, image: File) => {
      const body = new FormData()
      body.append('image', image)

      return await mutate<ContractShellModel>({
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/document-shells/${documentShellId}/attachments`,
        body,
        type: 'form-data',
        invalidate: ['/v2/contract-shells'],
      })
    },
    uploadContractSignedDocument: async ({
      contractShellId,
      contractId,
      documentShellId,
      attachmentId,
      documentId,
    }: UploadContractSignedDocumentParam) => {
      await mutate({
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/document-shells/${documentShellId}/attachments/${attachmentId}`,
        body: {
          documentId,
        },
        invalidate: ['/v2/contract-shells'],
      })
    },
    removeContractDocumentShellAttachment: async ({
      contractShellId,
      contractId,
      documentShellId,
      attachmentId,
    }: RemoveDocumentShellAttachmentParam) => {
      return await mutate<ContractShellModel>({
        method: 'DELETE',
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/document-shells/${documentShellId}/attachments/${attachmentId}`,
        invalidate: ['/v2/contract-shells'],
      })
    },
    removeContractDocumentShell: async (contractShellId: string, contractId: string, documentShellId: string) => {
      await mutate({
        method: 'DELETE',
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/document-shells/${documentShellId}`,
        invalidate: ['/v2/contract-shells'],
      })
    },
    removeContractApprover: async (contractShellId: string, contractId: string, approvalId: string, approverId: string) => {
      await mutate({
        method: 'DELETE',
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/approvals/${approvalId}/approvers/${approverId}`,
        invalidate: ['/v2/contract-shells'],
      })
    },
    actionContractApproval: async ({ contractShellId, contractId, approvalId, action }: ActionContractApprovalParam) => {
      await mutate({
        method: 'PUT',
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/approvals/${approvalId}/${action}`,
        invalidate: ['/v2/contract-shells'],
      })
    },
    actionContractApprover: async ({ contractShellId, contractId, approvalId, approverId, action, body }: ActionContractApproverParam) => {
      await mutate({
        method: 'PUT',
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/approvals/${approvalId}/approvers/${approverId}/${action}`,
        body,
        invalidate: ['/v2/contract-shells'],
      })
    },
    actionContractShell: async ({ contractShellId, action }: ActionContractShellParam) => {
      await mutate({
        method: 'PUT',
        route: `/v2/contract-shells/${contractShellId}/${action}`,
        invalidate: ['/v2/contract-shells'],
      })
    },
    ceaseContract: async ({ contractShellId, contractId, body }: CeaseContractParam) => {
      await mutate({
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}/cessation`,
        body,
        invalidate: ['/v2/contract-shells'],
      })
    },
    deleteContract: async (contractShellId: string, contractId: string) => {
      await mutate({
        method: 'DELETE',
        route: `/v2/contract-shells/${contractShellId}/contracts/${contractId}`,
        invalidate: ['/v2/contract-shells'],
      })
    },
    filterContractShell_DEPRECATED: async ({
      currentPage = 1,
      pageSize = 20,
      sort,
      order,
      filters,
      searchQuery,
    }: FilterContractShellParam_DEPRECATED) => {
      // If the filter value is an array, map it to get the value of what is being filtered
      const processedFilters = map(filters, (filter) => {
        const { field, operation, value } = filter
        const finalValue = isArray(value) ? map(value, ({ value }) => value) : value
        return { field, operation, value: finalValue }
      })

      const { limit, offset } = paginationService.convertPageSizeToOffset({ currentPage, pageSize })

      const { items, count, meta } = await mutate<PaginatedModel<ContractShellFilterPopulatedModel, FilterFieldOptions_DEPRECATED>>({
        method: 'POST',
        route: '/v2/contract-shells/filter',
        params: { limit, offset, sort, order: order === 'asc' ? 1 : -1 },
        body: { filters: processedFilters, searchQuery },
      })

      return {
        contractShells: items,
        pagination: { currentPage, pageSize, pageCount: Math.ceil(count / pageSize), itemCount: items.length, totalCount: count },
        meta,
      }
    },
    filterContractShell: async ({ currentPage = 1, pageSize = 20, sort, order, filters, searchQuery }: FilterContractShellParam) => {
      const { limit, offset } = paginationService.convertPageSizeToOffset({ currentPage, pageSize })

      const { items, count, meta } = await mutate<PaginatedModel<ContractShellFilterPopulatedModel, FilterFieldOptions>>({
        method: 'POST',
        route: '/v2/contract-shells/filter',
        params: { limit, offset, sort, order: order === 'asc' ? 1 : -1 },
        body: { filters: filters, searchQuery },
      })

      return {
        contractShells: items,
        pagination: { currentPage, pageSize, pageCount: Math.ceil(count / pageSize), itemCount: items.length, totalCount: count },
        meta,
      }
    },
    countContractShellFilterTotals: async ({ baseFilters, totalFilters }: ContractShellFilterTotalsParam) => {
      return await mutate<ContractShellFilterTotalsResponse>({
        method: 'POST',
        route: '/v2/contract-shells/filter-totals',
        body: { baseFilters, totalFilters },
      })
    },
    exportCsv_DEPRECATED: async (body: ExportContractShellCsvBody_DEPRECATED) => {
      // If the filter value is an array, map it to get the value of what is being filtered
      const finalFilter = body.filters?.map((filter) => {
        const { field, operation, value } = filter
        const finalValue = isArray(value) ? map(value, ({ value }) => value) : value
        return { field, operation, value: finalValue }
      })

      return await mutate<{ csv: string }>({
        method: 'POST',
        route: '/v2/contract-shells/export-csv',
        body: { ...body, filters: finalFilter },
      })
    },
    exportCsv: async (body: ExportContractShellCsvBody) => {
      return await mutate<{ csv: string }>({
        method: 'POST',
        route: '/v2/contract-shells/export-csv',
        body,
      })
    },
    createContractReminder: async (contractShellId: string, body: CreateContractReminderBody) => {
      return await mutate<ContractReminderModel>({
        route: `/v2/contract-shells/${contractShellId}/reminders`,
        body,
        invalidate: ['/v2/contract-shells'],
      })
    },
    updateContractReminder: async (id: string, contractShellId: string, body: UpdateContractReminderBody) => {
      return await mutate<ContractReminderModel>({
        route: `/v2/contract-shells/${contractShellId}/reminders/${id}`,
        body,
        invalidate: ['/v2/contract-shells'],
        method: 'PUT',
      })
    },
    deleteContractReminder: async (id: string, contractShellId: string) => {
      return await mutate<ContractReminderModel>({
        route: `/v2/contract-shells/${contractShellId}/reminders/${id}`,
        method: 'DELETE',
        invalidate: ['/v2/contract-shells'],
      })
    },
  }
}
