import React, { FormEvent, memo, useState } from 'react'
import { useMutateBilling } from '@cotiss/billing'
import { Button, Form, Icon, ModalConfirmationButton, ModalContent, Text, sentryService, useCallout, useToast } from '@cotiss/common'

export const BillingUpgradeFreeSupplierConfirmModal = memo(() => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { upgradeBilling } = useMutateBilling()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await upgradeBilling({ freeSupplier: true })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="relative min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalContent>
        <Button className="absolute top-0 right-0" onClick={closeModal} state="ghost" shape="square" isDisabled={isSaving}>
          <Icon icon="x-close" />
        </Button>

        <Text className="font-semibold" size="h5">
          Upgrade account
        </Text>

        <Text className="mt-4">
          Are you sure you want to subscribe to the <strong>&quot;Free Supplier&quot;</strong> product?
        </Text>
      </ModalContent>
      <ModalConfirmationButton type="submit" isLoading={isSaving}>
        Confirm
      </ModalConfirmationButton>
    </Form>
  )
})
