import { filter, includes, map } from 'lodash'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { SettingsModulesMetafieldCreateUpdateModal } from '@cotiss/settings'
import { METAFIELD_FIELD_TYPE_OPTIONS, MetafieldEntityType, MetafieldFieldType, MetafieldModel, useMutateMetafield } from '@cotiss/metafield'
import {
  Button,
  ConfirmModal,
  Drawer,
  Icon,
  Input,
  Label,
  Select_DEPRECATED,
  Table,
  TableHeader,
  TableRowCta,
  Text,
  TextArea,
  Tooltip_DEPRECATED,
  useCallout,
} from '@cotiss/common'

type FormData = {
  fieldType?: MetafieldFieldType
  fieldLabel: string
  fieldValues: string[]
  fieldDescription: string
}

type Props = {
  entityType: MetafieldEntityType
  metafield?: MetafieldModel
  isEditable?: boolean
}

export const SettingsModulesCreateUpdateMetafieldDrawer = memo(({ entityType, metafield, isEditable }: Props) => {
  const { openModal, closeNarrowDrawer } = useCallout()
  const { createMetafield, updateMetafield } = useMutateMetafield()
  const [formData, setFormData] = useState<FormData>({
    fieldType: metafield?.fieldType,
    fieldLabel: metafield?.fieldLabel ?? '',
    fieldValues: metafield?.fieldType === 'ARRAY_STRING' || metafield?.fieldType === 'MULTI_ARRAY_STRING' ? metafield.fieldValues : [],
    fieldDescription: metafield?.fieldDescription ?? '',
  })
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setFormData({
      fieldType: metafield?.fieldType,
      fieldLabel: metafield?.fieldLabel ?? '',
      fieldValues: metafield?.fieldType === 'ARRAY_STRING' || metafield?.fieldType === 'MULTI_ARRAY_STRING' ? metafield.fieldValues : [],
      fieldDescription: metafield?.fieldDescription ?? '',
    })
  }, [metafield])

  const metafieldFieldTypeOptions = useMemo(() => {
    if (!metafield) {
      return METAFIELD_FIELD_TYPE_OPTIONS
    }

    // Once the metafield is created we don't want to allow changing the field type
    // So only return the current field type as an option.
    // The exception to this is SHORT_STRING, LONG_STRING and HYPERLINK fields, which can be changed between each other.
    // This is because they are all text fields and the metafield value doesn't need to change between them.
    if (includes(['SHORT_STRING', 'LONG_STRING', 'HYPERLINK'], metafield.fieldType)) {
      return filter(METAFIELD_FIELD_TYPE_OPTIONS, ({ value }) => includes(['SHORT_STRING', 'LONG_STRING', 'HYPERLINK'], value))
    } else {
      return filter(METAFIELD_FIELD_TYPE_OPTIONS, { value: metafield.fieldType })
    }
  }, [metafield])

  const renderHeader = () => (
    <Text className="font-medium mr-2" size="h5">
      {metafield ? 'Edit' : 'Add'} custom field
    </Text>
  )

  const renderFooter = () => {
    if (!isEditable) {
      return null
    }

    return (
      <div className="flex items-center">
        <Tooltip_DEPRECATED
          tooltip="Please add at least one dropdown option"
          isEnabled={(formData.fieldType === 'ARRAY_STRING' || formData.fieldType === 'MULTI_ARRAY_STRING') && formData.fieldValues.length === 0}
        >
          <Button
            className="mr-2"
            type="submit"
            variant="secondary"
            isLoading={isSaving}
            isDisabled={(formData.fieldType === 'ARRAY_STRING' || formData.fieldType === 'MULTI_ARRAY_STRING') && formData.fieldValues.length === 0}
          >
            Save
          </Button>
        </Tooltip_DEPRECATED>
        <Button onClick={() => closeNarrowDrawer()} state="ghost" variant="link" isDisabled={isSaving}>
          Cancel
        </Button>
      </div>
    )
  }

  const handleSubmit = async () => {
    if (!formData.fieldType) {
      return
    }

    setIsSaving(true)
    if (metafield) {
      await updateMetafield({
        id: metafield._id,
        body: {
          ...formData,
          fieldValues: formData.fieldType === 'ARRAY_STRING' || formData.fieldType === 'MULTI_ARRAY_STRING' ? formData.fieldValues : undefined,
          isRequired: false,
        },
        propagateUpdates: true,
      })
    } else {
      await createMetafield({
        ...formData,
        fieldType: formData.fieldType,
        entityType: entityType,
        fieldValues: formData.fieldType === 'ARRAY_STRING' || formData.fieldType === 'MULTI_ARRAY_STRING' ? formData.fieldValues : undefined,
        isArchived: false,
        isRequired: false,
      })
    }

    closeNarrowDrawer()
  }

  const renderDropdownOptionTable = () => {
    return (
      <>
        <TableHeader className="flex justify-between items-center mt-6">
          <Text className="font-semibold">Dropdown options</Text>

          {isEditable && (
            <Button
              size="xs"
              variant="tertiary"
              onClick={() =>
                openModal(
                  <SettingsModulesMetafieldCreateUpdateModal
                    onSubmit={(value) =>
                      setFormData({
                        ...formData,
                        fieldValues: [...formData.fieldValues, value],
                      })
                    }
                  />
                )
              }
            >
              <Icon icon="plus-01" /> Add option
            </Button>
          )}
        </TableHeader>
        <Table
          columns={[
            {
              heading: 'Label',
              rows: map(formData.fieldValues, (fieldValue, index) => ({
                content: () => <Text size="sm">{fieldValue}</Text>,
                cta: (
                  <TableRowCta
                    actions={[
                      {
                        label: 'Edit',
                        onClick: () =>
                          openModal(
                            <SettingsModulesMetafieldCreateUpdateModal
                              value={fieldValue}
                              onSubmit={(value) => {
                                const newFieldValues = [...formData.fieldValues]
                                newFieldValues[index] = value
                                setFormData({ ...formData, fieldValues: newFieldValues })
                              }}
                            />
                          ),
                      },
                      ...(!metafield
                        ? [
                            {
                              label: 'Delete',
                              onClick: () =>
                                openModal(
                                  <ConfirmModal
                                    heading="Delete dropdown option"
                                    description="Are you sure you want to delete this dropdown option?"
                                    onSubmit={() => {
                                      const newFieldValues = [...formData.fieldValues]
                                      newFieldValues.splice(index, 1)
                                      setFormData({ ...formData, fieldValues: newFieldValues })
                                    }}
                                  />
                                ),
                            },
                          ]
                        : []),
                    ]}
                  />
                ),
              })),
            },
          ]}
          emptyCta={
            isEditable && (
              <Button
                size="sm"
                variant="secondary"
                state="text"
                onClick={() =>
                  openModal(
                    <SettingsModulesMetafieldCreateUpdateModal
                      onSubmit={(value) =>
                        setFormData({
                          ...formData,
                          fieldValues: [...formData.fieldValues, value],
                        })
                      }
                    />
                  )
                }
              >
                + Add option
              </Button>
            )
          }
        />
      </>
    )
  }

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit} isNarrow>
      <Label>Name</Label>
      <Input
        value={formData.fieldLabel}
        onChange={({ target }) => setFormData({ ...formData, fieldLabel: target.value })}
        placeholder="Enter name"
        maxLength={100}
        isDisabled={isSaving || !isEditable}
        isRequired
      />
      <Label className="mt-6">Description (optional)</Label>
      <TextArea
        className="mt-1"
        value={formData.fieldDescription}
        rows={4}
        onChange={({ target }) => setFormData({ ...formData, fieldDescription: target.value })}
        placeholder="Enter description"
        isDisabled={isSaving || !isEditable}
      />
      <Label className="mt-6">Field type</Label>
      <Select_DEPRECATED
        value={formData.fieldType}
        options={metafieldFieldTypeOptions}
        onChange={(fieldType) => setFormData({ ...formData, fieldType })}
        isDisabled={isSaving || !isEditable || metafieldFieldTypeOptions.length === 1}
        placeholder
        isRequired
      />
      {(formData.fieldType === 'ARRAY_STRING' || formData.fieldType === 'MULTI_ARRAY_STRING') && renderDropdownOptionTable()}
    </Drawer>
  )
})
