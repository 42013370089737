import { filter, includes, orderBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationCriteriaRatingScale, GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { Button, Drawer, Hr, Label, Select_DEPRECATED, Text, TextArea, sentryService, useCallout, useToast } from '@cotiss/common'
import {
  EVALUATION_EVENT_RATING_SCALE_OPTIONS,
  EvaluationEventRatingScaleDefinitionList,
  IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES,
  useEvaluationCriteria,
  useEvaluationEventAnalytics,
} from '@cotiss/evaluation-event'

type FormData = {
  content: string
  ratingScale: GqlEvaluationCriteriaRatingScale
  supplementary: string
}

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
  evaluationSubCriteriaItem?: GqlEvaluationCriteriaFieldsFragment
  isEditable?: boolean
}

const SUB_CRITERIA_NAME_MAX_LENGTH = 200

export const EvaluationEventSubCriteriaRatingScaleDrawer = memo((props: Props) => {
  const { evaluationEnvelope, parentEvaluationCriteriaItem, evaluationSubCriteriaItem, isEditable } = props
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationCriteria, mutateUpdateEvaluationCriteria, mutateCreateEvaluationCriteria } = useEvaluationCriteria()
  const [formData, setFormData] = useState<FormData>({
    content: evaluationSubCriteriaItem?.content || '',
    ratingScale: evaluationSubCriteriaItem?.ratingScale || evaluationEnvelope.defaultRatingScale,
    supplementary: evaluationSubCriteriaItem?.supplementary || '',
  })

  useEffect(() => {
    setFormData({
      content: evaluationSubCriteriaItem?.content || '',
      ratingScale: evaluationSubCriteriaItem?.ratingScale || evaluationEnvelope.defaultRatingScale,
      supplementary: evaluationSubCriteriaItem?.supplementary || '',
    })
  }, [evaluationSubCriteriaItem, evaluationEnvelope])

  useEffect(() => {
    track(evaluationSubCriteriaItem ? 'evaluation_event_wizard_sub_criteria_update_view' : 'evaluation_event_wizard_sub_criteria_create_view')
  }, [])

  const handleSubmit = async () => {
    try {
      setIsSaving(true)

      if (evaluationSubCriteriaItem) {
        track('evaluation_event_wizard_sub_criteria_update_submit')
        await mutateUpdateEvaluationCriteria({
          evaluationCriteriaId: evaluationSubCriteriaItem.id,
          content: formData.content,
          ratingScale: formData.ratingScale,
          supplementary: formData.supplementary,
        })
      }

      if (!evaluationSubCriteriaItem) {
        // Get the next index of the criteria within the envelope.
        const subCriteria = filter(evaluationCriteria, { parentEvaluationCriteriaId: parentEvaluationCriteriaItem.id })
        const index = (orderBy(subCriteria, 'index', 'desc')[0]?.index || 0) + 1

        track('evaluation_event_wizard_sub_criteria_create_submit')
        await mutateCreateEvaluationCriteria({
          parentEvaluationCriteriaId: parentEvaluationCriteriaItem.id,
          evaluationEnvelopeId: evaluationEnvelope.id,
          content: formData.content,
          weight: 1,
          ratingScale: formData.ratingScale,
          supplementary: formData.supplementary,
          index,
          isScored: true,
        })
      }
      closeDrawer()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      {evaluationSubCriteriaItem ? 'Edit' : 'Add'} sub-criteria
    </Text>
  )

  const renderFooter = () => {
    if (!isEditable) {
      return null
    }

    return (
      <>
        <Button className="mr-2" type="submit" variant="secondary" isLoading={isSaving}>
          Confirm
        </Button>
        <Button onClick={() => closeDrawer()} state="ghost" variant="link" isDisabled={isSaving}>
          Cancel
        </Button>
      </>
    )
  }

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <Label className="flex items-baseline">
        Sub-criteria name
        <Text size="sm" variant="light" className="ml-1">{`(${formData.content.length}/${SUB_CRITERIA_NAME_MAX_LENGTH})`}</Text>
      </Label>
      <TextArea
        value={formData.content}
        onChange={({ target }) => setFormData({ ...formData, content: target.value })}
        maxLength={SUB_CRITERIA_NAME_MAX_LENGTH}
        rows={3}
        isDisabled={isSaving || !isEditable}
        isRequired
        autoFocus
      />
      <Text className="mt-1" size="sm" variant="light">
        Max {SUB_CRITERIA_NAME_MAX_LENGTH} characters.
      </Text>
      <Label className="mt-6">
        Scoring requirements{' '}
        <Text isInline variant="light">
          (optional)
        </Text>{' '}
      </Label>
      <Text variant="light" size="sm">
        This is where you can add custom rating scale definitions or any additional scoring information.
      </Text>
      <TextArea
        className="mt-2"
        value={formData.supplementary}
        onChange={({ target }) => setFormData({ ...formData, supplementary: target.value })}
        rows={5}
        isDisabled={isSaving || !isEditable}
      />
      <Hr className="mt-4" />
      <div className="flex justify-between mt-8">
        <Label className="flex-1">Rating scale</Label>
        <Select_DEPRECATED<GqlEvaluationCriteriaRatingScale>
          className="flex-1"
          value={formData.ratingScale}
          options={EVALUATION_EVENT_RATING_SCALE_OPTIONS}
          onChange={(ratingScale) => setFormData({ ...formData, ratingScale })}
          isDisabled={isSaving || !isEditable}
          placeholder
          isRequired
          isFull={false}
        />
      </div>
      {includes(IS_SCORING_DETAILS_LINK_VISIBLE_RATING_SCALES, formData.ratingScale) && (
        <EvaluationEventRatingScaleDefinitionList className="mt-4" ratingScale={formData.ratingScale} />
      )}
    </Drawer>
  )
})
