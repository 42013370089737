import React, { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect, Route, useFeature } from '@cotiss/common'
import { ContractListPage as ContractListPageNew, ContractViewPage, ContractApprovalPage, ContractApprovalViewPage } from '@cotiss/contract'
import { ContractListPage_DEPRECATED } from '@cotiss/contract/pages/contract-list-deprecated.page'

export const ContractLayout = memo(() => {
  const isContractsSearchFiltersEnabled = useFeature('enable-contracts-search-filters')
  const ContractListPage = isContractsSearchFiltersEnabled ? ContractListPageNew : ContractListPage_DEPRECATED

  return (
    <Switch>
      <Route path="/contract/list" exact>
        <ContractListPage />
      </Route>
      <Route path="/contract/list/:tab?" exact>
        <ContractListPage />
      </Route>
      <Route path="/contract/view/:contractShellId/:tab?" exact>
        <ContractViewPage />
      </Route>
      <Route path="/contract/approval/:contractShellId/:contractId/:approvalId?" exact>
        <ContractApprovalPage />
      </Route>
      <Route path="/contract/approval/:contractShellId/:contractId/:approvalId?/view/:tab?" exact>
        <ContractApprovalViewPage />
      </Route>
      <Redirect from="/contract" to="/contract/list" />
    </Switch>
  )
})
