import React, { memo } from 'react'
import { useUserAccess } from '@cotiss/user'
import { PerformanceScorecardCreateFormData } from '@cotiss/performance'
import { Field, Input, RadioCard, Text, Radio, Banner, Badge, Icon, Tooltip_DEPRECATED } from '@cotiss/common'

type Props = {
  formData: PerformanceScorecardCreateFormData
  setFormData: (formData: PerformanceScorecardCreateFormData) => void
}

export const PerformanceScorecardCreateInitialStep = memo(({ formData, setFormData }: Props) => {
  const { permissions } = useUserAccess()

  return (
    <>
      <Banner className="flex items-center justify-between" variant="light">
        <div className="mr-6">
          <Text className="font-semibold" variant="heading">
            Create scorecard
          </Text>
          <Text size="sm">Enter a title and select an option below.</Text>
        </div>
        <Badge variant="secondary" state="translucent">
          Step 1 of {formData.withContract ? '3' : '2'}
        </Badge>
      </Banner>
      <Field className="mt-8" label="Scorecard title">
        <Input
          value={formData.name}
          onChange={({ target }) => setFormData({ ...formData, name: target.value })}
          placeholder="Enter the scorecard title..."
          isRequired
        />
      </Field>
      <Field
        className="mt-8"
        label="Link contract to scorecard"
        supplementary="Whether this scorecard will appear on one of the contracts associated with this vendor."
      >
        <div className="grid grid-cols-2 grid-rows-2 gap-4">
          <RadioCard
            className="border border-gray-100 p-3.5"
            name="performance-scorecard-create-link-contract"
            onChange={() => setFormData({ ...formData, withContract: false })}
            isSelected={!formData.withContract}
          >
            <div className="flex justify-between mb-3">
              <Text className="font-semibold">No contract</Text>

              <Radio className="ml-2" isChecked={!formData.withContract} />
            </div>
            <Text className="mt-1" size="sm" variant="light">
              Used mostly in strategic score-carding to directly score vendors against each other.
            </Text>
          </RadioCard>
          <RadioCard
            className="border border-gray-100 p-3.5"
            name="performance-scorecard-create-link-contract"
            onChange={() => permissions.hasContractAccess && setFormData({ ...formData, withContract: true })}
            isSelected={formData.withContract}
          >
            <div className="flex justify-between mb-3">
              <Text className="font-semibold">With contract</Text>

              {permissions.hasContractAccess ? (
                <Radio className="ml-2" isChecked={formData.withContract} />
              ) : (
                <Tooltip_DEPRECATED tooltip="Contract module is not enabled">
                  <Icon icon="lock" />
                </Tooltip_DEPRECATED>
              )}
            </div>
            <Text className="mt-1" size="sm" variant="light">
              Track the progress and performance of this vendor specific to a contract.
            </Text>
          </RadioCard>
        </div>
      </Field>
    </>
  )
})
