import { map } from 'lodash'
import classNames from 'classnames'
import React, { HTMLProps, ReactNode, memo } from 'react'
import { Button, Dropdown, DropdownContent, Icon, TableState, TableVariant } from '@cotiss/common'

export type TableThAction = {
  name: string
  onClick: () => void
  isDisabled?: boolean
}

type Props = HTMLProps<HTMLTableCellElement> & {
  children?: ReactNode
  className?: string
  onSort?: () => void
  actions?: TableThAction[]
  state?: TableState
  variant?: TableVariant
  isWrapped?: boolean
  isScrollableTable?: boolean
}

export const TableTh = memo(
  ({ className, children, onSort, actions, state = 'default', variant = 'white', isWrapped, isScrollableTable, ...rest }: Props) => {
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)
    const classes = classNames(className, 'uppercase font-normal text-gray-400 text-left text-2xs px-4 py-2.5', {
      'first:border-l-0': isScrollableTable && state === 'default',
      'border border-gray-200': state === 'default',
      'bg-primary-50': state === 'default' && variant === 'primary',
      'bg-white': state === 'default' && variant === 'white',
      'whitespace-nowrap': !isWrapped,
    })

    const renderContent = () => {
      if (actions?.length) {
        return (
          <div className="relative">
            {children}
            <Button className="absolute top-0 right-0 -mt-2" onClick={() => setIsDropdownOpen(true)} shape="square" state="ghost">
              <Icon icon="dots" variant="light" size={20} />
            </Button>
            <Dropdown className="absolute top-8 right-2" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
              {map(actions, ({ name, onClick, isDisabled }) => (
                <DropdownContent isDisabled={isDisabled} onClick={onClick}>
                  {name}
                </DropdownContent>
              ))}
            </Dropdown>
          </div>
        )
      }

      if (onSort) {
        return (
          <Button className="flex items-center text-gray-400 text-left text-2xs whitespace-nowrap uppercase" onClick={onSort} state="raw">
            {children}
            <Icon className="ml-1.5" icon="chevron-selector-vertical" />
          </Button>
        )
      }

      return <>{children}</>
    }

    return (
      <th {...rest} className={classes}>
        {renderContent()}
      </th>
    )
  }
)
