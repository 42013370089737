import { map } from 'lodash'
import { useParams } from 'react-router-dom'
import React, { memo, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { performanceService, usePerformanceScorecard, usePerformanceScorecardMetric } from '@cotiss/performance'
import {
  ErrorPanel,
  Icon,
  routerService,
  ScrollableTable,
  ScrollableTableColumn,
  sentryService,
  TableHeader,
  TableRowCta,
  Text,
  useAsyncEffect,
} from '@cotiss/common'
import { UserAvatarGroup } from '@cotiss/user'

export const PerformanceScorecardViewTrackingTab = memo(() => {
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const { performanceScorecard } = usePerformanceScorecard()
  const [pagination, setPagination] = useState<GqlPagination>()
  const { performanceScorecardId } = useParams<{ performanceScorecardId: string }>()
  const { performanceScorecardMetrics, queryPerformanceScorecardMetricList } = usePerformanceScorecardMetric()

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Metric',
        rows: map(performanceScorecardMetrics, ({ id: performanceScorecardMetricId, performanceMetric }) => ({
          content: () => (
            <Text className="truncate" title={performanceMetric.name}>
              {performanceMetric.name}
            </Text>
          ),
          cta: (
            <TableRowCta
              cta={{
                label: (
                  <>
                    View <Icon icon="arrow-right" />
                  </>
                ),
                href: routerService.getHref('/performance/scorecard/view/:performanceScorecardId/metric/:performanceScorecardMetricId', {
                  performanceScorecardId,
                  performanceScorecardMetricId,
                }),
              }}
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Target',
        rows: map(performanceScorecardMetrics, (performanceScorecardMetric) => ({
          content: () => (
            <Text>
              {performanceService.formatPerformanceValue({
                value: performanceScorecardMetric.target,
                performanceMetric: performanceScorecardMetric.performanceMetric,
              })}
            </Text>
          ),
        })),
      },
      {
        heading: 'Evaluators',
        rows: map(performanceScorecardMetrics, ({ evaluators }) => ({
          content: () => <UserAvatarGroup users={map(evaluators, 'user')} />,
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [performanceScorecardMetrics])

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      const { pagination } = await queryPerformanceScorecardMetricList({
        filter: {
          performanceScorecardId,
          isArchived: false,
        },
        pagination: {
          page: currentPage,
          pageSize: 100,
        },
      })
      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [currentPage])

  if (!isLoading && (isError || !performanceScorecardMetrics.length)) {
    return <ErrorPanel />
  }

  if (!performanceScorecard) {
    return null
  }

  return (
    <>
      <TableHeader variant="white">
        <div>
          <Text className="font-medium" size="lg">
            Tracking
          </Text>
          <Text className="mt-1" size="sm" variant="light">
            Track the progress of active metrics associated to this scorecard
          </Text>
        </div>
      </TableHeader>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    </>
  )
})
