import { map } from 'lodash'
import React, { memo } from 'react'
import { TenderTypeBadge } from '@cotiss/tender'
import { useGetSupplierProfile } from '@cotiss/supplier'
import { UserAvatarGroup, userService } from '@cotiss/user'
import { useGetTenderInvitation } from '@cotiss/tender-invitation'
import { useGetProcurementResponse } from '@cotiss/procurement-response'
import { OrganisationLogo, useGetOrganisation, useListOrganisationUser } from '@cotiss/organisation'
import { TenderResponseStatusBadge, tenderResponseService, useGetTenderResponse } from '@cotiss/tender-response'
import {
  Avatar,
  Banner,
  Button,
  datetimeService,
  Drawer,
  ExternalLink,
  Hr,
  Icon,
  Pill,
  routerService,
  Skeleton,
  Text,
  useCallout,
} from '@cotiss/common'

type Props = {
  organisationId: string
  procurementResponseId?: string
  tenderResponseId?: string
  tenderInvitationId?: string
  onPreviewTenderResponse?: (tenderResponseId: string) => void
}

export const SupplierViewDrawer = memo((props: Props) => {
  const { organisationId, procurementResponseId, tenderResponseId, tenderInvitationId, onPreviewTenderResponse } = props
  const { closeNarrowDrawer } = useCallout()
  const { organisation, isLoading: isOrganisationLoading } = useGetOrganisation(organisationId)
  const { tenderResponse, isLoading: isTenderResponseLoading } = useGetTenderResponse(tenderResponseId)
  const { supplierProfile, isLoading: isSupplierProfileLoading } = useGetSupplierProfile(organisationId)
  const { tenderInvitation, isLoading: isTenderInvitationLoading } = useGetTenderInvitation(tenderInvitationId)
  const { organisationUsers, isLoading: isOrganisationUsersLoading } = useListOrganisationUser({ organisationId })
  const { procurementResponse, isLoading: isProcurementResponseLoading } = useGetProcurementResponse(procurementResponseId)
  const hasExternalLinks = Boolean(organisation?.website || organisation?.linkedin || organisation?.otherLink)
  const tenderResponseStatus = tenderResponse ? tenderResponse.status : tenderInvitation ? 'invited' : undefined
  const isLoading =
    isOrganisationLoading ||
    isOrganisationUsersLoading ||
    isSupplierProfileLoading ||
    Boolean(procurementResponseId && isProcurementResponseLoading) ||
    Boolean(tenderResponseId && isTenderResponseLoading) ||
    Boolean(tenderInvitationId && isTenderInvitationLoading)

  const handlePreviewResponseClick = (tenderResponseId: string) => {
    onPreviewTenderResponse && onPreviewTenderResponse(tenderResponseId)
    closeNarrowDrawer()
  }

  const renderHeader = () => (
    <Button href={routerService.getHref('/supplier/view/:id/:tab?', { id: organisationId })} state="outline" size="sm" isLink isOpenNewTab>
      View profile
    </Button>
  )

  const renderFooter = () => {
    if (!onPreviewTenderResponse || !tenderResponseStatus) {
      return null
    }

    return (
      <div className="flex items-center">
        {tenderResponse?.tender?.canViewResponsesBeforeClose && tenderResponseId && tenderResponse.status === 'submitted' && (
          <Button className="mr-4" onClick={() => handlePreviewResponseClick(tenderResponseId)} variant="secondary" size="sm">
            Preview response
          </Button>
        )}
        <Text className="mr-2">Status:</Text>
        <TenderResponseStatusBadge status={tenderResponseStatus} size="md" />
      </div>
    )
  }

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} isNarrow>
      {/* Details */}
      <div className="flex">
        <OrganisationLogo className="mr-4" organisation={organisation} />
        {isLoading && (
          <div className="w-full">
            <Skeleton className="w-full h-5" />
            <div className="flex items-center mt-2">
              <Skeleton className="rounded w-5 h-5 mr-3" />
              <Skeleton className="h-3 w-1/2" />
            </div>
            <div className="flex items-center mt-2">
              <Skeleton className="rounded w-5 h-5 mr-3" />
              <Skeleton className="h-3 w-1/2" />
            </div>
            <div className="flex items-center mt-2">
              <Skeleton className="rounded w-5 h-5 mr-3" />
              <Skeleton className="h-3 w-1/2" />
            </div>
          </div>
        )}
        {!isLoading && (
          <div className="w-full">
            <Text className="font-semibold line-clamp-2" size="h4">
              {organisation?.name ||
                tenderInvitation?.preferredSupplier.organisationName ||
                tenderInvitation?.preferredSupplier.supplierOrganisation?.name ||
                '--'}
            </Text>
            {hasExternalLinks && (
              <>
                {Boolean(organisation?.website) && (
                  <ExternalLink className="break-words mt-2" href={organisation?.website} variant="dark" size="sm">
                    <div className="inline-flex items-center justify-center bg-gray-100 rounded w-5 h-5 mr-2">
                      <Icon icon="globe-01" variant="link" size={12} />
                    </div>
                    {organisation?.website}
                  </ExternalLink>
                )}
                {Boolean(organisation?.linkedin) && (
                  <ExternalLink className="break-words mt-2" href={organisation?.linkedin} variant="dark" size="sm">
                    <div className="inline-flex items-center justify-center bg-gray-100 rounded w-5 h-5 mr-2">
                      <Icon icon="linkedin" variant="link" size={12} />
                    </div>
                    {organisation?.linkedin}
                  </ExternalLink>
                )}
                {Boolean(organisation?.otherLink) && (
                  <ExternalLink className="break-words mt-2" href={organisation?.otherLink} variant="dark" size="sm">
                    <div className="inline-flex items-center justify-center bg-gray-100 rounded w-5 h-5 mr-2">
                      <Icon icon="link-external-01" variant="link" size={12} />
                    </div>
                    {organisation?.otherLink}
                  </ExternalLink>
                )}
              </>
            )}
          </div>
        )}
      </div>

      {/* Tender response/invitation details */}
      {isLoading && (
        <>
          <Hr className="my-4" />
          <Skeleton className="h-2 w-16" />
          <Skeleton className="bg-gray-200 p-4 mt-2" variant="none">
            <div className="flex items-center justify-between">
              <Skeleton className="bg-primary-200 h-2 w-full mr-2" variant="none" />
              <Skeleton className="bg-primary-200 h-2 w-full mr-2" variant="none" />
              <Skeleton className="bg-primary-200 h-2 w-full mr-2" variant="none" />
              <Skeleton className="bg-primary-200 h-2 w-full mr-2" variant="none" />
              <Skeleton className="bg-primary-200 h-2 w-full" variant="none" />
            </div>
            <div className="flex items-center justify-between mt-2">
              <Skeleton className="bg-primary-200 h-2.5 w-full mr-2" variant="none" />
              <Skeleton className="bg-primary-200 h-2.5 w-full mr-2" variant="none" />
              <Skeleton className="bg-primary-200 h-2.5 w-full mr-2" variant="none" />
              <Skeleton className="bg-primary-200 h-2.5 w-full mr-2" variant="none" />
              <Skeleton className="bg-primary-200 h-2.5 w-full" variant="none" />
            </div>
          </Skeleton>
        </>
      )}
      {!isLoading && tenderResponseStatus && (
        <>
          <Hr className="my-4" />
          <div className="flex items-center">
            {tenderResponse?.tender.tenderType && <TenderTypeBadge className="mr-2" tenderType={tenderResponse.tender.tenderType} />}
            <Text className="font-medium" variant="heading">
              Response documents
            </Text>
          </div>
          <Banner className="p-4 mt-2" state="raw" variant={tenderResponseService.getStatusBaseVariant(tenderResponseStatus)}>
            <div className="grid grid-cols-5 gap-2">
              <Text variant="light" size="sm">
                Invited
              </Text>
              <Text variant="light" size="sm">
                Subscribed
              </Text>
              <Text variant="light" size="sm">
                Submitted
              </Text>
              <Text variant="light" size="sm">
                Invited by
              </Text>
              <Text variant="light" size="sm">
                Respondent
              </Text>
              <Text size="sm">{tenderInvitation?.createdAt ? datetimeService.format(tenderInvitation.createdAt, 'do MMM yyyy') : '--'}</Text>
              <Text size="sm">{tenderResponse?.createdAt ? datetimeService.format(tenderResponse.createdAt, 'do MMM yyyy') : '--'}</Text>
              <Text size="sm">{tenderResponse?.submittedAt ? datetimeService.format(tenderResponse.submittedAt, 'do MMM yyyy') : '--'}</Text>
              {tenderInvitation ? <Avatar>{userService.getInitials(tenderInvitation.createdBy)}</Avatar> : <Text size="sm">--</Text>}
              {procurementResponse?.procurementResponseUsers?.length ? (
                <UserAvatarGroup users={map(procurementResponse.procurementResponseUsers, ({ user }) => user)} />
              ) : (
                <Text size="sm">--</Text>
              )}
            </div>
          </Banner>
        </>
      )}

      {/* Contact */}
      {isLoading && (
        <>
          <Hr className="my-4" />
          <Skeleton className="h-2 w-16" />
          <Skeleton className="bg-gray-200 p-4 mt-2" variant="none">
            <Skeleton className="bg-primary-200 h-3 w-1/2" variant="none" />
            <Skeleton className="bg-primary-200 h-2 w-1/3 mt-2" variant="none" />
            <Skeleton className="bg-primary-200 h-2 w-2/3 mt-2" variant="none" />
          </Skeleton>
        </>
      )}
      {!isLoading && organisationUsers.length > 0 && (
        <>
          <Hr className="my-4" />
          <Text className="uppercase" variant="light" size="sm">
            Contact
          </Text>
          {map(organisationUsers, (user) => (
            <div key={user._id} className="bg-primary-50 rounded p-4 mt-2">
              <Text>{userService.getFullName(user)}</Text>
              <div className="flex items-center mt-2">
                <Icon className="mr-1" icon="phone" variant="link" size={16} />
                <Text size="sm" variant="light">
                  {user.phone}
                </Text>
              </div>
              <div className="flex items-center mt-2">
                <Icon className="mr-1" icon="mail" variant="link" size={16} />
                <Text size="sm" variant="light">
                  {user.email}
                </Text>
              </div>
            </div>
          ))}
        </>
      )}

      {/* About */}
      <Hr className="my-4" />
      {isLoading && (
        <>
          <Skeleton className="h-2 w-16" />
          <Skeleton className="h-3 w-full mt-2" />
          <Skeleton className="h-3 w-full mt-1" />
          <Skeleton className="h-3 w-2/3 mt-1" />
        </>
      )}
      {!isLoading && (
        <>
          <Text className="uppercase" variant="light" size="sm">
            About
          </Text>
          {!isLoading && <Text className="mt-2">{supplierProfile?.description || '–'}</Text>}
        </>
      )}

      {/* Categories */}
      <Hr className="my-4" />
      {isLoading && (
        <>
          <Skeleton className="h-2 w-16" />
          <div className="flex items-center mt-2">
            <Skeleton className="h-3 w-12 mr-2" isRounded />
            <Skeleton className="h-3 w-12" isRounded />
          </div>
        </>
      )}
      {!isLoading && (
        <>
          <Text className="uppercase" variant="light" size="sm">
            UNSPC Categories
          </Text>
          {!isLoading && (
            <>
              {!supplierProfile?.categories.length && <Text>--</Text>}
              {map(supplierProfile?.categories, ({ _id, description }) => (
                <Pill key={_id} className="mt-1 mr-1">
                  {description}
                </Pill>
              ))}
            </>
          )}
        </>
      )}

      {/* Regions */}
      <Hr className="my-4" />
      {isLoading && (
        <>
          <Skeleton className="h-2 w-16" />
          <div className="flex items-center mt-2">
            <Skeleton className="h-3 w-12 mr-2" isRounded />
            <Skeleton className="h-3 w-12" isRounded />
          </div>
        </>
      )}
      {!isLoading && (
        <>
          <Text className="uppercase" variant="light" size="sm">
            Regions
          </Text>
          {!isLoading && (
            <>
              {!supplierProfile?.regions.length && <Text>--</Text>}
              {map(supplierProfile?.regions, (region) => (
                <Pill key={region} className="mt-1 mr-1">
                  {region}
                </Pill>
              ))}
            </>
          )}
        </>
      )}

      {/* Certifications */}
      {isLoading && (
        <>
          <Hr className="my-4" />
          <Skeleton className="h-2 w-16" />
          <Skeleton className="bg-gray-200 p-4 mt-2" variant="none">
            <Skeleton className="bg-primary-200 h-3 w-1/2" variant="none" />
            <Skeleton className="bg-primary-200 h-2 w-1/3 mt-2" variant="none" />
          </Skeleton>
        </>
      )}
      {!isLoading && Boolean(organisation?.certifications?.length) && (
        <>
          <Hr className="my-4" />
          <Text className="uppercase" variant="light" size="sm">
            Certifications
          </Text>
          {map(organisation?.certifications, (certification) => (
            <div key={certification._id} className="flex bg-primary-50 rounded p-4 mt-2">
              <Icon className="mr-2" icon="check-verified-01" variant="secondary" />
              <div>
                <Text>{certification.title}</Text>
                <Text className="line-clamp-2 mt-1" variant="light" size="sm">
                  {certification.description}
                </Text>
              </div>
            </div>
          ))}
        </>
      )}
    </Drawer>
  )
})
