import { apiService, utilService } from '@cotiss/common'

export type SessionResponse = {
  accessToken: string
  refreshToken: string
  status: undefined
}

export type SessionType = 'public' | 'masquerade' | 'sso'

type VerifyDomainResponse = {
  message: boolean
}

type OtpResponse = {
  status: 'OTP'
}

type VerifyUserInvitationResponse = {
  email: string
  account: string
}

class AuthResource {
  login = async (email: string, password: string, oneTimePasswordToken?: string) => {
    const uri = '/v3/auth/login'
    return await apiService.post<SessionResponse | OtpResponse>(uri, { email, password, token: oneTimePasswordToken })
  }

  logout = async ({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) => {
    return await apiService.post('/v3/auth/logout', { accessToken, refreshToken })
  }

  refreshToken = async (refreshToken: string) => {
    const uri = '/v3/auth/refresh'
    return await apiService.post<SessionResponse>(uri, { refreshToken })
  }

  forgotPassword = async (email: string) => {
    return await apiService.post('/v3/auth/forgot-password', { email })
  }

  masqueradeTokenExchange = async (token: string) => {
    return await apiService.post<SessionResponse>('/v3/auth/masquerade-token-exchange', { token })
  }

  ssoTokenExchange = async (token: string) => {
    return await apiService.post<SessionResponse>('/v3/auth/sso-token-exchange', { token })
  }

  verifyDomain = async (email: string) => {
    return await apiService.get<VerifyDomainResponse>(`/v2/auth/domain/sign-up${utilService.generateUrlSearchParams({ email })}`)
  }

  verifyUserInvitation = async (invitationCode: string) => {
    return await apiService.post<VerifyUserInvitationResponse>(`/v2/auth/verify-invitation-code`, { invitationCode })
  }
}

export const authResource = new AuthResource()
