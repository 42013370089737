import { filter, map } from 'lodash'
import { useHistory } from 'react-router-dom'
import React, { FormEvent, memo, useMemo, useState } from 'react'
import { UserLineItem } from '@cotiss/user'
import { TenderStatus, useGetTender, useMutateTender } from '@cotiss/tender'
import { AccessControlModel, accessControlService, useListAccessControl } from '@cotiss/access-control'
import { Button, Form, Icon, ModalConfirmationButton, ModalContent, Text, routerService, sentryService, useCallout, useToast } from '@cotiss/common'

function getStatusText(status: TenderStatus) {
  switch (status) {
    default:
    case 'planning':
      return 'go to market request'
    case 'selection':
      return 'recommendation'
  }
}

type Props = {
  tenderId: string
}

export const TenderFlowSendForApprovalConfirmModal = memo(({ tenderId }: Props) => {
  const { push } = useHistory()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { tender } = useGetTender(tenderId)
  const { progressTender } = useMutateTender()
  const [isSaving, setIsSaving] = useState(false)
  const { accessControls } = useListAccessControl({ resourceId: tenderId })
  const { approvers, nextStep } = useMemo(() => {
    let approvers: AccessControlModel[] = []
    let nextStep: string | undefined

    if (tender?.status === 'planning') {
      approvers = filter(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'tender:approval:go-to-market'))
      nextStep = 'active'
    } else if (tender?.status === 'selection') {
      approvers = filter(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'tender:approval:recommendation'))
      nextStep = 'report'
    }

    return { approvers, nextStep }
  }, [accessControls])

  if (!tender) {
    return null
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await progressTender(tender._id)
      push(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step: nextStep }))
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="relative min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalContent>
        <Button className="absolute top-0 right-0" onClick={closeModal} state="ghost" shape="square" isDisabled={isSaving}>
          <Icon icon="x-close" />
        </Button>

        <Text className="font-semibold text-center" size="h5">
          Are you sure you want to send this {getStatusText(tender.status)} for approval?
        </Text>

        <Text className="mt-4" variant="light">
          This {getStatusText(tender.status)} will be reviewed by the user{approvers.length > 1 ? 's' : ''} below. You will receive an email once it
          the review has been completed.
        </Text>

        {map(approvers, ({ user }) => (
          <UserLineItem key={user._id} className="mt-4" {...user} />
        ))}
      </ModalContent>
      <ModalConfirmationButton type="submit" isLoading={isSaving}>
        Confirm
      </ModalConfirmationButton>
    </Form>
  )
})
