import { Button, Icon, Input, InputProps } from '@cotiss/common'
import classNames from 'classnames'
import { filter, map } from 'lodash'
import React, { memo } from 'react'

type Props = Omit<InputProps, 'onChange' | 'onBlur' | 'onFocus' | 'value'> & {
  ctaText?: string
  onChange: (value: string[]) => void
  onCreate: () => void
  values: string[]
}

export const MultiInput = memo(({ ctaText = '+ Add', className, onChange, onCreate, values, isRequired, isDisabled, ...rest }: Props) => {
  return (
    <div className={classNames(className, 'flex flex-col')}>
      {map(values, (value, index) => (
        <div key={index} className={classNames('flex items-center', { 'mt-4': index })}>
          <Input
            value={value}
            onChange={({ target }) => onChange(map(values, (value, i) => (i === index ? target.value : value)))}
            isDisabled={isDisabled}
            isRequired
            {...rest}
          />
          {(values.length > 1 || !isRequired) && (
            <Button
              className="justify-self-end"
              onClick={() => onChange(filter(values, (_, i) => i !== index))}
              shape="square"
              size="sm"
              state="ghost"
              isDisabled={isDisabled}
            >
              <Icon icon="x-close" variant="light" />
            </Button>
          )}
        </div>
      ))}
      <Button className="mt-4 self-start" onClick={onCreate} size="sm" state="translucent" variant="secondary" isDisabled={isDisabled}>
        {ctaText}
      </Button>
    </div>
  )
})
