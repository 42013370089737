import {
  Badge,
  Checkbox,
  Table_DEPRECATED,
  Tbody_DEPRECATED,
  Td_DEPRECATED,
  Text,
  Th_DEPRECATED,
  Thead_DEPRECATED,
  Tooltip_DEPRECATED,
  Tr_DEPRECATED,
} from '@cotiss/common'
import { PreferredSupplierPopulatedModel } from '@cotiss/preferred-supplier'
import { TenderInvitationModel } from '@cotiss/tender-invitation'
import classNames from 'classnames'
import { every, filter, includes, map, some } from 'lodash'
import React, { memo, useMemo } from 'react'

type RowItem = {
  _id: string
  email: string
}

type Props = {
  className?: string
  isDisabled?: boolean
  onAddUsers: (idsToAdd: string[]) => void
  onRemoveUsers: (idsToRemove: string[]) => void
  preferredSupplier: PreferredSupplierPopulatedModel
  selectedUsers?: string[]
  tenderInvitation?: TenderInvitationModel
}

export const PreferredSupplierTenderResponseList = memo((props: Props) => {
  const { className, isDisabled, onAddUsers, onRemoveUsers, preferredSupplier, selectedUsers, tenderInvitation } = props
  const contacts = preferredSupplier.contacts || []
  const accountUsers = preferredSupplier.supplierOrganisation?.account?.accountUser || []
  const allUserIds = [...map(contacts, ({ _id }) => _id), ...map(accountUsers, ({ _id }) => _id)]
  const organisationName = preferredSupplier.supplierOrganisation?.name || preferredSupplier.organisationName
  const rows = [...map(accountUsers, ({ _id, email }) => ({ _id, email })), ...map(contacts, ({ _id, email }) => ({ _id, email }))]

  const getIsInvited = (_idToCheck: string) => {
    return some(tenderInvitation?.contacts, ({ _id }) => _id === _idToCheck) || some(tenderInvitation?.users, ({ _id }) => _id === _idToCheck)
  }

  const { isAllChecked, isSomeChecked, isAllInvited, uninvitedUserIds } = useMemo(() => {
    /**
     * Uninvited users are users that are not invited to the tender yet
     * If the user is in the preferred supplier contacts or users, but not in the tender invite contacts or users, they are uninvited
     */
    const uninvitedUserIds = filter(allUserIds, (id) => !getIsInvited(id))
    const isAllChecked = allUserIds.length > 0 && every(uninvitedUserIds, (id) => includes(selectedUsers, id))
    const isSomeChecked = !isAllChecked && some(uninvitedUserIds, (id) => includes(selectedUsers, id))
    const isAllInvited =
      (contacts.length || 0) === (tenderInvitation?.contacts.length || 0) && (accountUsers.length || 0) === (tenderInvitation?.users?.length || 0)

    return { isAllChecked, isSomeChecked, isAllInvited, uninvitedUserIds }
  }, [preferredSupplier, selectedUsers, tenderInvitation])

  const handleSelectAll = () => {
    if (isAllChecked) {
      onRemoveUsers(uninvitedUserIds)
    } else {
      onAddUsers(uninvitedUserIds)
    }
  }

  const handleSelect = (_id: string) => {
    const isChecked = includes(selectedUsers, _id)

    if (isChecked) {
      onRemoveUsers([_id])
    } else {
      onAddUsers([_id])
    }
  }

  const renderRow = ({ _id, email }: RowItem) => {
    const isInvited = getIsInvited(_id)
    const isCheckboxDisabled = isInvited || isDisabled

    return (
      <Tr_DEPRECATED key={_id} variant="white">
        <Td_DEPRECATED colSpan={2}>
          <label className={classNames('flex items-center', { 'cursor-pointer': !isCheckboxDisabled })}>
            <Checkbox
              className="shrink-0 mr-2"
              onChange={() => handleSelect(_id)}
              isChecked={includes(selectedUsers, _id)}
              isDisabled={isCheckboxDisabled}
            />
            <Text variant={isInvited ? 'light' : 'dark'}>{email}</Text>
          </label>
        </Td_DEPRECATED>
        <Td_DEPRECATED className="text-right">
          {isInvited && (
            <Text className="text-right" size="sm" variant="light">
              Invited
            </Text>
          )}
        </Td_DEPRECATED>
      </Tr_DEPRECATED>
    )
  }

  const renderTags = () => {
    if (!preferredSupplier.tags?.length) {
      return null
    }

    const [firstTag, ...rest] = preferredSupplier.tags

    return (
      <>
        <Badge className="truncate max-w-[140px] mr-1" state="outline" variant="neutral" size="sm" title={firstTag}>
          {firstTag}
        </Badge>
        {Boolean(rest.length) && (
          <Tooltip_DEPRECATED className="mr-1" tooltip={rest.join(', ')}>
            <Text className="font-medium" variant="link" size="sm">
              +{rest.length}
            </Text>
          </Tooltip_DEPRECATED>
        )}
      </>
    )
  }

  const isHeaderCheckboxDisabled = isAllInvited || isDisabled

  return (
    <Table_DEPRECATED className={className}>
      <Thead_DEPRECATED>
        <Th_DEPRECATED>
          <label className={classNames('flex items-center normal-case', { 'cursor-pointer': !isHeaderCheckboxDisabled })}>
            <Checkbox
              className="shrink-0 mr-2"
              onChange={handleSelectAll}
              isChecked={isAllChecked}
              isIndeterminate={isSomeChecked}
              isDisabled={isHeaderCheckboxDisabled}
            />
            <Text className="font-semibold truncate mr-2">
              {organisationName} (
              {(preferredSupplier.contacts?.length || 0) + (preferredSupplier.supplierOrganisation?.account?.accountUser.length || 0)})
            </Text>
            {renderTags()}
          </label>
        </Th_DEPRECATED>
        <Th_DEPRECATED className="text-right w-36" colSpan={2}>
          {!preferredSupplier.supplierOrganisation && (
            <Badge className="normal-case" size="sm" variant="warning" state="translucent">
              Invite pending
            </Badge>
          )}
        </Th_DEPRECATED>
      </Thead_DEPRECATED>
      <Tbody_DEPRECATED>{map(rows, renderRow)}</Tbody_DEPRECATED>
    </Table_DEPRECATED>
  )
})
