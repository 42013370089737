import React, { memo } from 'react'
import { Header, Page, PageContent, Text } from '@cotiss/common'
import { ConflictOfInterestList } from '@cotiss/conflict-of-interest'

type Props = {
  isAllConflicts?: boolean
}

export const ConflictOfInterestListPage = memo(({ isAllConflicts }: Props) => (
  <Page>
    <Header className="flex items-center justify-between">
      <Text className="font-semibold" size="h5" variant="heading">
        Conflict of interest
      </Text>
    </Header>
    <PageContent>
      <ConflictOfInterestList isAllConflicts={isAllConflicts} />
    </PageContent>
  </Page>
))
