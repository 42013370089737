import { every, map } from 'lodash'
import { useParams } from 'react-router-dom'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import {
  EvaluationEventSubmissionDeleteModal,
  EvaluationEventSubmissionImportTypeModal,
  EvaluationEventSubmissionSetupCompleteBadge,
  EvaluationEventSubmissionUpdateDrawer,
  mutateDeleteEvaluationSubmission,
  useEvaluationEnvelope,
  useEvaluationEvent,
  useEvaluationEventAnalytics,
  useEvaluationSubmission,
  useEvaluationUser,
} from '@cotiss/evaluation-event'
import {
  Button,
  ConfirmModal,
  datetimeService,
  ErrorPanel,
  Icon,
  ScrollableTable,
  ScrollableTableColumn,
  sentryService,
  TableHeader,
  TableRowCta,
  Text,
  useAsyncEffect,
  useCallout,
} from '@cotiss/common'

export const EvaluationEventViewSubmissionsTab = memo(() => {
  const [isError, setIsError] = useState(false)
  const { openModal, openDrawer } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const [isLoading, setIsLoading] = useState(true)
  const { evaluationEvent } = useEvaluationEvent()
  const [currentPage, setCurrentPage] = useState(1)
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const { evaluationUserInSession } = useEvaluationUser()
  const [pagination, setPagination] = useState<GqlPagination>()
  const { evaluationEventId } = useParams<{ evaluationEventId: string }>()
  const { evaluationSubmissions, setEvaluationSubmission, queryEvaluationSubmissionList } = useEvaluationSubmission()
  const canAddSubmissions = useMemo(
    () =>
      !evaluationEvent?.isArchived &&
      evaluationUserInSession?.role === 'owner' &&
      every(evaluationEnvelopes, ({ status }) => status !== 'moderate' && status !== 'complete'),
    [evaluationEvent, evaluationUserInSession, evaluationEnvelopes]
  )

  useEffect(() => {
    track('evaluation_event_view_submissions_tab_view')
  }, [])

  useAsyncEffect(async () => {
    try {
      const { pagination } = await queryEvaluationSubmissionList({ filter: { evaluationEventId }, pagination: { page: currentPage, pageSize: 20 } })

      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [currentPage])

  const handleDeleteEvaluationSubmission = async (evaluationSubmissionId: string) => {
    await mutateDeleteEvaluationSubmission({ evaluationSubmissionId })
    await queryEvaluationSubmissionList({ filter: { evaluationEventId }, pagination: { page: currentPage, pageSize: 20 } })
  }

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Name',
        rows: map(evaluationSubmissions, (evaluationSubmission) => ({
          content: () => (
            <div className="truncate">
              <Text className="font-medium truncate" title={evaluationSubmission.name}>
                {evaluationSubmission.name}
              </Text>

              {evaluationSubmission?.organisation?.name && evaluationSubmission.name !== evaluationSubmission.organisation.name && (
                <Text className="truncate" size="sm" variant="light">
                  {evaluationSubmission?.organisation?.name}
                </Text>
              )}
            </div>
          ),
          cta: (
            <TableRowCta
              actions={[
                {
                  label: 'Delete',
                  onClick: () => {
                    openModal(
                      evaluationEvent?.status == 'active' ? (
                        <EvaluationEventSubmissionDeleteModal evaluationSubmission={evaluationSubmission} />
                      ) : (
                        <ConfirmModal
                          heading="Delete submission"
                          description="Are you sure you want to delete this submission?"
                          onSubmit={() => {
                            track('evaluation_event_view_submissions_tab_delete_submit')
                            handleDeleteEvaluationSubmission(evaluationSubmission.id)
                          }}
                        />
                      )
                    )
                  },
                },
              ]}
              cta={{
                label: (
                  <>
                    View <Icon className="ml-1" icon="arrow-right" />
                  </>
                ),
                onClick: () => {
                  setEvaluationSubmission(evaluationSubmission)
                  openDrawer(<EvaluationEventSubmissionUpdateDrawer />)
                },
              }}
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Contact',
        rows: map(evaluationSubmissions, (evaluationSubmission) => ({
          content: () => <Text>{evaluationSubmission.organisation?.name ?? '--'}</Text>,
        })),
      },
      {
        heading: 'Status',
        rows: map(evaluationSubmissions, (evaluationSubmission) => ({
          content: () => <EvaluationEventSubmissionSetupCompleteBadge isSetupComplete={evaluationSubmission.isSetupComplete} />,
        })),
      },
      {
        heading: 'Date added',
        rows: map(evaluationSubmissions, ({ createdAt }) => ({
          content: () => <Text>{datetimeService.format(createdAt, 'do MMM yyyy')}</Text>,
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [evaluationSubmissions])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  // TODO: Empty state for non-owner?

  return (
    <>
      <TableHeader className="flex justify-between items-center" variant="white">
        <Text className="font-medium" variant="heading" size="lg">
          Submissions
          {pagination && (
            <Text className="ml-1" variant="light" size="lg" isInline>
              ({pagination.totalCount})
            </Text>
          )}
        </Text>
        <div className="flex flex-col items-end ml-4">
          {canAddSubmissions && (
            <Button onClick={() => openModal(<EvaluationEventSubmissionImportTypeModal />)} state="translucent" variant="secondary-dark" size="xs">
              + Add submission
            </Button>
          )}
        </div>
      </TableHeader>
      <ScrollableTable
        fixedColumns={fixedColumns}
        columns={columns}
        pagination={pagination}
        onPageChange={setCurrentPage}
        emptyCta={
          canAddSubmissions && (
            <Button
              size="sm"
              state="text"
              variant="secondary"
              onClick={() => openModal(<EvaluationEventSubmissionImportTypeModal />)}
              isDisabled={isLoading}
            >
              + Add submission
            </Button>
          )
        }
        isLoading={isLoading}
      />
    </>
  )
})
