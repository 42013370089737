import classNames from 'classnames'
import React, { memo, ReactNode } from 'react'
import { BaseVariant } from '@cotiss/common'

export type TextVariant = BaseVariant | 'dark' | 'light' | 'heading' | 'link' | 'white' | 'none'
export type TextSize = 'xs' | 'sm' | 'md' | 'lg' | 'h7' | 'h5' | 'h4' | 'h3' | 'h2' | 'h1'

type Props = {
  children: ReactNode
  className?: string
  variant?: TextVariant
  size?: TextSize
  font?: 'inter'
  title?: string
  onClick?: () => void
  isInline?: boolean
}

export const Text = memo((props: Props) => {
  const { children, className, variant = 'dark', size = 'md', font = 'inter', title, onClick, isInline } = props
  const classes = classNames(className, 'antialiased', {
    'font-inter': font === 'inter',
    'text-gray-700': variant === 'dark',
    'text-light': variant === 'light',
    'text-primary-500': variant === 'heading' || variant === 'primary',
    'text-secondary-500': variant === 'link' || variant === 'secondary',
    'text-alert-error-400': variant === 'danger',
    'text-orange-700': variant === 'warning',
    'text-green-500': variant === 'success',
    'text-white': variant === 'white',
    'text-2xs': size === 'xs',
    'text-xs': size === 'sm',
    'text-sm': size === 'md',
    'text-base': size === 'h7' || size === 'lg',
    'text-lg': size === 'h5',
    'text-xl': size === 'h4',
    'text-2xl': size === 'h3',
    'text-[1.75rem] leading-[2.125rem]': size === 'h2',
    'text-[2rem] leading-[2.5rem]': size === 'h1',
  })

  return isInline ? (
    <span className={classes} onClick={onClick} title={title}>
      {children}
    </span>
  ) : (
    <div className={classes} onClick={onClick} title={title}>
      {children}
    </div>
  )
})
