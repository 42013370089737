import { find, map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { useEvaluation, useEvaluationScore, useEvaluationSubmission } from '@cotiss/evaluation-event'
import { Table, TableColumn, TableHeader, Text, sentryService, useAsyncEffect, useToast } from '@cotiss/common'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationFieldsFragment, GqlEvaluationScoreFieldsFragment, GqlPagination } from '@gql'

type Props = {
  evaluation: GqlEvaluationFieldsFragment
  evaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
}

export const EvaluationEventCriteriaScoreComparisonList = memo(({ evaluation, evaluationCriteriaItem }: Props) => {
  const { openToast } = useToast()
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const { queryEvaluationScoreList } = useEvaluationScore()
  const { evaluations, queryEvaluationList } = useEvaluation()
  const [pagination, setPagination] = useState<GqlPagination>()
  const { evaluationSubmissions, queryEvaluationSubmissionList } = useEvaluationSubmission()
  const [evaluationScores, setEvaluationScores] = useState<GqlEvaluationScoreFieldsFragment[]>([])

  useAsyncEffect(async () => {
    try {
      const { evaluationSubmissions } = await queryEvaluationSubmissionList({
        filter: { evaluationEventId: evaluation.evaluationEventId },
        pagination: { page: currentPage, pageSize: 10 },
      })
      const { evaluations } = await queryEvaluationList({
        filter: {
          evaluationEventId: evaluation.evaluationEventId,
          evaluationEnvelopeId: evaluation.evaluationEnvelopeId,
          evaluationSubmissionIds: map(evaluationSubmissions, 'id'),
          evaluationUserId: evaluation.evaluationUserId,
        },
      })
      const { evaluationScores, pagination } = await queryEvaluationScoreList({
        filter: {
          evaluationEventId: evaluation.evaluationEventId,
          evaluationIds: map(evaluations, 'id'),
          evaluationCriteriaId: evaluationCriteriaItem.id,
        },
      })

      setEvaluationScores(evaluationScores)
      setPagination(pagination)
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }, [currentPage])

  const columns = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: 'Contact name',
        rows: map(evaluationSubmissions, ({ name }) => ({
          content: () => <Text className="truncate">{name}</Text>,
        })),
      },
      {
        heading: 'Score',
        thClassName: 'w-20',
        rows: map(evaluationSubmissions, ({ id: evaluationSubmissionId }) => {
          const evaluationToCompare = find(evaluations, {
            evaluationSubmissionId,
            evaluationUserId: evaluation.evaluationUserId,
            evaluationEnvelopeId: evaluation.evaluationEnvelopeId,
          })
          const score =
            evaluationToCompare && find(evaluationScores, { evaluationId: evaluationToCompare.id, evaluationCriteriaId: evaluationCriteriaItem.id })

          return {
            content: () => <Text>{score?.value || '--'}</Text>,
          }
        }),
      },
      {
        heading: 'Comment',
        rows: map(evaluationSubmissions, ({ id: evaluationSubmissionId }) => {
          const evaluationToCompare = find(evaluations, {
            evaluationSubmissionId,
            evaluationUserId: evaluation.evaluationUserId,
            evaluationEnvelopeId: evaluation.evaluationEnvelopeId,
          })
          const score =
            evaluationToCompare && find(evaluationScores, { evaluationId: evaluationToCompare.id, evaluationCriteriaId: evaluationCriteriaItem.id })

          return {
            content: () => <Text>{score?.comment || '--'}</Text>,
          }
        }),
      },
    ]

    return columns
  }, [evaluationScores, evaluationSubmissions])

  return (
    <>
      <TableHeader>
        <Text className="font-semibold">{evaluationCriteriaItem.content}</Text>
        <Text variant="light" size="sm">
          Below are the scores given for this criteria across all submissions.
        </Text>
      </TableHeader>
      <Table columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    </>
  )
})
