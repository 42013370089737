import { includes, map } from 'lodash'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { useGetLoggedInUser } from '@cotiss/user'
import { EvaluationEventCreateModal, EvaluationEventDuplicateModal, EvaluationEventStatusBadge, useEvaluationEvent } from '@cotiss/evaluation-event'
import {
  ConfirmModal,
  ErrorPanel,
  Icon,
  NoDataPlaceholder,
  Table,
  TableColumn,
  TableRowCta,
  Text,
  routerService,
  sentryService,
  useAnalytics,
  useAsyncEffect,
  useCallout,
} from '@cotiss/common'

type Props = {
  isArchived?: boolean
}

export const EvaluationEventList = memo(({ isArchived = false }: Props) => {
  const { track } = useAnalytics()
  const { openModal } = useCallout()
  const { user } = useGetLoggedInUser()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<GqlPagination>()
  const canCreateEvaluationEvent = useMemo(() => includes(user?.permissions, 'PROCUREMENT_MANAGER'), [user])
  const { evaluationEvents, setEvaluationEvent, queryEvaluationEventList, mutateUpdateEvaluationEvent } = useEvaluationEvent()

  const handleQueryEvaluationEventList = async () => {
    try {
      setEvaluationEvent(null)

      const { pagination } = await queryEvaluationEventList({
        filter: {
          isArchived,
          isUserContext: true,
        },
        pagination: {
          page: currentPage,
          pageSize: 100,
        },
      })

      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    track(isArchived ? 'evaluation_event_list_archived_view' : 'evaluation_event_list_active_view')
  }, [isArchived])

  useAsyncEffect(async () => {
    handleQueryEvaluationEventList()
  }, [currentPage])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  const handleArchive = async (evaluationEventId: string, isArchived: boolean) => {
    track(isArchived ? 'evaluation_event_list_archive_submit' : 'evaluation_event_list_unarchive_submit')

    await mutateUpdateEvaluationEvent({ evaluationEventId, isArchived })
    await handleQueryEvaluationEventList()
  }

  if (!evaluationEvents.length && !isLoading) {
    if (isArchived) {
      return <NoDataPlaceholder label="You have not been added to any archived evaluation events." />
    }

    return (
      <NoDataPlaceholder
        label="You have not been added to any evaluation events yet."
        ctaLabel={canCreateEvaluationEvent ? '+ New evaluation' : undefined}
        onCtaClick={canCreateEvaluationEvent ? () => openModal(<EvaluationEventCreateModal />) : undefined}
      />
    )
  }

  const columns: TableColumn[] = [
    {
      heading: 'Evaluation event',
      rows: map(evaluationEvents, (evaluationEvent) => ({
        content: () => (
          <Text className="font-medium truncate" title={evaluationEvent.name}>
            {evaluationEvent.name}
          </Text>
        ),
        cta: (
          <TableRowCta
            cta={
              !isArchived
                ? {
                    href: routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', {
                      evaluationEventId: evaluationEvent.id,
                    }),
                    label: (
                      <>
                        View <Icon className="ml-1" icon="arrow-right" />
                      </>
                    ),
                  }
                : undefined
            }
            actions={
              canCreateEvaluationEvent
                ? [
                    {
                      onClick: () => openModal(<EvaluationEventDuplicateModal evaluationEvent={evaluationEvent} />),
                      label: 'Duplicate',
                    },
                    {
                      onClick: () =>
                        openModal(
                          <ConfirmModal
                            heading={evaluationEvent.isArchived ? 'Unarchive' : 'Archive'}
                            description={`Are you sure you want to ${evaluationEvent.isArchived ? 'unarchive' : 'archive'} this evaluation event?`}
                            onSubmit={() => handleArchive(evaluationEvent.id, !evaluationEvent.isArchived)}
                          />
                        ),
                      label: evaluationEvent.isArchived ? 'Unarchive' : 'Archive',
                    },
                  ]
                : undefined
            }
          />
        ),
      })),
    },
    {
      heading: 'Status',
      rows: map(evaluationEvents, ({ status }) => ({
        content: () => <EvaluationEventStatusBadge status={status} size="sm" />,
      })),
    },
  ]

  return <Table columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
})
