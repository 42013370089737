import { parseISO } from 'date-fns'
import React, { memo, useMemo, useState } from 'react'
import { isArray, map, toLower, upperFirst } from 'lodash'
import {
  Breadcrumb,
  Button,
  DatetimeInput,
  Drawer,
  FILTER_OPERATION_NAMES_DEPRECATED,
  Filter_DEPRECATED,
  FilterAllOperations_DEPRECATED,
  FilterFieldOptions_DEPRECATED,
  Icon,
  Input,
  MultiSelect_DEPRECATED,
  Radio,
  Text,
  datetimeService,
} from '@cotiss/common'

type Props = {
  filters: Filter_DEPRECATED[]
  setFilters: (filters: Filter_DEPRECATED[]) => void
  filterFields?: FilterFieldOptions_DEPRECATED
}

type TempFilter = { field: string; operation: FilterAllOperations_DEPRECATED; value?: any }

export const FilterDrawer_DEPRECATED = memo(({ filters, setFilters, filterFields }: Props) => {
  const [isAddingField, setIsAddingField] = useState(false)
  const [tempFilter, setTempFilter] = useState<TempFilter>()
  const [currentFilters, setCurrentFilters] = useState<Filter_DEPRECATED[]>(filters)
  const [selectedFilterField, setSelectedFilterField] = useState<{ field: string; displayName: string; currentIndex?: number }>()

  const breadcrumbs = useMemo(() => {
    if (!isAddingField) {
      return []
    }

    if (!selectedFilterField) {
      return [
        {
          label: 'Fields',
          onClick: () => {
            setIsAddingField(false)
            setSelectedFilterField(undefined)
            setTempFilter(undefined)
          },
        },
        {
          label: 'Select field',
        },
      ]
    }

    return [
      {
        label: 'Fields',
        onClick: () => {
          setIsAddingField(false)
          setSelectedFilterField(undefined)
          setTempFilter(undefined)
        },
      },
      {
        label: 'Select field',
        onClick: () => {
          setSelectedFilterField(undefined)
          setTempFilter(undefined)
        },
      },
      { label: upperFirst(toLower(selectedFilterField.displayName)) },
    ]
  }, [selectedFilterField, isAddingField])

  const renderSelection = (key: string, filterFields: FilterFieldOptions_DEPRECATED, currentIndex?: number) => {
    const otherFilters = currentIndex !== undefined ? currentFilters.filter((_, index) => index !== currentIndex) : currentFilters

    const value = filterFields[key]

    return (
      <div key={key}>
        <div className="flex justify-between items-center mb-4 border-b border-gray-100">
          <Text variant="heading" className=" font-semibold">
            {upperFirst(toLower(value.displayName))}
          </Text>
        </div>
        <div className="flex flex-col space-y-2">
          {map(value.supportedOperations, (operation, index) => {
            const isChecked = Boolean(tempFilter?.operation === operation)
            return (
              <div key={index}>
                <Radio<FilterAllOperations_DEPRECATED>
                  key={index}
                  value={operation}
                  onChange={() =>
                    setTempFilter({ ...tempFilter, field: key, operation, value: value.valueType === 'boolean' ? true : tempFilter?.value })
                  }
                  name="filter-drawer-radio-options"
                  className="mb-2"
                  isChecked={isChecked}
                >
                  {FILTER_OPERATION_NAMES_DEPRECATED[operation]}
                </Radio>
                {tempFilter?.field && tempFilter.operation && isChecked && (
                  <>
                    {value.valueType === 'array' && (
                      <MultiSelect_DEPRECATED
                        value={tempFilter?.value}
                        options={value.valueOptions}
                        onChange={(user) =>
                          setTempFilter({
                            ...tempFilter,
                            value: user,
                          })
                        }
                      />
                    )}
                    {value.valueType === 'string' && (
                      <Input
                        value={tempFilter?.value ?? ''}
                        onChange={({ target }) =>
                          setTempFilter({
                            ...tempFilter,
                            value: target.value ?? '',
                          })
                        }
                        placeholder="Enter value here"
                      />
                    )}
                    {value.valueType === 'date' && (
                      <DatetimeInput
                        value={tempFilter?.value ? parseISO(tempFilter.value) : null}
                        placeholder="Select date and time"
                        onChange={(date) =>
                          setTempFilter({
                            ...tempFilter,
                            value: date?.toISOString(),
                          })
                        }
                      />
                    )}
                    {value.valueType === 'number' && (
                      <Input
                        value={tempFilter?.value}
                        type="number"
                        step=".01"
                        onChange={({ target }) =>
                          setTempFilter({
                            ...tempFilter,
                            value: target.value,
                          })
                        }
                        placeholder="Enter value here"
                      />
                    )}
                  </>
                )}
              </div>
            )
          })}
          <Button
            size="sm"
            state="filled"
            className="w-1/3"
            variant="secondary"
            onClick={() => {
              tempFilter?.value && setFilters([...otherFilters, tempFilter as Filter_DEPRECATED])
              tempFilter?.value && setCurrentFilters([...otherFilters, tempFilter as Filter_DEPRECATED])
              setTempFilter(undefined)
              setSelectedFilterField(undefined)
              setIsAddingField(false)
            }}
          >
            Apply filter
          </Button>
        </div>
      </div>
    )
  }

  const renderHeader = () => (
    <Text className="font-semibold" size="h5" variant="heading">
      Filters
    </Text>
  )

  return (
    <Drawer header={renderHeader()} isNarrow>
      <Breadcrumb className="mb-4" breadcrumbs={breadcrumbs} />
      {!isAddingField && !selectedFilterField && filterFields && (
        <>
          <div className="flex justify-between items-center mb-4 border-b border-gray-100">
            <Text variant="heading" className=" font-semibold">
              Applied filters
            </Text>
            <Button
              size="sm"
              state="ghost"
              variant="link"
              isDisabled={currentFilters.length === 0}
              onClick={() => {
                setFilters([])
                setCurrentFilters([])
              }}
            >
              Clear ({currentFilters.length})
            </Button>
          </div>
          {map(currentFilters, (filter, index) => (
            <div key={index} className="flex items-center space-x-2">
              <button
                className=" bg-gray-100 rounded w-full p-2 text-left flex items-center space-x-2 truncate"
                onClick={() => {
                  setSelectedFilterField({ field: filter.field, displayName: filterFields[filter.field].displayName, currentIndex: index })
                  setIsAddingField(true)
                  setTempFilter({ field: filter.field, operation: filter.operation, value: filter.value })
                }}
              >
                <Text size="sm">{upperFirst(toLower(filterFields[filter.field].displayName))}:</Text>
                <Text size="sm" variant="light">
                  {FILTER_OPERATION_NAMES_DEPRECATED[filter.operation]}
                </Text>
                <Text size="sm" variant="link" className=" max-w-[156px] truncate">
                  {isArray(filter.value)
                    ? `(${filter.value.length})`
                    : filterFields[filter.field].valueType === 'date'
                    ? datetimeService.format(filter.value, 'd MMMM yyyy h:mm aaa')
                    : filter.value}
                </Text>
              </button>
              <Button
                onClick={() => {
                  const strippedFilters = currentFilters.filter((_, filterIndex) => filterIndex !== index)
                  setFilters(strippedFilters)
                  setCurrentFilters(strippedFilters)
                }}
                state="ghost"
                shape="square"
              >
                <Icon icon="x-close" />
              </Button>
            </div>
          ))}
          <Button size="sm" state="filled" variant="secondary" className="mt-2" onClick={() => setIsAddingField(true)}>
            Add field
          </Button>
        </>
      )}
      {isAddingField && !selectedFilterField && filterFields && (
        <>
          <div className="flex justify-between items-center mb-4 border-b border-gray-100">
            <Text variant="heading" className=" font-semibold">
              Select field
            </Text>
          </div>
          <div className="flex flex-col space-y-2 items-start">
            {Object.entries(filterFields).map(([key, value]) => {
              return (
                <button
                  className=" hover:bg-gray-100 bg-gray-50 rounded w-full p-2 text-left"
                  key={key}
                  onClick={() => setSelectedFilterField({ field: key, displayName: filterFields[key].displayName })}
                >
                  <Text>{upperFirst(toLower(value.displayName))}</Text>
                </button>
              )
            })}
          </div>
        </>
      )}
      {isAddingField &&
        selectedFilterField &&
        filterFields &&
        renderSelection(selectedFilterField.field, filterFields, selectedFilterField.currentIndex)}
    </Drawer>
  )
})
