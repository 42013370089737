import React, { memo, useMemo } from 'react'
import { Card, CardHeader, NoDataPlaceholder, Text } from '@cotiss/common'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources'
import { useParams } from 'react-router-dom'
import { ContractOwnersTable, ContractStatusBadge } from '@cotiss/contract'

export const ContractViewOwnersTab = memo(() => {
  const { contractShellId } = useParams<{ contractShellId: string }>()
  const { contractShell } = useGetContractShell(contractShellId)
  const contract = useMemo(() => contractShell && contractService.getContract(contractShell), [contractShell])

  if (!contract) {
    return (
      <Card>
        <NoDataPlaceholder label="No active contract to view" />
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader>
        <div>
          <Text className="mb-1" size="sm" variant="light">
            {contractShell?.title || ''}
          </Text>
          <Text className="font-semibold" size="h5">
            Owners
          </Text>
        </div>
        <ContractStatusBadge status={contract.status} />
      </CardHeader>
      <ContractOwnersTable contractShellId={contractShellId} contractId={contract._id} />
    </Card>
  )
})
