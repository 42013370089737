import React, { FormEvent, memo, useMemo, useState } from 'react'
import {
  useGetContractShell,
  useMutateContractShell,
  ContractWizardMilestoneModal,
  ContractWizardMilestoneTable,
  ContractWizardPriceDurationModal,
  ContractWizardPriceDurationTable,
  ContractShellType,
} from '@cotiss/contract'
import {
  Card,
  useAnalytics,
  useCallout,
  useToast,
  Text,
  useAsyncEffect,
  Form,
  RadioCard,
  Radio,
  CardHeader,
  Button,
  Field,
  TableHeader,
  Icon,
  ConfirmModal,
  sentryService,
  Tooltip_DEPRECATED,
} from '@cotiss/common'
import { last, map } from 'lodash'

type Props = {
  onNext: () => void
  onBack: (() => void) | null
  contractShellId: string
}

type ContractTypeOption = {
  title: string
  value: ContractShellType
  description: string
}

const CONTRACT_TYPE_OPTIONS: ContractTypeOption[] = [
  {
    title: 'Duration',
    value: 'PRICE_DURATION',
    description: 'This contract is set up around an initial term duration and renewal periods.',
  },
  {
    title: 'Milestones',
    value: 'MILESTONE',
    description: 'This contract is setup around a specific set of milestones that you would like to achieve.',
  },
]

export const ContractWizardOverviewStep = memo(({ onNext, onBack, contractShellId }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { updateContractMetadata, updateContractShell } = useMutateContractShell()

  const { openToast } = useToast()
  const { openModal } = useCallout()
  const { track } = useAnalytics()

  const { contract, milestones, priceDurations, isVariation } = useMemo(() => {
    const contract = contractShell?.contracts.length ? contractShell?.contracts.find((c) => c.status === 'DRAFTING') : null
    const milestones = contract?.milestones || []
    const priceDurations = contract?.priceDurations || []

    return {
      contract,
      priceDurations,
      isVariation: Boolean(contract?.variationTypes.length),
      milestones,
    }
  }, [contractShell?.contracts])

  const [contractType, setContractType] = useState<ContractShellType | undefined>(contractShell?.type)
  const [isSaving, setIsSaving] = useState(false)

  useAsyncEffect(async () => {
    if (!contractShell || !contract) {
      return
    }

    track('contract_wizard_overview_view')
  }, [contractShell, contract])

  if (!isLoading && !contract) {
    return (
      <Card>
        <div className="p-6 h-96 flex items-center justify-center">
          <Text>Couldn&apos;t load contract. Please try again.</Text>
        </div>
      </Card>
    )
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!contractShell || !contract?.metadata) {
      return
    }

    try {
      setIsSaving(true)
      await updateContractMetadata(contractShell._id, contract._id, contract.metadata._id, {
        currency: contract?.metadata.currency,
      })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }

    onNext()
  }

  const handleChange = async (value: ContractShellType) => {
    if (isVariation) {
      return
    }

    try {
      setIsSaving(true)

      if (!contractType) {
        await updateContractShell(contractShellId, { type: value })
        setContractType(value)
        setIsSaving(false)
        return
      }

      openModal(
        <ConfirmModal
          heading="Are you sure you want to change the contract type?"
          description={`Changing this option will delete all the data relevant to the contract overview.`}
          onSubmit={async () => {
            await updateContractShell(contractShellId, { type: value })
            setContractType(value)
          }}
        />
      )

      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderMilestones = () => {
    return (
      <>
        <TableHeader className="flex justify-between items-center">
          <Text className="font-semibold">Milestone contract overview</Text>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => openModal(<ContractWizardMilestoneModal contractShellId={contractShellId} />)}
            isDisabled={isLoading || isSaving}
          >
            <Icon icon="plus-01" />
            Add milestone
          </Button>
        </TableHeader>
        {contract?._id && (
          <ContractWizardMilestoneTable
            contractShellId={contractShellId}
            milestones={milestones}
            isVariation={isVariation}
            showErrors
            currency={contract?.metadata.currency ?? 'NZD'} // covers backwards compatibility can be removed in the near future
            isEditable
          />
        )}
      </>
    )
  }

  const renderPriceDuration = () => {
    return (
      <>
        <TableHeader className="flex items-center justify-between">
          <Text className="font-semibold">Duration contract overview</Text>
          <Button
            size="sm"
            variant="secondary"
            onClick={() =>
              openModal(<ContractWizardPriceDurationModal startDate={last(priceDurations)?.endDate} contractShellId={contractShellId} />)
            }
            isDisabled={isLoading || isSaving}
          >
            <Icon icon="plus-01" />
            Add {priceDurations.length ? 'renewal' : 'initial'} period
          </Button>
        </TableHeader>
        {contract?._id && (
          <ContractWizardPriceDurationTable
            contractShellId={contractShellId}
            priceDurations={priceDurations}
            isVariation={isVariation}
            showErrors
            currency={contract?.metadata.currency ?? 'NZD'} // covers backwards compatibility can be removed in the near future
            isEditable
          />
        )}
      </>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card>
        <CardHeader className="flex items-center justify-between">
          <div>
            <Text className="mb-1" variant="light" size="sm">
              {contractShell?.title}
            </Text>
            <Text className="font-semibold" variant="heading" size="h5">
              Milestone contract overview
            </Text>
          </div>
          <div className="ml-4">
            {onBack && (
              <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm" isDisabled={isLoading || isSaving}>
                Back
              </Button>
            )}
            <Button type="submit" variant="secondary" size="sm" isDisabled={isLoading || isSaving || !contractType}>
              Continue
            </Button>
          </div>
        </CardHeader>
        <Field
          className="mb-6 mt-6"
          label="Contract type"
          supplementary="Each contract type slightly affects the makeup of the contract overview. Once the contract is created, this cannot be edited."
          isFullWidth
        >
          <div className="grid grid-cols-3 grid-rows-1 gap-4">
            {map(CONTRACT_TYPE_OPTIONS, ({ title, value, description }) =>
              !isVariation ? (
                <RadioCard
                  key={value}
                  className="border border-gray-100 p-3.5"
                  name="contract-shell-type-selection"
                  isSelected={contractType === value}
                  onChange={() => handleChange(value)}
                  isDisabled={isSaving || isLoading}
                >
                  <div className="flex justify-between">
                    <Text className="font-medium">{title}</Text>
                    <Radio className="ml-2" isChecked={contractType === value} />
                  </div>
                  <Text className="mt-1" size="sm" variant="light">
                    {description}
                  </Text>
                </RadioCard>
              ) : (
                contractShell?.type === value && (
                  <Tooltip_DEPRECATED tooltip="Contract type cannot be edited in variation flow." className="flex items-center">
                    <RadioCard
                      key={value}
                      className="border border-gray-100 p-3.5"
                      name="contract-shell-type-selection"
                      isDisabled={isSaving || isLoading || isVariation}
                    >
                      <div className="flex justify-between">
                        <Text className="font-medium">{title}</Text>
                        <Icon className="text-light" icon="lock" />
                      </div>
                      <Text className="mt-1" size="sm" variant="light">
                        {description}
                      </Text>
                    </RadioCard>
                  </Tooltip_DEPRECATED>
                )
              )
            )}
          </div>
        </Field>
        {contractType === 'PRICE_DURATION' && renderPriceDuration()}
        {contractType === 'MILESTONE' && renderMilestones()}
      </Card>
    </Form>
  )
})
