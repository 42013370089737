import { filter, find, findIndex, some } from 'lodash'
import React, { FormEvent, memo, useMemo } from 'react'
import { AnimatePresence } from 'framer-motion'
import {
  Breadcrumb,
  BreadcrumbModel,
  Button,
  Card,
  CardHeader,
  ConfirmModal,
  Form,
  Header,
  Icon,
  PageContent,
  Section,
  TabModel,
  Tabs,
  Text,
  Tooltip_DEPRECATED,
  TransitionContainer,
  useFeature,
  useCallout,
  useTransition,
} from '@cotiss/common'
import {
  ContractApprovalStatusBadge,
  ContractAssociations,
  ContractApproversTable,
  ContractSummaryCessationDetails,
  ContractSummaryContractTab,
  ContractSummaryMilestoneTab,
  ContractSummaryPriceDurationTab,
  ContractSummaryScheduleOfRates,
  ContractSummarySignaturesTab,
  ContractSummarySupportingDocumentsTab,
  ContractSummaryTermsAndMetadataTab,
  ContractUploadSignedDocumentModal,
  ContractWizardFullModal,
  useGetContractShell,
  useMutateContractShell,
  contractService,
} from '@cotiss/contract'
import { UserLineItem, useGetLoggedInUser } from '@cotiss/user'

type ContractSummaryTab =
  | 'price-duration'
  | 'contract'
  | 'approvals'
  | 'cessation'
  | 'terms-metadata'
  | 'schedule-rates'
  | 'signatures'
  | 'milestones'
  | 'supporting-docs'
  | 'associations'

type Props = {
  contractShellId: string
  contractId: string
  breadcrumbs: BreadcrumbModel[]
  onClose?: () => void
}

export const ContractSummaryFullModal = memo(({ contractShellId, contractId, breadcrumbs, onClose }: Props) => {
  const { closeFullModal, openModal, openFullModal } = useCallout()
  const { step, transition, onTransition } = useTransition()
  const { contractShell } = useGetContractShell(contractShellId)
  const { actionContractApproval } = useMutateContractShell()
  const { user } = useGetLoggedInUser()
  const { contract, approval } = useMemo(() => {
    const contract = contractShell?.contracts.find((contract) => contract._id === contractId)
    const approval = contractService.getApproval(contract?.approvals, ['PENDING_APPROVAL', 'APPROVED']) // Also need to display approved in the case where signatures are pending

    return { contract, approval }
  }, [contractShell, contractId])

  // revoking should be who owns the contract or submitted the approval
  const canRevokeContract = useMemo(() => {
    if (!contract?.metadata?.owners.length || !approval?.createdBy || !user) {
      return false
    }

    if (approval?.createdBy._id === user?._id) {
      return true
    }

    return some(contract?.metadata?.owners, (owner) => owner._id === user?._id)
  }, [user, contract, approval])

  const isEsignEnabled = useFeature('contract-management-e-sign')

  const isUsingEsign = useMemo(() => isEsignEnabled && contract?.documentShells.find((ds) => ds.signatureType === 'ESIGN_DOCUSIGN'), [])

  const tabs = useMemo(() => {
    const variationTypes = contract?.variationTypes
    const tabs: TabModel<ContractSummaryTab & { isHidden?: boolean }>[] = [
      {
        id: 'terms-metadata',
        label: 'Terms and metadata',
        isHidden: Boolean(variationTypes?.length) && !variationTypes?.includes('metadata_terms'),
      },
      { id: 'supporting-docs', label: 'Supporting documents', isHidden: !find(contract?.documentShells, { type: 'SUPPORTING' }) },
      {
        id: 'associations',
        label: 'Associations',
        isHidden:
          Boolean(contract?.variationTypes.length) ||
          !some([
            contractShell?.childrenContractShells.length,
            contractShell?.parentContractShells.length,
            contractShell?.siblingsContractShells.length,
          ]),
      },
      {
        id: 'schedule-rates',
        label: 'Schedule of rates',
        isHidden: Boolean(variationTypes?.length && !variationTypes?.includes('scheduled_rates')),
      },
      { id: 'cessation', label: 'Cessation of contract', isHidden: !contract?.cessationDate },
      { id: 'price-duration', label: 'Price and duration', isHidden: contractShell?.type === 'MILESTONE' },
      { id: 'milestones', label: 'Milestones', isHidden: contractShell?.type !== 'MILESTONE' },
      { id: 'contract', label: 'Contract', isHidden: !find(contract?.documentShells, { type: 'CONTRACT' }) },
      {
        id: 'signatures',
        label: 'Signatures',
        isHidden: !isUsingEsign,
      },
      { id: 'approvals', label: 'Approvals', isHidden: !approval },
    ]
    return filter(tabs, (tab) => !tab.isHidden)
  }, [contract?.cessationDate])

  const activeTabId = useMemo(() => tabs[step - 1].id, [step, tabs])

  const handleClose = () => {
    closeFullModal()
    onClose && onClose()
  }

  const handleTabChange = (tab: ContractSummaryTab) => {
    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1
    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!contractShell || !contract || !approval) {
      return
    }

    openModal(
      <ConfirmModal
        heading="Are you sure you would like to revoke this request?"
        description={
          <>
            <Text className="mb-6 text-left" size="sm" variant="light">
              This is a global action and cannot be undone. The request owner will be notified of this action:
            </Text>
            <UserLineItem {...contract?.createdBy} />
          </>
        }
        onSubmit={async () => {
          await actionContractApproval({
            contractShellId: contractShell?._id,
            contractId: contractId,
            approvalId: approval._id,
            action: 'revoke',
          })
          // Close summary modal
          handleClose()
          // And open draft editing modal
          openFullModal(<ContractWizardFullModal contractShellId={contractShell._id} />)
        }}
      />
    )
  }

  return (
    <div className="bg-primary-50 w-full min-h-screen overflow-scroll">
      <Form onSubmit={handleSubmit}>
        <Header>
          <Section isCentered>
            <div className="flex items-center justify-between">
              <div className="w-full">
                <Breadcrumb breadcrumbs={breadcrumbs} />
                <div className="flex items-center">
                  <Text className="font-semibold mr-2" size="h4" variant="heading">
                    {contract?.variationTypes.length ? 'Variation request' : 'New contract request'}
                  </Text>
                  {approval?.status && <ContractApprovalStatusBadge status={approval.status} />}
                </div>
              </div>
              <Button className="ml-2" onClick={handleClose} state="ghost" variant="link" size="sm">
                Exit
              </Button>
            </div>
          </Section>
        </Header>
        <PageContent>
          <Section isCentered>
            <Card>
              <CardHeader className="flex items-center justify-between">
                <div>
                  <Text className="mb-1" variant="light" size="sm">
                    {contractShell?.title}
                  </Text>
                  <Text className="font-semibold" variant="heading" size="h5">
                    Summary
                  </Text>
                </div>
                <div className="ml-4">
                  {canRevokeContract ? (
                    <Button type="submit" variant="secondary" state="outline" size="xs">
                      Revoke request
                    </Button>
                  ) : (
                    <Tooltip_DEPRECATED className="hover:cursor-default" tooltip="You do not have permission to revoke this request">
                      <Icon icon="lock" variant="light" className="mr-1" />
                      <Button isDisabled variant="secondary" state="outline" size="xs">
                        Revoke request
                      </Button>
                    </Tooltip_DEPRECATED>
                  )}
                </div>
              </CardHeader>
              {contract?.status === 'PENDING_SIGNATURES' && (
                <div className="flex items-center justify-between bg-green-200 rounded-md p-4 mb-6">
                  <div className="flex items-center justify-center h-8 w-8 bg-white rounded-md mr-4">
                    <Icon icon="check-circle" className="text-green-500" />
                  </div>
                  <div className="flex-grow">
                    <Text className="font-semibold">Contract request has been approved!</Text>
                    <Text size="sm">
                      {isUsingEsign
                        ? 'Signed contract is required from signee(s) in order to create contract shell'
                        : 'Upload signed copy of the contract to create your contract shell'}
                    </Text>
                  </div>
                  {!isUsingEsign && (
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => openModal(<ContractUploadSignedDocumentModal contractShellId={contractShellId} contractId={contractId} />)}
                    >
                      <Icon icon="upload" className="mr-2" />
                      Upload signed contract
                    </Button>
                  )}
                </div>
              )}
              <Tabs<ContractSummaryTab>
                className="border-b border-gray-300 w-full mb-4"
                tab={activeTabId}
                tabs={tabs}
                onChange={({ id }) => handleTabChange(id)}
                variant="underline"
              />
              <AnimatePresence initial={false} mode="wait">
                <TransitionContainer key={step} transition={transition}>
                  {activeTabId === 'terms-metadata' && (
                    <ContractSummaryTermsAndMetadataTab contractShellId={contractShellId} contractId={contractId} />
                  )}
                  {activeTabId === 'supporting-docs' && (
                    <ContractSummarySupportingDocumentsTab contractId={contractId} contractShellId={contractShellId} />
                  )}
                  {activeTabId === 'associations' && <ContractAssociations contractShellId={contractShellId} />}
                  {activeTabId === 'schedule-rates' && <ContractSummaryScheduleOfRates contractShellId={contractShellId} contractId={contractId} />}
                  {activeTabId === 'cessation' && <ContractSummaryCessationDetails contractShellId={contractShellId} contractId={contractId} />}
                  {activeTabId === 'price-duration' && <ContractSummaryPriceDurationTab contractShellId={contractShellId} contractId={contractId} />}
                  {activeTabId === 'milestones' && <ContractSummaryMilestoneTab contractShellId={contractShellId} contractId={contractId} />}
                  {activeTabId === 'contract' && <ContractSummaryContractTab contractShellId={contractShellId} contractId={contractId} />}
                  {activeTabId === 'signatures' && <ContractSummarySignaturesTab contractShellId={contractShellId} contractId={contractId} />}
                  {activeTabId === 'approvals' && (
                    <ContractApproversTable contractShellId={contractShellId} contractId={contractId} approvalId={approval?._id} isCommentVisible />
                  )}
                </TransitionContainer>
              </AnimatePresence>
            </Card>
          </Section>
        </PageContent>
      </Form>
    </div>
  )
})
