import { map, upperFirst } from 'lodash'
import React, { memo, useMemo, useState } from 'react'
import { GqlCurrencyCode, GqlPerformanceScorecardMetricFrequency } from '@gql'
import { PERFORMANCE_METRIC_FREQUENCY_OPTIONS, PerformanceScorecardMetricCreateFormData, usePerformanceMetric } from '@cotiss/performance'
import {
  Badge,
  Banner,
  Breadcrumb,
  BreadcrumbModel,
  CURRENCY_DISPLAY_NAME_MAP,
  DatetimeInput,
  ErrorPanel,
  Field,
  Hr,
  Input,
  MONTH_CONFIG,
  MONTH_OPTIONS,
  Select_DEPRECATED,
  SelectOption_DEPRECATED,
  sentryService,
  Text,
  useAsyncEffect,
  useToast,
  WEEKDAY_OPTIONS,
  Weekday,
} from '@cotiss/common'

type Props = {
  formData: PerformanceScorecardMetricCreateFormData
  setFormData: (formData: PerformanceScorecardMetricCreateFormData) => void
  onBack: () => void
  isDisabled?: boolean
}

export const PerformanceScorecardMetricCreateConfigureStep = memo(({ formData, setFormData, onBack, isDisabled }: Props) => {
  const { openToast } = useToast()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { performanceMetric, queryPerformanceMetricView } = usePerformanceMetric()

  const frequencyEndOnDayOptions: SelectOption_DEPRECATED[] = useMemo(() => {
    if (formData.frequency === 'annually') {
      return map(Array(MONTH_CONFIG[formData.frequencyEndOnMonth].numberOfDays), (_, index) => ({ label: `${index + 1}`, value: `${index + 1}` }))
    } else {
      return map(Array(31), (_, index) => ({ label: `${index + 1}`, value: `${index + 1}` }))
    }
  }, [formData.frequency, formData.frequencyEndOnMonth])

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      await queryPerformanceMetricView({ performanceMetricId: formData.performanceMetricId })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message)
      setIsError(true)
    }

    setIsLoading(false)
  }, [formData.performanceMetricId])

  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Select metric',
      onClick: onBack,
    },
    {
      label: performanceMetric?.name || '',
      isLoading,
    },
  ]

  if (isError) {
    return (
      <>
        <Breadcrumb className="mb-4" breadcrumbs={breadcrumbs} onBack={onBack} />
        <ErrorPanel />
      </>
    )
  }

  return (
    <>
      <Breadcrumb className="mb-4" breadcrumbs={breadcrumbs} onBack={onBack} />
      <Banner className="flex items-center justify-between mb-6" variant="light">
        <div className="mr-6">
          <Text className="font-semibold" variant="heading">
            Enter metric requirements
          </Text>
          <Text size="sm">Assign a target value, frequency and start date to the metric.</Text>
        </div>
        <Badge variant="secondary" state="translucent">
          Step 2 of 2
        </Badge>
      </Banner>
      {performanceMetric && (
        <Text size="sm" variant="light">
          {performanceMetric.group}
        </Text>
      )}
      {performanceMetric && (
        <Text className="font-medium mt-2" size="lg">
          {performanceMetric.name}
        </Text>
      )}
      <Hr className="my-4" />
      <Field label="Description" supplementary="Outlines this metric in more detail.">
        {performanceMetric && <Text>{performanceMetric.description}</Text>}
      </Field>
      <Field className="mt-8" label="Methodology" supplementary="Outlines how to gather the value of this metric.">
        {performanceMetric && <Text>{performanceMetric.methodology}</Text>}
      </Field>
      <Field className="mt-8" label="Metric type (unit)">
        {performanceMetric && (
          <div className="flex items-center">
            <Text>
              {upperFirst(performanceMetric.metricUnit)}
              {performanceMetric.metricUnit === 'currency' && ': '}
            </Text>
            {performanceMetric.metricUnit === 'currency' && performanceMetric.metricUnitType && (
              <Text className="ml-1" variant="light">
                {performanceMetric.metricUnitType} ({CURRENCY_DISPLAY_NAME_MAP[performanceMetric.metricUnitType as GqlCurrencyCode]})
              </Text>
            )}
          </div>
        )}
      </Field>
      <Field className="mt-8" label="Target value" supplementary="Set a target value for this metric.">
        <div className="relative w-full">
          <Input
            className="pr-12"
            value={formData.target}
            onChange={({ target }) => setFormData({ ...formData, target: target.value })}
            placeholder="Enter the scorecard target value..."
            type="number"
            isDisabled={isDisabled}
            isRequired
          />
          {performanceMetric?.metricUnit === 'percentage' && <Text className="absolute right-4 top-1/2 -translate-y-1/2">%</Text>}
          {performanceMetric?.metricUnit === 'currency' && (
            <Text className="absolute right-4 top-1/2 -translate-y-1/2">{performanceMetric.metricUnitType}</Text>
          )}
        </div>
      </Field>
      <Field className="mt-8" label="Start date" supplementary="Set a date for metric to be collected.">
        <DatetimeInput
          value={formData.startDate}
          onChange={(startDate) => setFormData({ ...formData, startDate })}
          placeholder="Select start date..."
          dateFormat="do MMM yyyy"
          isDisabled={isDisabled}
          isTimeVisible={false}
          isFuture
          isRequired
        />
      </Field>
      <Field className="mt-8" label="Frequency" supplementary="Set a recurring frequency for this metric.">
        <Select_DEPRECATED<GqlPerformanceScorecardMetricFrequency>
          value={formData.frequency}
          options={PERFORMANCE_METRIC_FREQUENCY_OPTIONS}
          onChange={(frequency) => setFormData({ ...formData, frequency })}
          isDisabled={isDisabled}
          isRequired
        />
      </Field>
      {formData.frequency === 'annually' && (
        <Field className="mt-8" label="Day" supplementary="The date the frequency data collection occurs.">
          <div className="flex items-center">
            <Select_DEPRECATED
              className="mr-4"
              value={formData.frequencyEndOnDay}
              options={frequencyEndOnDayOptions}
              onChange={(frequencyEndOnDay) => setFormData({ ...formData, frequencyEndOnDay })}
              isDisabled={isDisabled}
              isRequired
            />
            <Select_DEPRECATED
              value={formData.frequencyEndOnMonth}
              options={MONTH_OPTIONS}
              onChange={(frequencyEndOnMonth) => setFormData({ ...formData, frequencyEndOnMonth })}
              isDisabled={isDisabled}
              isRequired
            />
          </div>
        </Field>
      )}
      {formData.frequency === 'monthly' && (
        <Field className="mt-8" label="Day" supplementary="The day of the month the frequency data collection occurs.">
          <Select_DEPRECATED
            value={formData.frequencyEndOnDay}
            options={frequencyEndOnDayOptions}
            onChange={(frequencyEndOnDay) => setFormData({ ...formData, frequencyEndOnDay })}
            isDisabled={isDisabled}
            isRequired
          />
        </Field>
      )}
      {formData.frequency === 'weekly' && (
        <Field className="mt-8" label="Day" supplementary="The day of the week the frequency data collection occurs.">
          <Select_DEPRECATED<Weekday>
            value={formData.frequencyEndOnWeekday}
            options={WEEKDAY_OPTIONS}
            onChange={(frequencyEndOnWeekday) => setFormData({ ...formData, frequencyEndOnWeekday })}
            isDisabled={isDisabled}
            isRequired
          />
        </Field>
      )}
    </>
  )
})
