import React from 'react'
import { TabModel, Tabs, useAnalytics } from '@cotiss/common'

import { PrimaryTabKeys, useContractSearchFiltersContext } from '@cotiss/contract/components/contract-list-search-filters-context.component'

type Props = {
  tabs: TabModel<PrimaryTabKeys>[]
  tab: PrimaryTabKeys
}

export const ContractListTabs = ({ tabs, tab }: Props) => {
  const { track } = useAnalytics()
  const { queryStateDispatch } = useContractSearchFiltersContext()

  const handleTabChange = (_tab: PrimaryTabKeys) => {
    if (tab === 'active') {
      track('contract_list_active_view')
    }

    if (tab === 'archived') {
      track('contract_list_archived_view')
    }

    if (tab === 'mine') {
      track('contract_list_my_contracts_view')
    }
    queryStateDispatch({ type: 'UPDATE_PRIMARY_TAB', payload: _tab })
  }
  return <Tabs<PrimaryTabKeys> tabs={tabs} tab={tab} onChange={({ id }) => handleTabChange(id)} variant="raised" />
}
