import React, { FormEvent, memo, useState } from 'react'
import { ForumQuestionModel, useMutateForum } from '@cotiss/forum'
import { Button, Form, Icon, ModalConfirmationButton, ModalContent, Text, sentryService, useAnalytics, useCallout, useToast } from '@cotiss/common'

type Props = {
  forumQuestion: ForumQuestionModel
}

export const ForumDeleteQuestionConfirmModal = memo(({ forumQuestion }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { deleteForumQuestion } = useMutateForum()
  const { track } = useAnalytics()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      track('forum_question_delete_submit')
      setIsSaving(true)
      await deleteForumQuestion(forumQuestion._id)
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="relative min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalContent>
        <Button className="absolute top-0 right-0" onClick={closeModal} state="ghost" shape="square" isDisabled={isSaving}>
          <Icon icon="x-close" />
        </Button>
        <Text className="font-semibold" size="h5">
          Delete question
        </Text>

        <Text className="mt-4">Are you sure you want to permanently delete this question?</Text>
      </ModalContent>
      <ModalConfirmationButton type="submit" isLoading={isSaving}>
        Confirm
      </ModalConfirmationButton>
    </Form>
  )
})
