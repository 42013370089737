import classNames from 'classnames'
import React, { memo, useMemo, useState } from 'react'
import { compact, filter, find, includes, isEqual, map, size, sortBy, toLower } from 'lodash'
import ListCheckboxPlaceholder from '@assets/svg/list-checkbox-placeholder.svg'
import PROCESSED_UNSPSC_DATA from '@cotiss/supplier/data/processed-unspsc-data.json'
import { ProcessedUnspscCategory, ProcessedUnspscSubcategory, supplierService } from '@cotiss/supplier'
import { Button, Checkbox, EXTERNAL_LINK, ExternalLink, Icon, Input, NoDataPlaceholder, Pill, Text, useCallout } from '@cotiss/common'

const sortedUnspscData = map(sortBy(PROCESSED_UNSPSC_DATA as ProcessedUnspscCategory[], 'name'), (category) => {
  const subcategories = [category, ...category.subcategories] // NOTE: Add the parent category itself to the list of subcategories

  return {
    ...category,
    subcategories: sortBy(subcategories, 'name'),
  }
})

type ActiveCategory = {
  categoryCode: string
  subcategories: ProcessedUnspscSubcategory[]
}

type Props = {
  selectedCategoryCodes: string[]
  onChange: (categoryCodes: string[]) => void
}

export const UnspscCategoriesModal = memo(({ selectedCategoryCodes, onChange }: Props) => {
  const [q, setQ] = useState('')
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const unspscCategoryMap = supplierService.getUnspscCategoryMap()
  const [activeCategory, setActiveCategory] = useState<ActiveCategory | null>(null)
  const [stateCategories, setStateCategories] = useState<ProcessedUnspscSubcategory[]>(
    sortBy(compact(map(selectedCategoryCodes, (categoryCode) => unspscCategoryMap[categoryCode])), 'name')
  )

  const filteredUnspscCategories = useMemo(() => {
    const qToUse = toLower(q)
    const processedUnspscData = qToUse
      ? map(sortedUnspscData, ({ subcategories, name, code }) => {
          const filteredSubcategories = filter(subcategories, ({ name, code }) => includes(toLower(name), qToUse) || includes(toLower(code), qToUse))

          if (filteredSubcategories.length || includes(toLower(name), qToUse) || includes(toLower(code), qToUse)) {
            return {
              subcategories: filteredSubcategories,
              name,
              code,
            }
          }

          return null
        })
      : sortedUnspscData

    return compact(processedUnspscData)
  }, [sortedUnspscData, q])

  const subCategoriesToRender = useMemo(() => {
    if (!activeCategory) {
      return []
    }

    const qToUse = toLower(q)
    return qToUse
      ? filter(activeCategory.subcategories, ({ name, code }) => includes(toLower(name), qToUse) || includes(toLower(code), qToUse))
      : activeCategory.subcategories
  }, [activeCategory, filteredUnspscCategories, q])

  const isDirty = useMemo(() => {
    return !isEqual(
      selectedCategoryCodes,
      map(stateCategories, ({ code }) => code)
    )
  }, [selectedCategoryCodes, stateCategories])

  const setStateCategoriesWithSort = (categories: ProcessedUnspscSubcategory[]) => {
    setStateCategories(sortBy(categories, 'name'))
  }

  const handleConfirm = () => {
    setIsSaving(true)
    onChange(map(stateCategories, ({ code }) => code))
  }

  const handleCategoryClick = ({ code: categoryCode }: ProcessedUnspscCategory) => {
    setActiveCategory({ categoryCode, subcategories: find(sortedUnspscData, { code: categoryCode })?.subcategories || [] })
  }

  const handleSubcategoryChange = (subcategory: ProcessedUnspscSubcategory, isSelected: boolean) => {
    setStateCategoriesWithSort(
      isSelected ? [...stateCategories, subcategory] : filter(stateCategories, (category) => category.code !== subcategory.code)
    )
  }

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-700 bg-opacity-50 p-20 z-[1001]">
      <div className="flex flex-col bg-white rounded-lg max-w-[900px] h-[500px] w-full py-4">
        <div className="flex items-center justify-between px-6">
          <div className="flex items-center w-full">
            <Text className="shrink-0 font-semibold" size="h7">
              Select UNSPSC Categories
            </Text>

            <div className="flex items-center border-l border-gray-200 w-full ml-3 pl-3">
              <Icon icon="search" variant="light" />
              <Input
                className="w-full"
                value={q}
                onChange={({ target }) => setQ(target.value)}
                placeholder="Search keyword or code #"
                state="ghost"
              />
            </div>
          </div>

          <div className="flex items-center shrink-0 ml-2">
            <Button className="mr-2" onClick={handleConfirm} variant="secondary" size="sm" isLoading={isSaving} isDisabled={!isDirty}>
              Confirm Selection
            </Button>
            <Button onClick={() => closeModal()} state="ghost" shape="square" isDisabled={isSaving}>
              <Icon icon="x-close" />
            </Button>
          </div>
        </div>
        <div className="flex items-stretch h-[calc(100%-42px)] mt-4">
          <div className="flex flex-col w-2/3 mr-4">
            <div className="flex items-center justify-between bg-secondary-100 rounded-r-lg py-3 px-6">
              <div className="w-1/3 mr-4">
                <Text className="font-medium uppercase" size="xs">
                  Categories ({size(filteredUnspscCategories)})
                </Text>
              </div>
              <div className="flex items-center justify-between w-2/3">
                <Text className="font-medium uppercase" size="xs">
                  Sub Categories{activeCategory && ` (${size(activeCategory.subcategories)})`}
                </Text>
                <Text className="font-medium uppercase" size="xs">
                  Code
                </Text>
              </div>
            </div>
            <div className="flex h-[calc(100%-42px)] pl-4">
              <div className="overflow-y-auto border-r border-gray-100 w-1/3 pr-2 mr-2">
                {map(filteredUnspscCategories, (category, index) => {
                  const { code, name, subcategories } = category
                  const isActive = Boolean(activeCategory?.categoryCode === code)
                  const classes = classNames('text-left text-xs rounded w-full p-2', {
                    'mt-4': !index,
                    'mt-0.5': index,
                    'bg-secondary-100': isActive,
                  })

                  return (
                    <Button key={code} className={classes} onClick={() => handleCategoryClick(category)} state="raw" isDisabled={isSaving}>
                      <Text variant={isActive ? 'secondary' : 'dark'}>
                        {name} ({subcategories.length})
                      </Text>
                    </Button>
                  )
                })}
              </div>
              <div className="overflow-y-auto pl-2 w-2/3">
                {activeCategory ? (
                  map(subCategoriesToRender, (subcategory, index) => {
                    const id = `cotiss-unspsc-category-${subcategory.code}`
                    const { code, name } = subcategory
                    const isSelected = Boolean(stateCategories.find((category) => category.code === code))
                    const classes = classNames('flex items-center justify-between bg-gray-50 text-xs cursor-pointer w-full p-2.5', {
                      'mt-4': index === 0,
                      'mt-0.5': index > 0,
                    })

                    return (
                      <label key={code} className={classes} htmlFor={id}>
                        <Text className="mr-2">{name}</Text>
                        <Text className="flex items-center text-light">
                          {code}
                          <Checkbox
                            id={id}
                            className="ml-2"
                            isChecked={isSelected}
                            onChange={() => handleSubcategoryChange(subcategory, !isSelected)}
                            isDisabled={isSaving}
                          />
                        </Text>
                      </label>
                    )
                  })
                ) : (
                  <div className="flex justify-center items-center h-full w-full">
                    <NoDataPlaceholder
                      label="Select a category from the list and browse subcategories here"
                      variant="transparent"
                      orientation="vertical"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-1/3 h-full pr-6 pb-4">
            {stateCategories.length > 0 ? (
              <div className="bg-secondary-50 border border-secondary-200 rounded-lg overflow-y-auto h-full p-4">
                <Text className="font-medium uppercase" size="xs">
                  Selected ({stateCategories.length})
                </Text>
                {map(stateCategories, (category) => (
                  <div key={category.code} className="mt-2">
                    <Pill onRemove={() => handleSubcategoryChange(category, false)}>{category.name}</Pill>
                  </div>
                ))}
              </div>
            ) : (
              <div className="border border-dashed flex flex-col justify-center items-center text-center border-secondary-200 rounded-lg overflow-y-auto h-full p-4">
                {activeCategory && (
                  <>
                    <ListCheckboxPlaceholder className="mb-4" />
                    <Text className="font-semibold mb-2">No Subcategories Selected</Text>
                    <Text size="xs" variant="light">
                      Tick the subcategories that you would like added. Learn more about{' '}
                      <ExternalLink href={EXTERNAL_LINK.unspsc} className="text-secondary-500 hover:underline" size="xs" isInline>
                        UNSPSC
                      </ExternalLink>{' '}
                      categories
                    </Text>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
