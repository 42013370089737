import { map, some } from 'lodash'
import React, { memo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Button, Drawer, TransitionContainer, Text, useTransition, useToast, sentryService, useCallout, Icon } from '@cotiss/common'
import {
  ContractLinkContractStep,
  ContractShellHierarchy,
  ContractShellFilterPopulatedModel,
  ContractLinkTypeStep,
  useGetContractShell,
  useMutateContractShell,
} from '@cotiss/contract'

type Props = {
  contractShellId: string
}

export const ContractLinkDrawer = memo(({ contractShellId }: Props) => {
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { associateContractShell } = useMutateContractShell()
  const { contractShell } = useGetContractShell(contractShellId)
  const { step, transition, isTransitioning, onTransition } = useTransition()
  const [hierarchy, setHierarchy] = useState<ContractShellHierarchy>('parent')
  const [selectedContractShell, setSelectedContractShell] = useState<ContractShellFilterPopulatedModel>()

  const handleLinkContract = async () => {
    if (!selectedContractShell) {
      return
    }

    if (!contractShell) {
      openToast('Something went wrong. Please re-load the page and try again.', 'danger')
      sentryService.captureException({ exception: 'Tried to update associated contracts but found no contract shell.', extras: { contractShellId } })
      return
    }

    // ----- Validate id/hierarchy pair -----
    // This shouldn't be allowed through the UI, but having extra check here just in case
    if (selectedContractShell._id === contractShellId) {
      setErrorMessage('Cannot link contract shell to itself.')
      return
    }
    if (hierarchy === 'child' && some(contractShell.childrenContractShells, { _id: selectedContractShell._id })) {
      setErrorMessage('This contract shell is already associated as a child.')
      return
    }
    if (hierarchy === 'parent' && some(contractShell.parentContractShells, { _id: selectedContractShell._id })) {
      setErrorMessage('This contract shell is already associated as a parent.')
      return
    }
    if (hierarchy === 'sibling' && some(contractShell.siblingsContractShells, { _id: selectedContractShell._id })) {
      setErrorMessage('This contract shell is already associated as a sibling.')
      return
    }
    // --------------------------------------

    try {
      setIsSaving(true)
      await associateContractShell(contractShellId, {
        ...(hierarchy === 'parent' && {
          parentContractShells: [...map(contractShell.parentContractShells, '_id'), selectedContractShell._id],
        }),
        ...(hierarchy === 'sibling' && {
          siblingsContractShells: [...map(contractShell.siblingsContractShells, '_id'), selectedContractShell._id],
        }),
        ...(hierarchy === 'child' && {
          childrenContractShells: [...map(contractShell.childrenContractShells, '_id'), selectedContractShell._id],
        }),
      })

      setIsSaving(false)
      closeDrawer()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast('Whoops, something went wrong. Please try again.', 'danger')
      setIsSaving(false)
    }
  }

  const handleSubmit = () => {
    if (step === 1 && selectedContractShell) {
      onTransition({ step: 2 })
    } else {
      handleLinkContract()
    }
  }

  const renderHeader = () => (
    <Text className="font-medium" size="h5" variant="heading">
      Link associated contract
    </Text>
  )

  const renderFooter = () => (
    <AnimatePresence mode="wait" initial={false}>
      <TransitionContainer key={step} transition={transition}>
        {step === 1 && (
          <div className="flex items-center">
            <Button type="submit" variant="secondary" isLoading={isSaving}>
              Continue <Icon className="ml-1" icon="arrow-right" />
            </Button>
            <Text className="ml-2">{selectedContractShell ? '1' : '0'} selected</Text>
          </div>
        )}
        {step === 2 && (
          <Button type="submit" variant="secondary" isLoading={isSaving}>
            Confirm
          </Button>
        )}
      </TransitionContainer>
    </AnimatePresence>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <AnimatePresence mode="wait" initial={false}>
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && (
            <ContractLinkContractStep
              contractShellId={contractShellId}
              selectedContractShell={selectedContractShell}
              onContractShellChange={setSelectedContractShell}
              isDisabled={isTransitioning || isSaving}
            />
          )}
          {step === 2 && selectedContractShell && (
            <ContractLinkTypeStep
              selectedContractShell={selectedContractShell}
              hierarchy={hierarchy}
              errorMessage={errorMessage}
              onHierarchyChange={setHierarchy}
              onBack={() => onTransition({ step: 1, transition: 'left' })}
              isDisabled={isTransitioning || isSaving}
            />
          )}
        </TransitionContainer>
      </AnimatePresence>
    </Drawer>
  )
})
