import { Filter, FilterFieldOptions } from '@cotiss/common/models/filter.model'
import { utilService } from '@cotiss/common/services'
import React, { createContext, useContext, useReducer, useMemo } from 'react'
import { AdvancedFiltersAction, advancedFiltersReducer } from '@cotiss/common/modals/advanced-filters/advanced-filters.reducer'
import { createEmptyFilter } from '@cotiss/common/modals/advanced-filters/advanced-filters.utils'
import { useDeepMemo } from '@cotiss/common/hooks/use-deep-memo.hook'

export type PartialFilter = Partial<Filter> & { id: string }

function mapFiltersToPartialFilters(advancedFilters?: Filter[]): PartialFilter[] {
  return advancedFilters?.length ? advancedFilters?.map((filter) => ({ id: utilService.generateUid(), ...filter })) : [createEmptyFilter()]
}

type AdvancedFiltersContextValue = {
  isInitialized: boolean
  filterFields: FilterFieldOptions
  advancedFilters: PartialFilter[]
  advancedFiltersDispatch: React.Dispatch<AdvancedFiltersAction>
  validAdvancedFilters: PartialFilter[]
}

const AdvancedFiltersContext = createContext<AdvancedFiltersContextValue | null>(null)

export const useAdvancedFiltersContext = () => {
  const context = useContext(AdvancedFiltersContext)
  if (!context) {
    throw new Error('useAdvancedFiltersContext must be used within an AdvancedFiltersProvider')
  }
  return context
}

const filterIsValid = (filter: PartialFilter): boolean => {
  const hasValidValue = Array.isArray(filter.value) ? filter.value.length > 0 : Boolean(filter.value)

  return Boolean(filter.field && filter.operation && hasValidValue)
}

export const AdvancedFiltersProvider: React.FC<{
  children: React.ReactNode
  initialFilters?: Filter[]
}> = ({ children, initialFilters }) => {
  const [state, dispatch] = useReducer(advancedFiltersReducer, {
    advancedFilters: mapFiltersToPartialFilters(initialFilters),
    filterFields: {},
    isInitialized: false,
  })

  const validAdvancedFilters = useDeepMemo(() => state.advancedFilters.filter(filterIsValid), [state.advancedFilters])

  const value = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      filterFields: state.filterFields,
      advancedFilters: state.advancedFilters,
      validAdvancedFilters,
      advancedFiltersDispatch: dispatch,
    }),
    [state.advancedFilters, state.filterFields, validAdvancedFilters, state.isInitialized]
  )

  return <AdvancedFiltersContext.Provider value={value}>{children}</AdvancedFiltersContext.Provider>
}
