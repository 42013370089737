import '@ungap/with-resolvers'
// This polyfill is required by the latest version of PdfJs for supporting legacy browsers
import React from 'react'
import { createRoot } from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { App } from './app'
import { AppProvider } from '@cotiss/app'
import { AuthProvider } from '@cotiss/auth'
import { CalloutProvider, ToastProvider, apolloService } from '@cotiss/common'
import { TooltipProvider } from '@radix-ui/react-tooltip'

const jsx = (
  <BrowserRouter>
    <ApolloProvider client={apolloService}>
      <QueryClientProvider client={new QueryClient()}>
        <AppProvider>
          <ToastProvider>
            <AuthProvider>
              <CalloutProvider>
                <TooltipProvider>
                  <App />
                </TooltipProvider>
              </CalloutProvider>
            </AuthProvider>
          </ToastProvider>
        </AppProvider>
        {process.env.IS_REACT_QUERY_DEV_TOOLS_ENABLED && <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />}
      </QueryClientProvider>
    </ApolloProvider>
  </BrowserRouter>
)

createRoot(document.getElementById('cotiss-app') as HTMLElement).render(jsx)
