import { Button, Form, Hr, Icon, RichText, Text, TextArea, datetimeService, sentryService, useAnalytics, useToast } from '@cotiss/common'
import { DocumentLink } from '@cotiss/document'
import { useGetProcurement } from '@cotiss/procurement'
import {
  TenderUpdateButtons,
  TenderUpdateComment,
  TenderUpdatePopulatedModel,
  useListTenderUpdateComment,
  useMutateTenderUpdate,
} from '@cotiss/tender-update'
import { useGetTender } from '@cotiss/tender/resources'
import classNames from 'classnames'
import { map } from 'lodash'
import React, { FormEvent, memo, useState } from 'react'

type Props = {
  className?: string
  tenderUpdate: TenderUpdatePopulatedModel
  onBack?: () => void
  isEditable?: boolean
  tenderResponseCount?: number
  isRead?: boolean
}

export const TenderUpdateExpanded = memo(({ className, tenderUpdate, onBack, isEditable, tenderResponseCount, isRead }: Props) => {
  const { openToast } = useToast()
  const [comment, setComment] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isCommenting, setIsCommenting] = useState(false)
  const { createTenderUpdateComment } = useMutateTenderUpdate()
  const classes = classNames(className, 'rounded-lg p-4')
  const { tenderUpdateComments } = useListTenderUpdateComment({ tenderUpdateId: tenderUpdate._id })
  const { track } = useAnalytics()
  const { tender } = useGetTender(tenderUpdate.tender)
  const { procurement } = useGetProcurement(tender?.procurement)

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      track('forum_update_comment_create_submit')
      await createTenderUpdateComment({ tenderUpdateId: tenderUpdate._id, content: comment })
      setComment('')
      setIsSaving(false)
      setIsCommenting(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <div key={tenderUpdate._id} className={classes}>
      <TenderUpdateButtons tenderUpdate={tenderUpdate} isEditable={isEditable} tenderResponseCount={tenderResponseCount} isRead={isRead} />

      {onBack && (
        <Button onClick={onBack} state="ghost" variant="link">
          <Icon className="mr-1" icon="arrow-left" size={20} />
          Back
        </Button>
      )}
      <div>
        {tenderUpdate.pinned && (
          <Text className="inline-flex items-center mr-2" size="xs" isInline>
            <Icon className="mr-1" icon="push-pin" size={12} />
            Pinned
          </Text>
        )}
        {tenderUpdate.hidden && (
          <Text className="inline-flex items-center" size="xs" isInline>
            <Icon className="mr-1" icon="hidden" size={12} />
            Hidden
          </Text>
        )}
      </div>
      <Text className="font-medium" variant="light" size="sm">
        {datetimeService.format(tenderUpdate.createdAt, 'd MMMM yyyy h:mm aaa')}
      </Text>

      <Text className="font-semibold mt-4">{tenderUpdate.title}</Text>

      <RichText className="mt-2" value={tenderUpdate.content} />

      {Boolean(tenderUpdate.attachments.length) && (
        <>
          <Text className="font-medium mt-4">Documents</Text>
          {map(tenderUpdate.attachments, (document) => (
            <DocumentLink key={document._id} className="mt-2" document={document} />
          ))}
        </>
      )}
      <Hr className="my-4" />
      <div className="flex items-center justify-between">
        <Text className="font-medium">Comments</Text>
        {!isCommenting && (
          <Button onClick={() => setIsCommenting(true)} state="text" variant="link">
            + Comment
          </Button>
        )}
      </div>

      {map(tenderUpdateComments, (tenderUpdateComment) => (
        <TenderUpdateComment
          key={tenderUpdateComment._id}
          className="mt-2"
          tenderUpdateComment={tenderUpdateComment}
          procurementId={procurement?._id}
        />
      ))}

      {isCommenting && (
        <Form className="pl-8 mt-2" onSubmit={handleSubmit}>
          <TextArea
            className="text-sm"
            value={comment}
            onChange={({ target }) => setComment(target.value)}
            placeholder="Add a comment"
            isDisabled={isSaving}
            isRequired
          />
          <div className="flex items-center justify-end mt-2">
            <Button onClick={() => setIsCommenting(false)} state="ghost" variant="link" size="xs" isDisabled={isSaving}>
              Close
            </Button>
            <Button variant="secondary" size="xs" type="submit" isLoading={isSaving}>
              Post
            </Button>
          </div>
        </Form>
      )}
    </div>
  )
})
