import { AnimatePresence } from 'framer-motion'
import React, { memo, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { find, findIndex, includes, last } from 'lodash'
import { useGetTender, useMutateTender } from '@cotiss/tender'
import { TenderCriterionReportModel } from '@cotiss/tender-criteria'
import { TenderQuestionReport, TenderQuestionReportModel } from '@cotiss/tender-question'
import {
  TenderResponseReportConsensusList,
  TenderResponseReportModel,
  TenderResponseReportView,
  useListTenderResponseReport,
} from '@cotiss/tender-response'
import {
  Banner,
  Breadcrumb,
  BreadcrumbModel,
  Button,
  ConfirmModal,
  Hr,
  RemainingTasksButton,
  Text,
  TransitionContainer,
  TransitionType,
  routerService,
  useCallout,
} from '@cotiss/common'

export const EvaluationChairConsensusStep = memo(() => {
  const { push } = useHistory()
  const { openModal } = useCallout()
  const { progressTender } = useMutateTender()
  const { tenderId } = useParams<{ tenderId: string }>()
  const { tender } = useGetTender(tenderId)
  const [transition, setTransition] = useState<TransitionType>('right')
  const { tenderResponseReports } = useListTenderResponseReport(tenderId)
  const [activeTenderResponseReportId, setActiveTenderResponseReportId] = useState('')
  const [activeTenderQuestionReportId, setActiveTenderQuestionReportId] = useState('')
  const [activeTenderCriterionReportId, setActiveTenderCriterionReportId] = useState('')
  const { activeTenderResponseReport, activeTenderCriterionReport, activeTenderQuestionReport } = useMemo(() => {
    const activeTenderResponseReport = find(tenderResponseReports, { _id: activeTenderResponseReportId })
    const activeTenderCriterionReport = find(activeTenderResponseReport?.tenderCriteria, { _id: activeTenderCriterionReportId })
    const activeTenderQuestionReport = find(activeTenderCriterionReport?.tenderQuestions, { _id: activeTenderQuestionReportId })

    return { activeTenderResponseReport, activeTenderCriterionReport, activeTenderQuestionReport }
  }, [tenderResponseReports, activeTenderResponseReportId, activeTenderQuestionReportId, activeTenderCriterionReportId])
  const isEditable = tender?.isTwoEnvelope ? tender?.status === 'consensus' : includes(['consensus', 'price'], tender?.status)
  const breadcrumbs = useMemo(() => {
    const breadcrumbs: BreadcrumbModel[] = [
      {
        label: 'Consensus scoring',
        onClick: () => handleSetActiveTenderResponseReport(null),
      },
    ]

    if (activeTenderResponseReport && !activeTenderQuestionReport) {
      breadcrumbs.push({ label: activeTenderResponseReport.procurementResponse.supplier.name })
    }

    if (activeTenderResponseReport && activeTenderQuestionReport) {
      breadcrumbs.push({ label: activeTenderResponseReport.procurementResponse.supplier.name, onClick: () => handleBackToCriterion() })
    }

    if (activeTenderQuestionReport) {
      breadcrumbs.push({ label: activeTenderQuestionReport.question })
    }

    return breadcrumbs
  }, [activeTenderResponseReport, activeTenderQuestionReport])

  const handleProgressProcurement = async () => {
    await progressTender(tenderId)
    push(routerService.getHref('/evaluation/chair/:tenderId/:step?', { tenderId, step: 'price-scoring' }))
  }

  const handleSetActiveTenderResponseReport = (tenderResponseReport: TenderResponseReportModel | null) => {
    setTransition(tenderResponseReport ? 'right' : 'left')

    setTimeout(() => {
      setActiveTenderResponseReportId(tenderResponseReport?._id || '')
    }, 0)
  }

  const handleViewTenderQuestionReport = (tenderCriterionReport: TenderCriterionReportModel, tenderQuestionReport: TenderQuestionReportModel) => {
    if (!activeTenderResponseReport) {
      return
    }

    setTransition('right')
    setTimeout(() => {
      setActiveTenderCriterionReportId(tenderCriterionReport?._id || '')
      setActiveTenderQuestionReportId(tenderQuestionReport?._id || '')
    }, 0)
  }

  const handlePreviousQuestion = () => {
    if (!activeTenderResponseReport || !activeTenderCriterionReport || !activeTenderQuestionReport) {
      return
    }

    const activeTenderQuestionReportIndex = findIndex(activeTenderCriterionReport.tenderQuestions, { _id: activeTenderQuestionReportId })
    if (activeTenderQuestionReportIndex) {
      setActiveTenderQuestionReportId(activeTenderCriterionReport.tenderQuestions[activeTenderQuestionReportIndex - 1]._id)
      return
    }

    const activeTenderCriterionReportIndex = findIndex(activeTenderResponseReport.tenderCriteria, { _id: activeTenderCriterionReportId })
    if (activeTenderCriterionReportIndex) {
      const newActiveTenderCriterionReport = activeTenderResponseReport.tenderCriteria[activeTenderCriterionReportIndex - 1]
      setActiveTenderCriterionReportId(newActiveTenderCriterionReport._id)
      setActiveTenderQuestionReportId(last(newActiveTenderCriterionReport.tenderQuestions)?._id || '')
      return
    }

    const newActiveTenderCriterionReport = last(activeTenderResponseReport.tenderCriteria)
    setActiveTenderCriterionReportId(newActiveTenderCriterionReport?._id || '')
    setActiveTenderQuestionReportId(last(newActiveTenderCriterionReport?.tenderQuestions)?._id || '')
  }

  const handleNextQuestion = () => {
    if (!activeTenderResponseReport || !activeTenderCriterionReport || !activeTenderQuestionReport) {
      return
    }

    const activeTenderQuestionReportIndex = findIndex(activeTenderCriterionReport.tenderQuestions, { _id: activeTenderQuestionReportId })
    if (activeTenderQuestionReportIndex < activeTenderCriterionReport.tenderQuestions.length - 1) {
      setActiveTenderQuestionReportId(activeTenderCriterionReport.tenderQuestions[activeTenderQuestionReportIndex + 1]._id)
      return
    }

    const activeTenderCriterionReportIndex = findIndex(activeTenderResponseReport.tenderCriteria, { _id: activeTenderCriterionReportId })
    if (activeTenderCriterionReportIndex < activeTenderResponseReport.tenderCriteria.length - 1) {
      const newActiveTenderCriterionReport = activeTenderResponseReport.tenderCriteria[activeTenderCriterionReportIndex + 1]
      setActiveTenderCriterionReportId(activeTenderResponseReport.tenderCriteria[activeTenderCriterionReportIndex + 1]._id)
      setActiveTenderQuestionReportId(newActiveTenderCriterionReport.tenderQuestions[0]._id)
      return
    }

    const newActiveTenderCriterionReport = activeTenderResponseReport.tenderCriteria[0]
    setActiveTenderCriterionReportId(newActiveTenderCriterionReport?._id || '')
    setActiveTenderQuestionReportId(newActiveTenderCriterionReport?.tenderQuestions[0]?._id || '')
  }

  const handleBackToCriterion = () => {
    setTransition('left')

    setTimeout(() => {
      setActiveTenderCriterionReportId('')
      setActiveTenderQuestionReportId('')
    }, 0)
  }

  return (
    <>
      {tender?.status === 'consensus' && (
        <Banner className="mb-6" icon="users-01" variant="secondary">
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              Complete consensus scoring
            </Text>
            <Text size="sm">Review Evaluations results and mark as complete</Text>
          </div>
          <div className="flex items-center justify-center shrink-0">
            <RemainingTasksButton errors={tender.validation.validationErrors} />
            <Button
              className="ml-2"
              onClick={() =>
                openModal(
                  <ConfirmModal
                    heading="Finalise consensus scores?"
                    description="This action cannot be undone."
                    onSubmit={handleProgressProcurement}
                  />
                )
              }
              size="sm"
              isDisabled={Boolean(tender.validation.validationErrors.length)}
            >
              Complete consensus
            </Button>
          </div>
        </Banner>
      )}

      {tender?.status !== 'consensus' && (
        <>
          <Text className="font-semibold" size="h7">
            Consensus scoring
          </Text>
          <Hr className="my-4" />
        </>
      )}
      <AnimatePresence mode="wait" initial={false}>
        {!activeTenderResponseReport && !activeTenderQuestionReport && (
          <TransitionContainer key={1} transition={transition}>
            <TenderResponseReportConsensusList tenderId={tenderId} onView={handleSetActiveTenderResponseReport} />
          </TransitionContainer>
        )}
        {activeTenderResponseReport && (
          <>
            <TransitionContainer key={2} transition={transition}>
              <Breadcrumb className="mb-2" breadcrumbs={breadcrumbs} />
              {!activeTenderQuestionReport && (
                <TenderResponseReportView tenderResponseReport={activeTenderResponseReport} onView={handleViewTenderQuestionReport} />
              )}
              {activeTenderCriterionReport && activeTenderQuestionReport && tender && (
                <TenderQuestionReport
                  tender={tender}
                  tenderResponseReport={activeTenderResponseReport}
                  tenderCriterionReport={activeTenderCriterionReport}
                  tenderQuestionReport={activeTenderQuestionReport}
                  onPreviousQuestion={handlePreviousQuestion}
                  onNextQuestion={handleNextQuestion}
                  isEditable={isEditable}
                />
              )}
            </TransitionContainer>
          </>
        )}
      </AnimatePresence>
    </>
  )
})
