import documentsPng from '@assets/images/documents-01.png'
import { Button, FourOhThreePage, Hr, Icon, Text, routerService } from '@cotiss/common'
import { DocumentViewer, useGetDocument } from '@cotiss/document'
import { useGetTender } from '@cotiss/tender'
import { TenderFlowSelectionApprovalGate, tenderFlowService } from '@cotiss/tender-flow'
import React, { memo } from 'react'
import { useParams } from 'react-router-dom'

export const TenderFlowReportStep = memo(() => {
  const { tenderId } = useParams<{ tenderId: string }>()
  const { tender, refetch: refetchTender, isFetching: isFetchingTender } = useGetTender(tenderId)
  const { document: recommendationReport } = useGetDocument(tender?.recommendationReport)
  const isOldEvaluationsEnabled = tenderFlowService.isOldEvaluationsEnabled(tender)

  if (!isOldEvaluationsEnabled) {
    return <FourOhThreePage />
  }

  if (tender?.status === 'selection-approval') {
    return <TenderFlowSelectionApprovalGate tenderId={tenderId} />
  }

  return (
    <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl px-8 py-6">
      <div className="flex items-center justify-between">
        <Text className="font-semibold" size="h5" variant="heading">
          Recommendation report
        </Text>
        {recommendationReport && (
          <div className="flex items-center ml-8">
            <Button
              isLink
              href={routerService.getHref('/document/view/:id', { id: recommendationReport._id })}
              isOpenNewTab
              variant="secondary"
              size="sm"
            >
              <Icon className="mr-2 text-white" icon="download" /> Download
            </Button>
          </div>
        )}
      </div>
      <Hr className="my-4" />
      <div className="bg-gray-100 rounded-lg w-full p-6 mr-4 max-w-5xl">
        {recommendationReport && <DocumentViewer document={recommendationReport} />}
        {!recommendationReport && (
          <div className="flex space-x-10 justify-center items-center w-full h-full">
            <img className="flex-shrink-0 scale-150" src={documentsPng} alt="Document preview not available" />
            <div>
              <Text className="font-semibold my-2">Your recommendation report is currently being generated</Text>
              <Text className="mt-1" variant="light" size="sm">
                Please refresh the page in a few minutes
              </Text>
              <Button className="mt-4" onClick={() => refetchTender()} variant="secondary" size="sm" isLoading={isFetchingTender}>
                Refresh
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
})
