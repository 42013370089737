import {
  Button,
  ConfirmModal,
  Icon,
  NoDataPlaceholder,
  ScrollableTable,
  ScrollableTableColumn,
  TabModel,
  TableHeader,
  TableRowCta,
  Tabs,
  Text,
  Tooltip_DEPRECATED,
  paginationService,
  sortService,
  useCallout,
  useSortTable,
} from '@cotiss/common'
import { METAFIELD_FIELD_TYPE_LABEL_MAP, MetafieldEntityType, useListMetafield, useMutateMetafield } from '@cotiss/metafield'
import { SettingsModulesCreateUpdateMetafieldDrawer } from '@cotiss/settings'
import { useUserAccess, userService } from '@cotiss/user'
import { map } from 'lodash'
import React, { memo, useMemo, useState } from 'react'

type MetafieldTab = 'active' | 'archived'

const METAFIELD_TABS: TabModel<MetafieldTab>[] = [
  { id: 'active', label: 'Active' },
  { id: 'archived', label: 'Archived' },
]

const METAFIELD_MAX_COUNT = 20

type SortKey = 'fieldLabel' | 'fieldType' | 'createdBy'

type Props = {
  entityType: MetafieldEntityType
}

export const SettingsModulesMetafieldsTab = memo(({ entityType }: Props) => {
  const { permissions } = useUserAccess()
  const [isSaving, setIsSaving] = useState(false)
  const { updateMetafield } = useMutateMetafield()
  const [currentPage, setCurrentPage] = useState(1)
  const [isArchived, setIsArchived] = useState(false)
  const { openModal, openNarrowDrawer } = useCallout()
  const { metafields, isLoading, count: metafieldsCount } = useListMetafield({ entityType, isArchived })
  const { sortKey, sortDirection, onSort } = useSortTable<SortKey>({ initialKey: 'fieldLabel' })
  const { processedMetafields, pagination } = useMemo(() => {
    const result = metafields.sort((a, b) => {
      if (sortKey === 'fieldLabel') {
        return sortService.sortString(a.fieldLabel, b.fieldLabel)
      } else if (sortKey === 'fieldType') {
        return sortService.sortDate(a.fieldType, b.fieldType)
      } else if (sortKey === 'createdBy') {
        return sortService.sortDate(userService.getFullName(a.createdBy), userService.getFullName(b.createdBy))
      }

      return 0
    })

    const sortedResult = sortDirection === 'asc' ? result : result.reverse()
    const { items: processedMetafields, pagination } = paginationService.paginate(sortedResult, { currentPage })

    return { processedMetafields, pagination }
  }, [metafields, sortKey, sortDirection, currentPage])

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Name',
        onSort: () => onSort('fieldLabel'),
        rows: map(processedMetafields, (metafield) => ({
          content: () => (
            <Text className="truncate" title={metafield.fieldLabel}>
              {metafield.fieldLabel}
            </Text>
          ),
          cta: (
            <TableRowCta
              cta={
                metafield.isArchived
                  ? undefined
                  : {
                      label: 'View',
                      onClick: () =>
                        openNarrowDrawer(
                          <SettingsModulesCreateUpdateMetafieldDrawer
                            entityType={entityType}
                            isEditable={permissions.isAdmin}
                            metafield={metafield}
                          />
                        ),
                    }
              }
              actions={
                permissions.isAdmin
                  ? [
                      {
                        label: metafield.isArchived ? 'Unarchive' : 'Archive',
                        onClick: () =>
                          openModal(
                            <ConfirmModal
                              heading={`${metafield.isArchived ? 'Unarchive' : 'Archive'} custom field`}
                              description={`Are you sure you want to ${metafield.isArchived ? 'unarchive' : 'archive'} this custom field?`}
                              onSubmit={async () => {
                                setIsSaving(true)
                                await updateMetafield({ id: metafield._id, body: { isArchived: !metafield.isArchived } })
                                setIsSaving(false)
                              }}
                            />
                          ),
                        isDisabled: isSaving,
                      },
                    ]
                  : undefined
              }
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Field type',
        onSort: () => onSort('fieldType'),
        rows: map(processedMetafields, (metafield) => ({
          content: () => (
            <Text className="whitespace-pre-wrap" size="sm">
              {METAFIELD_FIELD_TYPE_LABEL_MAP[metafield.fieldType]}
            </Text>
          ),
        })),
      },
      {
        heading: 'Created by',
        onSort: () => onSort('createdBy'),
        rows: map(processedMetafields, (metafield) => ({
          content: () => (
            <Text className="whitespace-pre-wrap" size="sm">
              {userService.getFullName(metafield.createdBy)}
            </Text>
          ),
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [processedMetafields, permissions])

  const renderCta = () => {
    if (!permissions.isAdmin || isArchived) {
      return null
    }

    const isMetafieldLimitReached = metafieldsCount > METAFIELD_MAX_COUNT
    return (
      <Tooltip_DEPRECATED tooltip="You have reached the limit of 20 custom fields per module." isEnabled={isMetafieldLimitReached}>
        <Button
          onClick={() => openNarrowDrawer(<SettingsModulesCreateUpdateMetafieldDrawer entityType={entityType} isEditable />)}
          variant="secondary"
          size="xs"
          isDisabled={isMetafieldLimitReached}
        >
          <Icon icon="plus-01" /> Add custom field
        </Button>
      </Tooltip_DEPRECATED>
    )
  }

  return (
    <>
      <TableHeader className="flex justify-between items-center">
        <Text className="font-semibold">Custom fields</Text>
        {renderCta()}
      </TableHeader>
      <div className="bg-white p-4 border-gray-200 border-x border-t">
        <Tabs<MetafieldTab> tab={isArchived ? 'archived' : 'active'} tabs={METAFIELD_TABS} onChange={({ id }) => setIsArchived(id === 'archived')} />
      </div>
      {!isLoading && !metafields.length && (
        <div className="flex items-center justify-center h-60 bg-gray-200 rounded">
          <NoDataPlaceholder
            illustration="dot-list"
            variant="transparent"
            label={
              isArchived
                ? 'No archived custom fields'
                : "You haven't created any custom fields yet. Once created, you can track your custom fields here"
            }
            ctaSize="xs"
            ctaLabel="+ Create custom field"
            onCtaClick={
              permissions.isAdmin && !isArchived
                ? () => openNarrowDrawer(<SettingsModulesCreateUpdateMetafieldDrawer entityType={entityType} isEditable={permissions.isAdmin} />)
                : undefined
            }
          />
        </div>
      )}
      {(Boolean(metafields.length) || isLoading) && (
        <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
      )}
    </>
  )
})
