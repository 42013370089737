import { FilterAllOperations } from '@cotiss/common/models/filter.model'

export const FILTER_OPERATION_NAMES: Record<FilterAllOperations, string> = {
  IS_ANY_OF: 'Includes',
  IS_NONE_OF: 'Excludes',
  CONTAINS_EXACTLY: 'Includes',
  DOES_NOT_CONTAIN_EXACTLY: 'Excludes',
  IS_TRUE: 'Is true',
  IS_FALSE: 'Is false',
  IS_BEFORE: 'Is before',
  IS_AFTER: 'Is after',
  IS_GREATER_THAN: 'Is greater than',
  IS_LESS_THAN: 'Is less than',
  IS_EQUAL_TO: 'Is equal to',
}
