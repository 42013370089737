import { map } from 'lodash'
import React, { memo, useMemo } from 'react'
import { useGetTender } from '@cotiss/tender'
import { tenderFlowService } from '@cotiss/tender-flow'
import {
  Text,
  sortService,
  useSortTable,
  datetimeService,
  ScrollableTableColumn,
  ScrollableTable,
  TableHeader,
  Button,
  useCallout,
  Icon,
} from '@cotiss/common'
import {
  TenderResponsePopulatedModel,
  TenderResponseReviewStatusBadge,
  useListTenderResponse,
  TenderResponseReviewListCta,
  TenderResponseSubmissionCreateDrawer,
} from '@cotiss/tender-response'

type ReviewTableSortKey = 'name' | 'updatedAt' | 'reviewStatus'

type Props = {
  className?: string
  tenderId: string
  onView: (tenderResponse: TenderResponsePopulatedModel) => void
}

export const TenderResponseReviewList = memo(({ className, tenderId, onView }: Props) => {
  const { openDrawer } = useCallout()
  const { tender } = useGetTender(tenderId)
  const { tenderResponses, isLoading } = useListTenderResponse({ tenderId, status: 'submitted' })
  const { sortKey, sortDirection, onSort } = useSortTable<ReviewTableSortKey>({ initialKey: 'name' })

  const sortedTenderResponses = useMemo(() => {
    const result = tenderResponses.sort((a, b) => {
      if (sortKey === 'name') {
        return sortService.sortString(a.procurementResponse.supplier.name, b.procurementResponse.supplier.name)
      } else if (sortKey === 'updatedAt') {
        return sortService.sortDate(a.updatedAt, b.updatedAt)
      } else if (sortKey === 'reviewStatus') {
        return sortService.sortString(a.reviewStatus, b.reviewStatus)
      }

      return 0
    })

    return sortDirection === 'asc' ? result : result.reverse()
  }, [tenderResponses, sortKey, sortDirection])

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Contact',
      onSort: () => onSort('name'),
      rows: map(sortedTenderResponses, (tenderResponse) => ({
        content: () => (
          <Text className="truncate" title={tenderResponse.procurementResponse.supplier.name}>
            {tenderResponse.procurementResponse.supplier.name}
          </Text>
        ),
        cta: <TenderResponseReviewListCta tenderResponse={tenderResponse} onView={onView} />,
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Review status',
      onSort: () => onSort('reviewStatus'),
      rows: map(sortedTenderResponses, (tenderResponse) => ({
        content: () => <TenderResponseReviewStatusBadge status={tenderResponse.reviewStatus} />,
      })),
    },
    {
      heading: 'Submitted by',
      rows: map(sortedTenderResponses, (tenderResponse) => ({
        content: () => <Text>{tenderResponse?.isBuyerSubmission ? 'Buyer' : 'Vendor'}</Text>,
      })),
    },
    {
      heading: 'Date submitted',
      onSort: () => onSort('updatedAt'),
      rows: map(sortedTenderResponses, (tenderResponse) => ({
        content: () => <Text>{datetimeService.format(tenderResponse.updatedAt, 'do MMM yyyy')}</Text>,
      })),
    },
  ]

  return (
    <div className={className}>
      <TableHeader className="flex items-center justify-between" variant="white">
        <Text className="font-medium" size="lg">
          Submissions
        </Text>
        {tenderFlowService.getActiveStep(tender) !== 'shortlist' && (
          <Button
            onClick={() => openDrawer(<TenderResponseSubmissionCreateDrawer tenderId={tenderId} />)}
            size="xs"
            variant="secondary"
            state="translucent"
            isDisabled={isLoading}
          >
            <Icon className="mr-1" icon="plus-01" variant="secondary" /> Add submission
          </Button>
        )}
      </TableHeader>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} isLoading={isLoading} />
    </div>
  )
})
