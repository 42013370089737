import React, { memo, useState } from 'react'
import { EvaluationEventSummaryCriteria, useEvaluationCriteria, useEvaluationEvent } from '@cotiss/evaluation-event'
import { Card, CardHeader, ErrorPanel, Skeleton, ScrollableTable, TableHeader, Text, sentryService, useAsyncEffect } from '@cotiss/common'

export const EvaluationEventViewDetailsCriteriaTab = memo(() => {
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { evaluationEvent } = useEvaluationEvent()
  const { queryEvaluationCriteriaList } = useEvaluationCriteria()

  useAsyncEffect(async () => {
    if (!evaluationEvent) {
      return
    }

    try {
      // TODO: We may have to think about paginating criteria.
      await queryEvaluationCriteriaList({ filter: { evaluationEventId: evaluationEvent.id } })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [])

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <Text className="font-semibold" variant="heading" size="h5">
          Criteria & sub-criteria
        </Text>
      </CardHeader>
      {isLoading && (
        <>
          <TableHeader>
            <Skeleton className="h-3 w-1/3" />
          </TableHeader>
          <ScrollableTable
            fixedColumns={[
              {
                heading: 'Sub-criteria',
                rows: [],
              },
            ]}
            columns={[
              {
                heading: 'Rating scale',
                rows: [],
              },
            ]}
            isLoading
          />
        </>
      )}
      {!isLoading && isError && <ErrorPanel />}
      {!isLoading && !isError && <EvaluationEventSummaryCriteria />}
    </Card>
  )
})
