import classNames from 'classnames'
import { head, map } from 'lodash'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { TenderPriceTableButton, useGetTender } from '@cotiss/tender'
import { EvaluationNonPriceDrawer, EvaluationStatusBadge, useGetEvaluation } from '@cotiss/evaluation'
import { DocumentButton, DocumentButtonSkeleton, DocumentModel, DocumentViewer } from '@cotiss/document'
import { Breadcrumb, BreadcrumbModel, Button, Hr, Skeleton, Text, sentryService, useCallout, useToast } from '@cotiss/common'
import {
  TenderResponsePriceDrawer,
  TenderResponsePriceScoreConsensusStatusBadge,
  TenderResponsePriceTable,
  useGetTenderResponse,
  useMutateTenderResponse,
} from '@cotiss/tender-response'

type ActiveEntity =
  | {
      type: 'document'
      document: DocumentModel
    }
  | {
      type: 'pricing-table'
    }

type Props = {
  evaluationId: string
  variant?: 'non-price' | 'price'
  breadcrumbs?: BreadcrumbModel[]
  isEditable?: boolean
  isPriceVisible?: boolean
}

export const EvaluationView = memo(({ evaluationId, variant = 'non-price', breadcrumbs: rootBreadcrumbs, isEditable, isPriceVisible }: Props) => {
  const { openToast } = useToast()
  const { openNarrowDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { updateTenderResponse } = useMutateTenderResponse()
  const { evaluation, isLoading: isEvaluationLoading } = useGetEvaluation(evaluationId)
  const { tender, isLoading: isTenderLoading } = useGetTender(evaluation?.tender._id)
  const { tenderResponse, isLoading: isTenderResponseLoading } = useGetTenderResponse(evaluation?.tenderResponse)
  const isLoading = isEvaluationLoading || isTenderLoading || isTenderResponseLoading
  const [activeEntity, setActiveEntity] = useState<ActiveEntity | null>(null)
  const breadcrumbs: BreadcrumbModel[] = useMemo(() => {
    return rootBreadcrumbs ? [...rootBreadcrumbs, { label: tenderResponse?.procurementResponse.supplier.name || '', isLoading: !tenderResponse }] : []
  }, [rootBreadcrumbs, tenderResponse])

  useEffect(() => {
    if (!tenderResponse || activeEntity) {
      return
    }

    // Selecting the first document in the list.
    const nonPriceDocument = head(tenderResponse.nonPriceAttachments) || head(tenderResponse.nonPriceAttachmentsClarification)

    if (nonPriceDocument) {
      setActiveEntity({ type: 'document', document: nonPriceDocument })
      return
    }

    if (isPriceVisible && !nonPriceDocument) {
      const priceDocument = head(tenderResponse.priceAttachments) || head(tenderResponse.priceAttachmentsClarification)

      if (priceDocument) {
        setActiveEntity({ type: 'document', document: priceDocument })
        return
      }
    }
  }, [tenderResponse])

  const renderCta = () => {
    if (variant === 'non-price' && tender && tenderResponse) {
      const handleClick = () => {
        openNarrowDrawer(<EvaluationNonPriceDrawer evaluationId={evaluationId} isEditable={isEditable && tender.status === 'evaluate'} />)
      }

      return (
        <Button onClick={handleClick} size="sm">
          {evaluation?.status === 'abstained'
            ? 'View criteria'
            : !isEditable || evaluation?.status === 'completed'
            ? 'View scores'
            : 'Score criteria'}
        </Button>
      )
    }

    if (variant === 'price' && tenderResponse && tender) {
      if (tender.isPriceWeighted) {
        const handleClick = () => {
          openNarrowDrawer(<TenderResponsePriceDrawer tenderId={tender._id} tenderResponseId={tenderResponse._id} isEditable={isEditable} />)
        }

        return (
          <Button onClick={handleClick} size="sm">
            {!isEditable || tenderResponse?.priceScoreConsensusStatus === 'completed' ? 'View score' : 'Score price'}
          </Button>
        )
      }

      if (isEditable) {
        const isComplete = tenderResponse.priceScoreConsensusStatus === 'completed'
        const handleClick = async () => {
          try {
            setIsSaving(true)
            await updateTenderResponse(tenderResponse._id, { priceScoreConsensusStatus: isComplete ? 'notStarted' : 'completed' })
            setIsSaving(false)
          } catch (error: any) {
            sentryService.captureException({ exception: error })
            openToast(error.message, 'danger')
            setIsSaving(false)
          }
        }

        return (
          <Button
            onClick={handleClick}
            size="sm"
            variant={isComplete ? 'secondary' : 'primary'}
            state={isComplete ? 'translucent' : 'filled'}
            isLoading={isSaving}
          >
            {isComplete ? 'Edit' : 'Mark as complete'}
          </Button>
        )
      }
    }
  }
  return (
    <>
      <Breadcrumb className="mb-2" breadcrumbs={breadcrumbs} />
      <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl px-8 py-6">
        <div className="flex items-center justify-between">
          <div className="w-full mr-4">
            <Text variant="light" size="sm">
              {variant === 'non-price' ? 'Non-Price Evaluation' : 'Price Evaluation'}
            </Text>
            {isLoading && <Skeleton className="h-3 w-1/3 mt-1" />}
            {!isLoading && <Text className="font-semibold">{tenderResponse?.procurementResponse.supplier.name}</Text>}
          </div>

          <div className="flex items-center shrink-0 ml-2">
            {evaluation && variant === 'non-price' && (
              <EvaluationStatusBadge className="shrink-0 whitespace-nowrap mr-2" status={evaluation.status} size="md" />
            )}
            {tenderResponse?.priceScoreConsensusStatus && variant === 'price' && (
              <TenderResponsePriceScoreConsensusStatusBadge
                className="shrink-0 whitespace-nowrap mr-2"
                status={tenderResponse.priceScoreConsensusStatus}
                size="md"
              />
            )}
            {renderCta()}
          </div>
        </div>
        <Hr className="my-4" />
        <div className="flex">
          {(!activeEntity || activeEntity.type === 'document') && (
            <div className="bg-gray-100 rounded-lg w-full p-6 mr-4 max-h-[calc(100vh-260px)] aspect-[1/1.414]">
              {activeEntity?.type === 'document' && <DocumentViewer document={activeEntity.document} />}
            </div>
          )}
          {evaluation && activeEntity?.type === 'pricing-table' && (
            <TenderResponsePriceTable
              className="bg-white min-w-0 w-full mr-12"
              tenderResponseId={evaluation.tenderResponse}
              isNpvVisible={tender?.priceTableRequirePaymentDate}
            />
          )}
          <div className="shrink-0 w-[180px]">
            {variant === 'non-price' && (
              <>
                <Text className="uppercase" variant="light" size="xs">
                  Non-price documents{Boolean(tenderResponse?.nonPriceAttachments.length) && ` (${tenderResponse?.nonPriceAttachments.length})`}
                </Text>
                {isLoading && (
                  <>
                    <DocumentButtonSkeleton className="mt-2" />
                    <DocumentButtonSkeleton className="mt-2" />
                  </>
                )}
                {!isLoading && (
                  <>
                    {map(tenderResponse?.nonPriceAttachments, (document) => (
                      <DocumentButton
                        key={document._id}
                        className="mt-2"
                        document={document}
                        onClick={() => setActiveEntity({ type: 'document', document })}
                        isActive={activeEntity?.type === 'document' && activeEntity.document?._id === document._id}
                      />
                    ))}
                    {Boolean(tenderResponse?.nonPriceAttachmentsClarification.length) && (
                      <>
                        <Text className="uppercase mt-8" variant="light" size="xs">
                          Non-price clarification documents
                          {Boolean(tenderResponse?.nonPriceAttachmentsClarification.length) &&
                            ` (${tenderResponse?.nonPriceAttachmentsClarification.length})`}
                        </Text>
                        {map(tenderResponse?.nonPriceAttachmentsClarification, (document) => (
                          <DocumentButton
                            key={document._id}
                            className="mt-2"
                            document={document}
                            onClick={() => setActiveEntity({ type: 'document', document })}
                            isActive={activeEntity?.type === 'document' && activeEntity.document?._id === document._id}
                          />
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {(variant === 'price' || isPriceVisible) && (
              <>
                <Text className={classNames('uppercase', { 'mt-8': variant === 'non-price' })} variant="light" size="xs">
                  Price documents{Boolean(tenderResponse?.priceAttachments.length) && ` (${tenderResponse?.priceAttachments.length})`}
                </Text>
                {isLoading && (
                  <>
                    <DocumentButtonSkeleton className="mt-2" />
                    <DocumentButtonSkeleton className="mt-2" />
                  </>
                )}
                {!isLoading && (
                  <>
                    {tender?.priceTableEnabled && (
                      <TenderPriceTableButton
                        className="mt-2"
                        onClick={() => setActiveEntity({ type: 'pricing-table' })}
                        isActive={activeEntity?.type === 'pricing-table'}
                      />
                    )}
                    {map(tenderResponse?.priceAttachments, (document) => (
                      <DocumentButton
                        key={document._id}
                        className="mt-2"
                        document={document}
                        onClick={() => setActiveEntity({ type: 'document', document })}
                        isActive={activeEntity?.type === 'document' && activeEntity.document?._id === document._id}
                      />
                    ))}
                    {Boolean(tenderResponse?.priceAttachmentsClarification.length) && (
                      <>
                        <Text className="uppercase mt-8" variant="light" size="xs">
                          Price clarification documents
                          {Boolean(tenderResponse?.priceAttachmentsClarification.length) &&
                            ` (${tenderResponse?.priceAttachmentsClarification.length})`}
                        </Text>
                        {map(tenderResponse?.priceAttachmentsClarification, (document) => (
                          <DocumentButton
                            key={document._id}
                            className="mt-2"
                            document={document}
                            onClick={() => setActiveEntity({ type: 'document', document })}
                            isActive={activeEntity?.type === 'document' && activeEntity.document?._id === document._id}
                          />
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
})
