import { find } from 'lodash'
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import ReactSelect, { components, ClearIndicatorProps, DropdownIndicatorProps, ControlProps, OptionProps } from 'react-select'
import { Icon, Text } from '@cotiss/common'

export type SelectOption<T extends string = string> = {
  label: string
  value: T
  isDisabled?: boolean
}

type GetSelectClassNamesConfigProps = {
  isError?: boolean
  isDisabled?: boolean
}

type Props<T extends string> = {
  className?: string
  value?: T | null
  options: SelectOption<T>[]
  onChange: (value: T) => void
  onCreate?: (valueToCreate: string) => void
  placeholder?: string
  isError?: boolean
  isRequired?: boolean
  isDisabled?: boolean
  renderInPortal?: boolean
  menuPlacement?: 'auto' | 'bottom' | 'top'
}

export function Select<T extends string = string>(props: Props<T>) {
  const {
    className,
    value,
    options,
    onChange,
    menuPlacement = 'auto',
    onCreate,
    placeholder = '- Select an option -',
    isRequired,
    isDisabled,
    renderInPortal,
  } = props
  const [inputValue, setInputValue] = useState('')
  const { processedValue, processedOptions } = useMemo(() => {
    const processedValue = find(options, (option) => option.value === value)
    const processedOptions =
      onCreate && !inputValue
        ? [...options, { label: '+ Type to create', value: '' as T, isDisabled: true, data: { isCreatePlaceholder: true } }]
        : options

    return { processedValue, processedOptions }
  }, [value, inputValue, options, onCreate])

  if (onCreate) {
    const handleCreate = (value: string) => {
      onChange(value as T)
      onCreate(value)
    }

    return (
      <CreatableSelect
        menuPortalTarget={renderInPortal ? document.body : null}
        menuPlacement={menuPlacement}
        className={className}
        value={processedValue}
        classNames={getSelectClassNamesConfig(props) as any}
        components={{ ClearIndicator: SelectClearIndicator, DropdownIndicator: SelectDropdownIndicator }}
        options={[{ label: 'Select or create', options: processedOptions }]}
        getOptionLabel={(option) => option.label}
        onChange={(selectedOption) => onChange(selectedOption?.value || ('' as T))}
        onInputChange={setInputValue}
        onCreateOption={handleCreate}
        formatCreateLabel={(inputValue) => (
          <Text className="!text-base" variant="link">
            + Create &quot;{inputValue}&quot;
          </Text>
        )}
        formatGroupLabel={(groupLabel) => (
          <Text className="border-b border-gray-200 pb-2 px-2 mb-2" size="sm" variant="light">
            {groupLabel.label}
          </Text>
        )}
        placeholder={placeholder}
        required={isRequired}
        isClearable={!isRequired}
        isDisabled={isDisabled}
        isMulti={false}
      />
    )
  }

  return (
    <ReactSelect
      menuPortalTarget={renderInPortal ? document.body : null}
      menuPlacement={menuPlacement}
      className={className}
      value={processedValue}
      classNames={getSelectClassNamesConfig(props) as any}
      components={{ ClearIndicator: SelectClearIndicator, DropdownIndicator: SelectDropdownIndicator }}
      options={processedOptions}
      getOptionLabel={(option) => option.label}
      onChange={(selectedOption) => onChange(selectedOption?.value || ('' as T))}
      placeholder={placeholder}
      required={isRequired}
      isClearable={!isRequired}
      isDisabled={isDisabled}
      isMulti={false}
    />
  )
}

export const getSelectClassNamesConfig = ({ isError, isDisabled }: GetSelectClassNamesConfigProps) => ({
  control: ({ isFocused }: ControlProps) => {
    return classNames('!border !rounded-lg !py-[5px]', {
      '!border-gray-300': !isError && !isFocused,
      '!border-secondary-300 !ring-2 !ring-secondary-200': !isError && isFocused,
      '!border-red-400': isError,
      '!ring-2 !ring-red-200': isError && isFocused,
      '!bg-white': !isDisabled,
      '!bg-gray-50': isDisabled,
    })
  },
  indicatorSeparator: () => '!hidden',
  placeholder: () => '!text-base !text-gray-400',
  option: (state: OptionProps) => {
    const data: any = state.data
    const isNotClickable = state.isDisabled || data.isCreatePlaceholder

    return classNames('!font-normal !text-base !rounded !px-2 !py-1.5', {
      '!bg-gray-50': !isNotClickable && state.isFocused && !state.isSelected,
      '!bg-gray-200 !text-primary-500': !isNotClickable && state.isSelected,
      '!cursor-pointer': !isNotClickable,
      '!bg-transparent !text-light': isNotClickable,
    })
  },
  valueContainer: () => '!text-base !text-primary-500 !py-0',
  menu: () => '!border !border-gray-100 !rounded-lg !shadow-lg',
  menuPortal: () => '!z-dropdown',
  menuList: () => '!space-y-1 !p-2',
  groupHeading: () => '!p-0',
})

export function SelectClearIndicator<T>(props: ClearIndicatorProps<T>) {
  return (
    <components.ClearIndicator {...props} className="!cursor-pointer">
      <Icon icon="x-close" variant="light" />
    </components.ClearIndicator>
  )
}

export function SelectDropdownIndicator<T>(props: DropdownIndicatorProps<T>) {
  return (
    <components.DropdownIndicator {...props} className="!cursor-pointer">
      <Icon icon="chevron-down" variant="light" />
    </components.DropdownIndicator>
  )
}
