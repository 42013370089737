import { useHistory } from 'react-router-dom'
import React, { FormEvent, memo, useState } from 'react'
import { useMutateAccount } from '@cotiss/account'
import { Button, Form, Icon, ModalConfirmationButton, ModalContent, Text, routerService, sentryService, useCallout, useToast } from '@cotiss/common'

type Props = {
  isAdmin?: boolean
}

export const AccountDeleteConfirmModal = memo(({ isAdmin }: Props) => {
  const { push } = useHistory()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { deleteAccount } = useMutateAccount()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await deleteAccount()
      push(routerService.getHref('/logout'))
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="relative min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalContent>
        <Button className="absolute top-0 right-0" onClick={closeModal} state="ghost" shape="square" isDisabled={isSaving}>
          <Icon icon="x-close" />
        </Button>

        <Text className="font-semibold" size="h5">
          Delete account
        </Text>

        <Text className="mt-4">Are you sure you want to deactivate this account?</Text>
        <Text className="mt-2" variant="light">
          {isAdmin && 'This will also deactivate every account in your organisation.'}
          {!isAdmin && 'This action is irreversible and will log you out of the system.'}
        </Text>
      </ModalContent>
      <ModalConfirmationButton type="submit" isLoading={isSaving}>
        Deactivate account
      </ModalConfirmationButton>
    </Form>
  )
})
