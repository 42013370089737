import { map } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { GqlPagination } from '@gql'
import { usePlanEvent } from '@cotiss/plan-event'
import {
  ConfirmModal,
  ErrorPanel,
  Icon,
  NoDataPlaceholder,
  Table,
  TableColumn,
  TableRowCta,
  Text,
  routerService,
  sentryService,
  useAnalytics,
  useAsyncEffect,
  useCallout,
} from '@cotiss/common'

type Props = {
  isArchived?: boolean
}

export const PlanEventList = memo(({ isArchived = false }: Props) => {
  const { track } = useAnalytics()
  const { openModal } = useCallout()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<GqlPagination>()
  const { planEvents, setPlanEvent, queryPlanEventList, mutateUpdatePlanEvent } = usePlanEvent()

  const handleQueryPlanEventList = async () => {
    try {
      setPlanEvent(null)

      const { pagination } = await queryPlanEventList({
        filter: {
          isArchived,
        },
        pagination: {
          page: currentPage,
          pageSize: 100,
        },
      })

      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    track(isArchived ? 'plan_event_list_archived_view' : 'plan_event_list_active_view')
  }, [isArchived])

  useAsyncEffect(async () => {
    handleQueryPlanEventList()
  }, [currentPage])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  const handleArchive = async (planEventId: string, isArchived: boolean) => {
    track(isArchived ? 'plan_event_list_archive_submit' : 'plan_event_list_unarchive_submit')

    await mutateUpdatePlanEvent({ planEventId, isArchived })
    await handleQueryPlanEventList()
  }

  if (!planEvents.length && !isLoading) {
    if (isArchived) {
      return <NoDataPlaceholder label="You have not been added to any archived plan events." />
    }

    return <NoDataPlaceholder label="You have not been added to any plan events yet." />
  }

  const columns: TableColumn[] = [
    {
      heading: 'Plan event',
      rows: map(planEvents, (planEvent) => ({
        content: () => (
          <Text className="font-medium truncate" title={planEvent.name}>
            {planEvent.name}
          </Text>
        ),
        cta: (
          <TableRowCta
            cta={
              !isArchived
                ? {
                    href: routerService.getHref('/plan-event/view/:planEventId/:tab?/:nestedTab?', {
                      planEventId: planEvent.id,
                    }),
                    label: (
                      <>
                        View <Icon className="ml-1" icon="arrow-right" />
                      </>
                    ),
                  }
                : undefined
            }
            actions={[
              {
                onClick: () =>
                  openModal(
                    <ConfirmModal
                      heading={planEvent.isArchived ? 'Unarchive' : 'Archive'}
                      description={`Are you sure you want to ${planEvent.isArchived ? 'unarchive' : 'archive'} this plan event?`}
                      onSubmit={() => handleArchive(planEvent.id, !planEvent.isArchived)}
                    />
                  ),
                label: planEvent.isArchived ? 'Unarchive' : 'Archive',
              },
            ]}
          />
        ),
      })),
    },
  ]

  return <Table columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
})
