import { filter, find, forEach, groupBy, map } from 'lodash'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useGetTender } from '@cotiss/tender'
import { useListTenderCriteria } from '@cotiss/tender-criteria'
import { useListTenderQuestion, useListTenderQuestionScore } from '@cotiss/tender-question'
import { EvaluationCriteriaAccordion, EvaluationCriteriaScoreFormDataItem, useGetEvaluation, useMutateEvaluation } from '@cotiss/evaluation'
import {
  AccordionSkeleton,
  Button,
  ConfirmModal,
  Icon,
  NumberAnimation,
  ProgressBar,
  Banner,
  Switch,
  Text,
  sentryService,
  useToast,
  Drawer,
  useCallout,
} from '@cotiss/common'

type Props = {
  evaluationId: string
  isEditable?: boolean
}

export const EvaluationNonPriceDrawer = memo(({ evaluationId, isEditable }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { updateEvaluation } = useMutateEvaluation()
  const { openModal, closeNarrowDrawer } = useCallout()
  const [isUpdatable, setIsUpdatable] = useState(false)
  const [isAbstained, setIsAbstained] = useState(false)
  const { evaluation, isLoading: isEvaluationLoading } = useGetEvaluation(evaluationId)
  const { tender, isLoading: isTenderLoading } = useGetTender(evaluation?.tender._id)
  const { tenderQuestionScores, isLoading: isTenderQuestionScoresLoading } = useListTenderQuestionScore({ evaluationId })
  const { tenderCriteria, isLoading: isTenderCriteriaLoading } = useListTenderCriteria({ tenderId: evaluation?.tender._id })
  const { tenderQuestions, isLoading: isTenderQuestionsLoading } = useListTenderQuestion({ tenderId: evaluation?.tender._id })

  const [formData, setFormData] = useState<Record<string, EvaluationCriteriaScoreFormDataItem[]>>({})
  const { percentageComplete, isComplete } = useMemo(() => {
    const scoreCount = filter(tenderQuestionScores, ({ score }) => score !== null).length
    const percentageComplete = (scoreCount / tenderQuestionScores.length) * 100
    const isComplete = scoreCount === tenderQuestionScores.length

    return { percentageComplete, isComplete }
  }, [tenderQuestionScores])

  const isLoading = isEvaluationLoading || isTenderLoading || isTenderQuestionScoresLoading || isTenderCriteriaLoading || isTenderQuestionsLoading
  const isSelfEvaluation = tender?.evaluationPanelType === 'self'

  useEffect(() => {
    if (tenderCriteria.length && tenderQuestions.length) {
      processAndSetFormData()
    }
  }, [tenderCriteria, tenderQuestions, tenderQuestionScores])

  const handleMarkAsComplete = async () => {
    await updateEvaluation(evaluationId, { status: 'completed' })
    closeNarrowDrawer()
  }

  const handleToggleAbstain = async () => {
    try {
      setIsSaving(true)
      setIsUpdatable(isAbstained)
      setIsAbstained(!isAbstained)
      await updateEvaluation(evaluationId, { status: isAbstained ? 'inProgress' : 'abstained' })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
      setIsUpdatable(!isAbstained)
      setIsAbstained((prev) => !prev)
    }
  }

  const handleEdit = async () => {
    try {
      setIsSaving(true)
      await updateEvaluation(evaluationId, { status: 'inProgress' })
      setIsUpdatable(true)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const processAndSetFormData = () => {
    if (!evaluation) {
      return
    }

    const formData: Record<string, EvaluationCriteriaScoreFormDataItem[]> = {}
    const formDataMap = groupBy(
      map(tenderQuestions, ({ _id, criterion, question }) => {
        const tenderQuestionScore = find(tenderQuestionScores, { tenderQuestion: _id })

        return {
          criterionId: criterion,
          questionId: _id,
          tenderQuestionScoreId: tenderQuestionScore?._id || '',
          question,
          score: tenderQuestionScore?.score?.toString() || '',
          commentary: tenderQuestionScore?.commentary || '',
        }
      }),
      'criterionId'
    )

    forEach(tenderCriteria, ({ _id }) => {
      formData[_id] = formDataMap[_id] || []
    })

    setIsAbstained(evaluation.status === 'abstained')
    setIsUpdatable(evaluation.status !== 'completed' && evaluation.status !== 'abstained')
    setFormData(formData)
  }

  const renderFooter = () => {
    if (!evaluation || isLoading || isAbstained) {
      return null
    }

    if (evaluation.status === 'completed') {
      return (
        <>
          <div className="flex items-center mr-4">
            <Icon className="mr-1" icon="check-circle" variant="success" />
            <Text className="font-semibold" isInline>
              Marked as complete
            </Text>
          </div>
          {isEditable && (
            <Button onClick={handleEdit} state="outline" size="sm" isLoading={isSaving}>
              Edit
            </Button>
          )}
        </>
      )
    }

    return (
      <>
        <div className="w-full mr-4">
          <Text className="font-semibold" isInline>
            Scoring progress
          </Text>
          <Text className="font-medium ml-1" variant="secondary" size="sm" isInline>
            (<NumberAnimation value={percentageComplete || 0} format={(value) => `${value.toFixed(0) || 0}%`} />)
          </Text>
          <ProgressBar
            className="mt-2"
            total={tenderQuestionScores.length}
            completed={filter(tenderQuestionScores, ({ score }) => score !== null).length}
          />
        </div>
        <Button
          onClick={() =>
            openModal(
              <ConfirmModal
                heading="Are you sure you want to complete this evaluation?"
                description="You can still make changes before consensus scoring."
                onSubmit={handleMarkAsComplete}
              />
            )
          }
          size="sm"
          isDisabled={!isComplete || isSaving}
        >
          Mark as complete
        </Button>
      </>
    )
  }

  const renderHeader = () => (
    <div className="flex items-center justify-between">
      <Text className="font-semibold" size="h5" variant="heading">
        Score criteria
      </Text>
      {isEditable && !isSelfEvaluation && (
        <div className="flex items-center space-x-2 ml-4">
          <Switch size="sm" isOn={isAbstained} onClick={handleToggleAbstain} isDisabled={!isEditable || isSaving} />
          <Text size="sm">Abstain from scoring</Text>
        </div>
      )}
    </div>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} isNarrow>
      {isLoading && (
        <>
          <AccordionSkeleton />
          <AccordionSkeleton className="mt-8" />
          <AccordionSkeleton className="mt-8" />
        </>
      )}
      {!isLoading && evaluation && tender && (
        <>
          {isAbstained && (
            <Banner className="mb-6" variant="neutral" icon="lock-01" iconVariant="secondary">
              <div className="mr-6">
                <Text className="font-semibold" variant="heading">
                  Scoring locked
                </Text>
                <Text size="sm">You have selected to abstain from scoring</Text>
              </div>
            </Banner>
          )}
          {map(tenderCriteria, (tenderCriterion, index) => (
            <EvaluationCriteriaAccordion
              key={tenderCriterion._id}
              className={index ? 'mt-8' : ''}
              tender={tender}
              evaluation={evaluation}
              tenderCriterion={tenderCriterion}
              evaluationCriteriaScores={formData[tenderCriterion._id] || []}
              isEditable={isEditable && isUpdatable}
            />
          ))}
        </>
      )}
    </Drawer>
  )
})
