import { createEmptyFilter, isFilterDirty } from '@cotiss/common/modals/advanced-filters/advanced-filters.utils'
import { Filter, FilterFieldOptions } from '@cotiss/common/models/filter.model'

export type PartialFilter = Partial<Filter> & { id: string }

type AddFilterAction = {
  type: 'ADD_FILTER'
}

type UpdateFilterFieldAction = {
  type: 'UPDATE_FILTER_FIELD'
  payload: {
    id: string
    field: Filter['field']
    filterFields: FilterFieldOptions
  }
}

type UpdateFilterOperationAction = {
  type: 'UPDATE_FILTER_OPERATION'
  payload: {
    id: string
    operation: Filter['operation']
  }
}

type UpdateFilterValueAction = {
  type: 'UPDATE_FILTER_VALUE'
  payload: {
    id: string
    value: Filter['value']
  }
}

type RemoveFilterAction = {
  type: 'REMOVE_FILTER'
  payload: string
}

type ClearAllFiltersAction = {
  type: 'CLEAR_ALL_FILTERS'
}

type SetFilterFieldsAction = {
  type: 'SET_FILTER_FIELDS'
  payload: FilterFieldOptions
}

type ClearEmptyFiltersAction = {
  type: 'CLEAR_EMPTY_FILTERS'
}

export type AdvancedFiltersAction =
  | AddFilterAction
  | UpdateFilterFieldAction
  | UpdateFilterOperationAction
  | UpdateFilterValueAction
  | RemoveFilterAction
  | ClearAllFiltersAction
  | SetFilterFieldsAction
  | ClearEmptyFiltersAction

type ReducerState = {
  advancedFilters: PartialFilter[]
  filterFields: FilterFieldOptions
  isInitialized: boolean
}

export const advancedFiltersReducer = (state: ReducerState, action: AdvancedFiltersAction): ReducerState => {
  switch (action.type) {
    case 'ADD_FILTER':
      return {
        ...state,
        advancedFilters: [...state.advancedFilters, createEmptyFilter()],
      }

    case 'SET_FILTER_FIELDS':
      return {
        ...state,
        filterFields: action.payload,
        isInitialized: true,
      }

    case 'UPDATE_FILTER_FIELD':
      return {
        ...state,
        advancedFilters: state.advancedFilters.map((filter) =>
          filter.id === action.payload.id
            ? {
                ...filter,
                field: action.payload.field,
                operation: state.filterFields[action.payload.field]?.supportedOperations[0],
                value: '',
              }
            : filter
        ),
      }

    case 'UPDATE_FILTER_OPERATION':
      return {
        ...state,
        advancedFilters: state.advancedFilters.map((filter) =>
          filter.id === action.payload.id ? { ...filter, operation: action.payload.operation, value: '' } : filter
        ),
      }

    case 'UPDATE_FILTER_VALUE':
      return {
        ...state,
        advancedFilters: state.advancedFilters.map((filter) =>
          filter.id === action.payload.id ? { ...filter, value: action.payload.value } : filter
        ),
      }

    case 'REMOVE_FILTER': {
      const filteredFilters = state.advancedFilters.filter((filter) => filter.id !== action.payload)
      return {
        ...state,
        advancedFilters: filteredFilters.length ? filteredFilters : [createEmptyFilter()],
      }
    }

    case 'CLEAR_ALL_FILTERS':
      return {
        ...state,
        advancedFilters: [createEmptyFilter()],
      }

    case 'CLEAR_EMPTY_FILTERS': {
      const nonEmptyFilters = state.advancedFilters.filter(isFilterDirty)
      return {
        ...state,
        advancedFilters: nonEmptyFilters.length ? nonEmptyFilters : [createEmptyFilter()],
      }
    }

    default:
      return state
  }
}
