import { useHistory } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import React, { memo, useEffect, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationStatus } from '@gql'
import {
  EvaluationEventCriteriaScoreList,
  EvaluationEventScoreDrawerPanel,
  useEvaluation,
  useEvaluationEnvelope,
  useEvaluationEvent,
  useEvaluationScore,
  useEvaluationUser,
} from '@cotiss/evaluation-event'
import {
  Button,
  ConfirmModal,
  Drawer,
  Icon,
  NumberAnimation,
  ProgressBar,
  Switch,
  Text,
  TransitionContainer,
  routerService,
  sentryService,
  useCallout,
  useTransition,
} from '@cotiss/common'

export const EvaluationEventScoreDrawer = memo(() => {
  const { push } = useHistory()
  const { evaluationUser } = useEvaluationUser()
  const { openModal, closeDrawer } = useCallout()
  const { evaluationEvent } = useEvaluationEvent()
  const { evaluationEnvelope } = useEvaluationEnvelope()
  const { queryEvaluationScoreList } = useEvaluationScore()
  const { step, transition, onTransition } = useTransition()
  const { evaluation, evaluationOverview, queryEvaluationOverviewView, mutateUpdateEvaluation } = useEvaluation()
  const [activeEvaluationCriteriaItem, setActiveEvaluationCriteriaItem] = useState<GqlEvaluationCriteriaFieldsFragment | null>(null)
  const [activeParentEvaluationCriteriaItem, setActiveParentEvaluationCriteriaItem] = useState<GqlEvaluationCriteriaFieldsFragment | null>(null)

  useEffect(() => {
    // If these are not in context, something has gone wrong.
    if (!evaluation || !evaluationOverview || !evaluationUser) {
      sentryService.captureException({ exception: 'Failed to open EvaluationEventScoreDrawer due to missing data.' })
      closeDrawer()
      return
    }
  }, [])

  if (!evaluation || !evaluationUser) {
    return null
  }

  const handleBack = () => {
    if (step === 3) {
      onTransition({ step: activeParentEvaluationCriteriaItem ? 2 : 1, transition: 'left' })
    } else if (step === 2) {
      onTransition({ step: 1, transition: 'left' })
    }
  }

  const handleSelectCriteriaItem = (evaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment) => {
    if (evaluationCriteriaItem.isScored) {
      !evaluationCriteriaItem.parentEvaluationCriteriaId && setActiveParentEvaluationCriteriaItem(null)
      setActiveEvaluationCriteriaItem(evaluationCriteriaItem)
      onTransition({ step: 3, transition: 'right' })
    } else {
      setActiveParentEvaluationCriteriaItem(evaluationCriteriaItem)
      onTransition({ step: 2, transition: 'right' })
    }
  }

  const handleUpdateStatus = async (status: GqlEvaluationStatus) => {
    await mutateUpdateEvaluation({ evaluationId: evaluation.id, status })
    await queryEvaluationOverviewView({ evaluationId: evaluation.id })

    if (status === 'complete') {
      closeDrawer()
      push(
        routerService.getHref('/evaluation-event/view/:evaluationEventId/evaluate/envelope/:evaluationEnvelopeId/user/:evaluationUserId/:tab?', {
          evaluationEventId: evaluationEvent?.id || '',
          evaluationEnvelopeId: evaluationEnvelope?.id || '',
          evaluationUserId: evaluationUser?.id || '',
        })
      )
    } else if (status === 'abstained' && evaluationEvent) {
      await queryEvaluationScoreList({ filter: { evaluationEventId: evaluationEvent.id, evaluationId: evaluation.id } })
    }
  }

  const renderHeader = () => (
    <div className="flex items-center justify-between w-full truncate">
      <div className="flex items-center truncate">
        <Text className="font-medium mr-2" size="h5" variant="light">
          Score Envelope {evaluationEnvelope?.order}:
        </Text>
        <Text className="font-medium truncate" size="h5" variant="heading" title={evaluationEnvelope?.name}>
          {evaluationEnvelope?.name}
        </Text>
      </div>
      {evaluationEnvelope?.status === 'evaluate' && evaluation.status !== 'complete' && (
        <div className="flex items-center justify-end ml-4">
          <Switch
            isOn={evaluation?.status === 'abstained'}
            onClick={() =>
              evaluation?.status === 'abstained'
                ? handleUpdateStatus('inProgress')
                : openModal(
                    <ConfirmModal
                      heading="Abstain from scoring"
                      description="Are you sure you want to abstain from scoring this submission? All existing scores will be removed."
                      onSubmit={() => handleUpdateStatus('abstained')}
                    />
                  )
            }
          />
          <Text className="whitespace-nowrap ml-2">Abstain from scoring</Text>
        </div>
      )}
    </div>
  )

  const renderFooter = () => {
    if (evaluation.status === 'abstained') {
      return null
    }

    if (evaluation.status === 'complete') {
      return (
        <div className="flex items-center">
          {evaluationEnvelope?.status === 'evaluate' && (
            <Button
              className="mr-2"
              onClick={() =>
                openModal(
                  <ConfirmModal
                    heading="Edit evaluation"
                    description="Are you sure you want to edit this evaluation?"
                    onSubmit={() => handleUpdateStatus('inProgress')}
                  />
                )
              }
              state="translucent"
              variant="secondary"
            >
              <Icon className="mr-1" icon="edit-05" />
              Edit scores
            </Button>
          )}
          <Icon className="mr-1" icon="check-circle" variant="success" />
          <Text className="font-semibold mr-1">Marked as complete</Text>
          <Text variant="secondary">(100%)</Text>
        </div>
      )
    }

    return (
      <div className="flex items-center w-1/2">
        <Button
          onClick={() =>
            openModal(
              <ConfirmModal
                heading="Mark evaluation as complete"
                description="Are you sure you want to mark this evaluation as complete?"
                onSubmit={() => handleUpdateStatus('complete')}
              />
            )
          }
          variant="secondary"
          size="sm"
          isDisabled={evaluationOverview?.completedScoredCriteriaCount !== evaluationOverview?.scoredCriteriaCount}
        >
          Mark as complete
        </Button>
        <div className="w-full ml-4">
          <Text className="font-semibold" isInline>
            Scoring progress
          </Text>
          <Text className="font-medium ml-1" variant="secondary" size="sm" isInline>
            (
            <NumberAnimation
              value={((evaluationOverview?.completedScoredCriteriaCount || 0) / (evaluationOverview?.scoredCriteriaCount || 0) || 0) * 100}
              format={(value) => `${value.toFixed(0) || 0}%`}
            />
            )
          </Text>
          <ProgressBar
            className="mt-1"
            total={evaluationOverview?.scoredCriteriaCount || 0}
            completed={evaluationOverview?.completedScoredCriteriaCount || 0}
          />
        </div>
      </div>
    )
  }

  return (
    <Drawer header={renderHeader()} footer={renderFooter()}>
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && <EvaluationEventCriteriaScoreList onCtaClick={handleSelectCriteriaItem} />}
          {step === 2 && activeParentEvaluationCriteriaItem && (
            <>
              <Button className="mb-4" onClick={handleBack} state="translucent" variant="secondary" size="xs" isLink>
                <Icon className="mr-1" icon="arrow-left" />
                Back
              </Button>
              <EvaluationEventCriteriaScoreList
                parentEvaluationCriteriaItem={activeParentEvaluationCriteriaItem}
                onCtaClick={handleSelectCriteriaItem}
              />
            </>
          )}
          {step === 3 && activeEvaluationCriteriaItem && (
            <>
              <Button className="mb-4" onClick={handleBack} state="translucent" variant="secondary" size="xs" isLink>
                <Icon className="mr-1" icon="arrow-left" />
                Back
              </Button>
              <EvaluationEventScoreDrawerPanel
                evaluationCriteriaItem={activeEvaluationCriteriaItem}
                parentEvaluationCriteriaItem={activeParentEvaluationCriteriaItem}
              />
            </>
          )}
        </TransitionContainer>
      </AnimatePresence>
    </Drawer>
  )
})
