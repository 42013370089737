import { PartialFilter } from '@cotiss/common/modals/advanced-filters/advanced-filters.context'
import { utilService } from '@cotiss/common/services'

export const hasValue = (filter: PartialFilter) => (Array.isArray(filter.value) ? filter.value.length > 0 : Boolean(filter.value))

export const isFilterValid = (filter: PartialFilter) => Boolean(filter.field && filter.operation && hasValue(filter))

export const isFilterDirty = (filter: PartialFilter) => Boolean(filter.field || filter.operation || hasValue(filter))

export const createEmptyFilter = (): PartialFilter => ({ field: '', operation: undefined, value: '', id: utilService.generateUid() })
