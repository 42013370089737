import { Button, Field, Form, Hr, Radio, Text, TextArea, sentryService, useCallout, useToast } from '@cotiss/common'
import React, { FormEvent, memo, useState } from 'react'
import { useMutateConflictOfInterest, ConflictOfInterestModel, ConflictOfInterestViewDeclarationDocumentsModal } from '@cotiss/conflict-of-interest'
import { DocumentList } from '@cotiss/document'
import { filter, includes, map } from 'lodash'

type Props = {
  conflictOfInterest: ConflictOfInterestModel
  onSuccess: () => void
}

export type FormData = {
  conflictDeclared: boolean
  conflictDetails: string
  conflictMitigation: string
}

export const ConflictOfInterestViewDeclarationTab = memo(({ conflictOfInterest, onSuccess }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const { openToast } = useToast()
  const { updateConflictOfInterest } = useMutateConflictOfInterest()
  const [formData, setFormData] = useState({
    conflictDeclared: conflictOfInterest.conflictDeclared ?? false,
    conflictDetails: conflictOfInterest.conflictDetails ?? '',
    conflictMitigation: conflictOfInterest.conflictMitigation ?? '',
  })

  const { openModal } = useCallout()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateConflictOfInterest(conflictOfInterest._id, { ...formData, step: 'confirmation' })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsSaving(false)
    onSuccess()
  }

  const handleBulkRemove = async (documentIdsToRemove: string[]) => {
    try {
      setIsSaving(true)
      const conflictAttachments = map(
        filter(conflictOfInterest.conflictAttachments, ({ _id }) => !includes(documentIdsToRemove, _id)),
        ({ _id }) => _id
      )
      await updateConflictOfInterest(conflictOfInterest._id, { conflictAttachments })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="bg-white rounded-lg shadow-lg w-full px-8 py-6 max-w-5xl" onSubmit={handleSubmit}>
      <Text variant="heading" size="h4">
        {conflictOfInterest.procurement.title}
      </Text>
      <Hr className="my-4" />
      <Field label="Conflict of interest">
        <Text>
          A conflict of interest exists in any situation when a person has a financial interest, a private or personal interest, or a business
          interest sufficient to influence, or appear to influence, the impartial exercise of their official duties or professional judgments.
        </Text>
      </Field>
      <Hr className="my-4" />
      <Field label="Procurement">
        <Text>{conflictOfInterest.procurement.title}</Text>
      </Field>
      <Hr className="my-4" />
      <Field label="Do you have an actual, potential, or perceived conflict to declare?" className="mt-8">
        <div className="flex items-center">
          <Radio<boolean>
            className="mr-4"
            value={true}
            onChange={(conflictDeclared) => setFormData({ ...formData, conflictDeclared })}
            name="conflict-declared"
            isChecked={formData.conflictDeclared}
            isDisabled={isSaving}
          >
            Yes
          </Radio>
          <Radio<boolean>
            value={false}
            onChange={(conflictDeclared) => setFormData({ ...formData, conflictDeclared })}
            name="conflict-declared"
            isChecked={!formData.conflictDeclared}
            isDisabled={isSaving}
          >
            No
          </Radio>
        </div>
      </Field>
      {formData?.conflictDeclared && (
        <>
          <Field label="Please detail the Conflict of Interest you wish to disclose" className="mt-8">
            <TextArea
              value={formData.conflictDetails}
              rows={4}
              maxLength={500}
              onChange={({ target }) => setFormData({ ...formData, conflictDetails: target.value })}
              isDisabled={isSaving}
              isRequired
            />
          </Field>
          <Field label="Please detail action you propose to reduce, mitigate, or eliminate the Conflict of Interest. (optional)" className="mt-8">
            <TextArea
              value={formData.conflictMitigation}
              rows={4}
              maxLength={500}
              onChange={({ target }) => setFormData({ ...formData, conflictMitigation: target.value })}
              isDisabled={isSaving}
              isRequired
            />
          </Field>
        </>
      )}
      <Hr className="my-4" />
      <Field label="Please upload any relevant supporting documentation" className="mt-8">
        <DocumentList
          documents={conflictOfInterest.conflictAttachments}
          onUpload={() => openModal(<ConflictOfInterestViewDeclarationDocumentsModal conflictOfInterest={conflictOfInterest} />)}
          onBulkRemove={handleBulkRemove}
          isDisabled={isSaving}
        />
      </Field>
      <Hr className="my-4" />
      <div className="flex items-center justify-end">
        <Button type="submit" variant="secondary" isLoading={isSaving}>
          Continue
        </Button>
      </div>
    </Form>
  )
})
